import { GetUsersInProjectProp } from "pages/project-component/types"

export interface ActivityItemDetail {
  id: string
  project_id: string
  content: any
  created_at: string
  created_by: string
  user: GetUsersInProjectProp
  param?: string
}
export interface ParamsActivityLogs {
  page: number
  pageSize: number
  model: string
  id: string
}

export enum MODEL_ACTIVITY_LOG {
  PROJECTS = "projects",
  PROJECT_COMPONENTS = "project_components",
  PROJECT_BUILDS = "project_builds",
  PROJECT_BUILD_COMPONENTS = "project_build_components",
  CONVERSATION_BUILDS = "conversations",
  CONVERSATION_BUILD_COMPONENTS = "conversation_build_components",
}

import { useEffect, useState } from "react"
import NotesEditor from "./invitees-component/NotesEditor"
import { Note, emptyNote } from "../conversations.type"
import { ReactComponent as CloseIcon } from "assets/images/icons/close-icon.svg"
import { getNoteMiddleware, updateNotesMiddleware } from "../conversations.api"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { cloneDeep, remove } from "lodash"
import { formatDate } from "helpers/utils"
import { IUseDefaultValueProps, useBoolean } from "helpers/hooks"

interface Props {
  activeConversationId: string
  closeNote: () => void
  isSavingNote: IUseDefaultValueProps
}

const NoteColumn = (props: Props) => {
  const { activeConversationId, closeNote, isSavingNote } = props
  const [noteDetail, setNoteDetail] = useState<
    Note & { conversationId: string }
  >({ ...emptyNote, conversationId: "" })
  const isLoadingDefault = useBoolean(false)
  useEffect(() => {
    if (!activeConversationId) {
      return
    }
    isLoadingDefault.setValue(false)
    setNoteDetail({ ...emptyNote, conversationId: "" })
    getNote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversationId])

  const getNote = () => {
    getNoteMiddleware(activeConversationId)
      .then((res) => {
        setNoteDetail({ ...res, conversationId: activeConversationId })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
      .finally(() => {
        isLoadingDefault.setValue(true)
      })
  }
  const onEditNotes = (note: string) => {
    if (!activeConversationId || !isLoadingDefault.value) return
    //there's a bit complex here, so when we resize a image and imediately change to another conversation notes,
    // for somehow it still keep the old conversation notes state and
    // auto call the update note function for new conversation note with the payload of old conversation note
    //so we gonna store the conversation id to state and compare the activeId and the id in the state
    // if (activeConversationId !== noteDetail.conversationId) return
    //and for now user cannot change to another conversation if the editing notes is processing

    isSavingNote.setValue(true)
    let newNote = note
    noteDetail.files.forEach((file) => {
      newNote = newNote.replaceAll(file.url, file.file)
    })
    updateNotesMiddleware(activeConversationId, newNote)
      .then((_res) => {
        setNoteDetail({
          ...noteDetail,
          note,
          updated_at: Date().toString(),
        })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
      .finally(() => {
        isSavingNote.setValue(false)
      })
  }
  const onEditImageNote = (
    image: { file: string; file_name: string; url: string },
    type: "add" | "delete"
  ) => {
    if (!activeConversationId) return
    if (activeConversationId !== noteDetail.conversationId) return
    if (type === "add") {
      noteDetail.files.push(image)
    } else {
      remove(noteDetail.files, (item) => item.file === image.file)
    }
    noteDetail.updated_at = Date().toString()
    setNoteDetail({ ...noteDetail })
  }
  const onInsertAttachments = (
    attachments: { file: string; file_name: string; url: string }[]
  ) => {
    if (!activeConversationId) return
    if (activeConversationId !== noteDetail.conversationId) return
    isSavingNote.setValue(true)
    setNoteDetail({
      ...noteDetail,
      files: [...noteDetail.files, ...attachments],
      updated_at: Date().toString(),
    })
    isSavingNote.setValue(false)
  }
  return (
    <div className="flex flex-col flex-1 pl-6 pt-6 pb-6 pr-2 overflow-auto bg-white">
      <div className="flex items-center justify-between pb-3 min-h-32 overflow-hidden border-b border-gray-500">
        <p className="font-semibold text-16 leading-26 color-black-111111 ">
          Notes
        </p>
        <div className="flex gap-2 items-center">
          {noteDetail.note && (
            <p className="text-sm leading-6 font-normal">{`Saved at ${formatDate(
              noteDetail.updated_at,
              "H:mm A DD MMM yyyy"
            )}`}</p>
          )}
          <CloseIcon
            className="hover-action-icon cursor-pointer mr-2"
            onClick={closeNote}
          />
        </div>
      </div>
      {isLoadingDefault.value && (
        <NotesEditor
          key={activeConversationId}
          noteDetail={noteDetail}
          onEditNotes={onEditNotes}
          activeConversationId={activeConversationId}
          onEditImage={onEditImageNote}
          onInsertAttachments={onInsertAttachments}
          isSavingNote={isSavingNote}
        />
      )}
    </div>
  )
}

export default NoteColumn

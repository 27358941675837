import ModalCustom from "components/ModalCustom"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import Typography from "components/Typography"
import { useBoolean } from "helpers/hooks"
import { isFunction } from "lodash"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import React from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { postCopyLinkInviteeMiddleware } from "../api.services"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  originInvites: any
}

const FormCopyLink = (props: Props) => {
  const { onCloseModal, openModal, originInvites } = props
  const isLoading = useBoolean(false)

  const copyLink = (ccEmail?: string) => {
    postCopyLinkInviteeMiddleware(
      originInvites.id,
      originInvites.user_id,
      (type: STATUS_RESPONSE, messenger: string, link?: string) => {
        if(link && isFunction(navigator?.clipboard?.writeText)) {
          navigator.clipboard.writeText(link)
        }
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
      },
      originInvites.conversation_id,
      ccEmail
    )
  }
  const renderInviteeAndCCList = () => {
    return (
      <div>
        <Typography
          textClass="font-semibold"
          style={{
            color: "#111111",
            fontSize: 13,
            lineHeight: "22px",
            fontWeight: 600,
          }}
        >
          Invitee:
        </Typography>
        <div className="flex justify-between items-center ml-2">
          <Typography
            textClass="font-semibold"
            style={{
              color: "#111111",
              fontSize: 13,
              lineHeight: "22px",
              fontWeight: 600,
            }}
          >
            {originInvites.email}
          </Typography>
          <ActionIconHistory
            classIcon="hidden md:block"
            icon="copy-link"
            tooltip="Copy link"
            styleIcon={{
              marginLeft: 12,
              marginRight: 0,
            }}
            onClick={() => copyLink()}
          />
        </div>
        {originInvites.cc.length !== 0 ? (
          <div>
            <hr style={{ padding: "10px 0" }} />
            <Typography
              textClass="font-semibold"
              style={{
                color: "#111111",
                fontSize: 13,
                lineHeight: "22px",
                fontWeight: 600,
              }}
            >
              CC:
            </Typography>
            {originInvites.cc.map((ccUser) => {
              return (
                <div className="flex justify-between items-center ml-2">
                  <Typography
                    textClass="font-semibold"
                    style={{
                      color: "#111111",
                      fontSize: 13,
                      lineHeight: "22px",
                      fontWeight: 600,
                    }}
                  >
                    {ccUser}
                  </Typography>
                  <ActionIconHistory
                    classIcon="hidden md:block"
                    icon="copy-link"
                    tooltip="Copy link"
                    styleIcon={{
                      marginLeft: 12,
                      marginRight: 0,
                    }}
                    onClick={() => copyLink(ccUser)}
                  />
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <ModalCustom
      label={`Copy link / ${originInvites.conversation_code}`}
      handleChangeButton={() => {
        return onCloseModal
      }}
      bodyChildren={renderInviteeAndCCList()}
    />
  )
}
export default FormCopyLink

import { isEmpty } from "lodash"

export const SPECIFICATIONS = {
  PCB_SPECIFICATION_SELECTION: {
    ID: "6e099e46-ba79-4951-9f8a-dd9e50e1238e",
    BOARD_TYPE: "6e53e3f5-d851-48bc-ad95-eb5b05004519",
    DIFFERENT_DESIGN_IN_PANEL: "27191462-ca88-46c3-8aab-9247827a8db4",
    SIZE: "d5055fe2-c67a-40c4-a5d5-48b774ad31b2",
    QUANTITY: "a782c2fc-d4df-4eff-81a3-7f8257913d48",
    LAYER: "3cc0d8b5-c9e5-45f8-b2d2-b0342258a07f",
    MATERIAL: "126b9c47-de30-4e4f-a939-c4fbe26a22eb",
    FR4_TG: "6fd7b3f3-24bd-40d4-8ed9-be5d7528f7ae",
    THICKNESS: "e4314810-c857-49ee-b096-44c24b266a29",
    MIN_TRACK_SPACING: "bf3115e5-4264-4bda-b996-3b00b612d0cd",
    MIN_HOLE_SIZE: "1eae6f88-8426-40ea-9fb8-f6ac86b91a98",
    SOLDER_MASK: "b2410112-248a-4303-a3bb-90950c67d74d",
    SILKSCREEN: "a3f8fc56-fe00-474f-a2f1-fdbbe3574baf",
    EDGE_CONNECTOR: "c0d32686-82e5-4f80-ad3a-bfb705a402e5",
    SURFACE_FINISH: "1bbd8588-fbab-4486-878e-9f6bf1d81304",
    VIA_PROCESS: "72530610-a8cf-45e1-b429-71483f6b79b7",
    FINISHED_COPPER: "b0da074b-032e-494c-9936-707cfde3b9fb",
    INNER_COPPER: "320008fe-2443-4ba5-8e40-0c0c37e2c06c",
    EXTRA_PCB_PRODUCT_NUMBER: "979578e0-11db-4f6a-a35b-e743538a5017",
    ADDITIONAL_OPTION: {
      ID: "1a4c33e2-828e-40f4-8b43-6bd4bac67116",
      PEELABEL_SOLDERMASK: "f4a90bb9-00c2-477a-85f4-69d7f2bafff0",
      UL_MARKING: "72e705fa-72ba-4b84-b2d6-dccf7127bacc",
      HALF_CUT_CASTELLATED_HOLES: "6f34b355-4587-4921-ab46-da33a8fa21ae",
      EDGE_PLATING: "a5f88b0b-f76a-49ae-93ba-c11ea864aa48",
      IMPEDANCE_CONTROL: "72a0c757-0680-4ab9-82c7-87088a7e1a9c",
      HALOGEN_FREE: "320caea3-88af-4df1-a35e-6ce95045a236",
      CUSTOM_STACKUP: "c9abe42f-6514-41c2-bb42-da03de2a6057",
      CARBON_MASK: "868196bc-6981-43cb-a851-50987128ed69",
      VIA_IN_PAD_VIA_FILLED_WITH_RESIN: "a9da9bd5-64c2-4744-89f1-51dc2e7f7789",
      PRESS_FIT_HOLES: "e7e9c371-52c8-45af-bce5-fef24ecd9a80",
      COUNTERSINKS_COUNTERBORES: "9ff25160-94f0-43dd-a14e-13d585e81c06",
      Z_AXIS_MILLING: "fbee5858-b1bd-48ca-b3d7-d36e0d3351e5",
      BLACK_FR4_BLACK_CORE: "dfe6475a-da11-41cf-844f-be0bf816ebee",
    },
    OTHER_SPECIAL_REQUEST: "a6fdb1f5-a22d-470f-90d5-8f6423081f4e",
  },
  SMD_STENCIL: {
    ID: "a9aa005e-669d-45c3-9b9b-0647ac6b7f82",
    STENCIL_TYPE: "12ec4d24-a106-4406-9834-01b308231926",
    MULTI_LEVEL_STEP_STENCIL: "031d873c-bfca-4247-9bb3-f478fce7f92d",
    SIZE: "9b0e2120-1691-4eef-8f87-af38b81f5d06",
    STENCIL_SIDE: "7fcda4b5-7103-4d41-8098-2ec3b821fbff",
    QUANTITY: "78dc007f-ef4a-4428-a4a6-2896dbea8425",
    THICKNESS: "d73717f8-8f57-4c9b-b0bb-ecccadc6b245",
    EXISTING_FIDUCIALS: "190525ff-740b-4d82-9943-e8cb22538d4c",
    ELECTROPOLISHING: "82f5d6a3-95d2-497c-9766-d0166c71e912",
    OTHER_SPECIAL_REQUEST: "6d2f213c-92ca-4c56-8504-04e38204ced0",
  },
  ASSEMBLY_SERVICE: {
    ID: "71bcdde0-5889-45d2-82be-9e27d5def0a1",
    THREE_FLEXIBLE_OPTIONS: "0110d9b6-a4f0-404e-bfa7-325927cd6b13",
    BOARD_TYPE: "a57a6888-ac5e-4b32-ad11-41373aba6266",
    ASSEMBLY_SIDE: "3504722b-51c6-48a7-9204-ae394e7d547f",
    QUANTITY: "eef10382-c28e-4e86-9509-8ec563365819",
    PAY_ATTENTION: "13682443-51d2-41e0-b919-250f7de578a0",
    SUB_PAY_ATTENTION: "c0b60a27-b6a5-4532-aa9d-d54bdd02e9f9",
    NUMBER_OF_UNIQUE_PARTS: "2593d1c8-4104-4f43-999b-a0e508d73fa3",
    NUMBER_OF_SMD_PARTS: "60c887e2-0393-4d7c-8be5-dbb280ab93e5",
    NUMBER_OF_BGA_QFP_PARTS: "83dad44f-5edc-43e5-ad12-b39de5c5f251",
    NUMBER_OF_THROUGH_HOLE_PARTS: "0fde8349-136c-4d79-b7ec-db5e4175c994",
    ADDITIONAL_OPTION: {
      ID: "44d2868c-0a16-4ad5-aa8d-24d471cc39f3",
      DEPANEL_THE_BOARDS_TO_DELIVERY: "147f5ffa-8fc6-4420-a4a7-6b67d0762474",
      FUNCTION_TEST: "497d7aef-352f-4976-a0fe-2a173d582b3c",
      CONFORMAL_COATING: "a276d26f-75a5-484f-8697-845841088ecc",
      FIRMWARE_LOADING: "7f6889bd-ce99-4e75-8a83-99eb8761c45f",
      PRESS_FIT_CONNECTORS_ASSEMBLY: "090d6368-6515-47c4-84b4-e871887388b0",
      NUMBER_OF_X_RAY_TEST: "fa89077e-bbab-41d7-b34c-22843c1a0b76",
    },
    OTHER_SPECIAL_REQUEST: "982c8436-eea5-461d-a3c1-6937c7a85346",
  },
}
const returnValueOrNA = (value) => {
  if (!value) return "N/A"
  return value
}
const keyValues = [
  {
    key: "pcb_size",
    specification_part_id: SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SIZE,
  },
  {
    key: "layer",
    specification_part_id: SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.LAYER,
  },
  {
    key: "material",
    specification_part_id: SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.MATERIAL,
  },
  {
    key: "fr4_tg",
    specification_part_id: SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.FR4_TG,
  },
  {
    key: "thickness",
    specification_part_id: SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.THICKNESS,
  },
  {
    key: "min_track",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.MIN_TRACK_SPACING,
  },
  {
    key: "min_hole_size",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.MIN_HOLE_SIZE,
  },
  {
    key: "solder_mask",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SOLDER_MASK,
  },
  {
    key: "silkscreen",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SILKSCREEN,
  },
  {
    key: "edge_connector",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.EDGE_CONNECTOR,
  },
  {
    key: "surface_finish",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SURFACE_FINISH,
  },
  {
    key: "via_process",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.VIA_PROCESS,
  },
  {
    key: "finished_copper",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.FINISHED_COPPER,
  },

  {
    key: "other_1",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.OTHER_SPECIAL_REQUEST,
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .HALF_CUT_CASTELLATED_HOLES,
    text: "Half-cut/Castellated Holes",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION.HALOGEN_FREE,
    text: "Halogen-Free",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .VIA_IN_PAD_VIA_FILLED_WITH_RESIN,
    text: "Via in pad/ Via filled with resin",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .Z_AXIS_MILLING,
    text: "Z-axis milling",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION.EDGE_PLATING,
    text: "Edge Plating",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .CUSTOM_STACKUP,
    text: "Custom Stackup",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .PRESS_FIT_HOLES,
    text: "Press-fit holes",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .BLACK_FR4_BLACK_CORE,
    text: "Black FR4 (black core)",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .IMPEDANCE_CONTROL,
    text: "Impedance control",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION.CARBON_MASK,
    text: "Carbon Mask",
  },
  {
    key: "additional_options",
    specification_part_id:
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.ADDITIONAL_OPTION
        .COUNTERSINKS_COUNTERBORES,
    text: "Countersinks/Counterbores",
  },
]
const toSpecificationHtmlInput = (data: any) => {
  const newKeyValues = keyValues.map((item) => {
    const found = data.find(
      (el) => el.specification_part_id === item.specification_part_id
    )
    if (found) {
      if (
        item.specification_part_id ===
        SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SIZE
      ) {
        const parts = found.value.split(",")
        const temp = `${parts[0].split("_")[0]} x ${parts[1].split("_")[0]} ${
          parts[2].split("_")[0]
        }`
        return {
          ...item,
          value: temp,
        }
      }
      if (
        item.specification_part_id ===
        SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.VIA_PROCESS
      ) {
        const parts = found.value.split(",")
        const temp = [
          parts[0].split("_")[0],
          parts[1].split("_")[0],
          parts[2].split("_")[0],
        ]
          .filter((viaProcess) => !isEmpty(viaProcess))
          .join(", ")
        return {
          ...item,
          value: temp,
        }
      }
      return {
        ...item,
        value: found.value.split("_")[0],
      }
    }
    return {
      ...item,
      value: "",
    }
  })
  let result: any = {}
  newKeyValues.forEach((element) => {
    result[element.key] = element.value
  })
  result["additional_options"] = newKeyValues
    .filter(
      (item) => item.key === "additional_options" && item.value === "true"
    )
    .map((item) => item.text)
    .join(", ")
  return result
}
export const toSpecificationHtml = (
  data: any,
  extraInput: { layers: any; gerber_file_name: string; attachments: string[] }
) => {
  const input = toSpecificationHtmlInput(data)
  return createSpecificationHtmlString({
    ...input,
    ...extraInput,
  })
}
export const createSpecificationHtmlString = (data: {
  layers: any[]
  product_no?: string
  order_status?: string
  create_time?: string
  build_time?: string
  estimated_finish_time?: string
  gerber_file_name?: string
  pcb_file_path?: string
  pcb_size?: string
  layer?: string
  material?: string
  thickness?: string
  min_track?: string
  min_hole_size?: string
  solder_mask?: string
  silkscreen?: string
  edge_connector?: string
  surface_finish?: string
  via_process?: string
  finished_copper?: string
  additional_options?: string
  peelable_soldermask?: string
  ul_marking?: string
  fr4_tg?: string
  other_1?: string

  attachments?: any[]
}) => {
  const newLayers = data.layers
  let files_1: string = ""
  if (data.attachments) {
    files_1 = data.attachments.reduce((pre, cur) => {
      return pre + `<br/><span>${cur}</span>`
    }, "")
  }
  return `<html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=Edge，chrome=1" />
        <title>Product detail - PCBWay</title>
        <link rel="stylesheet" type="text/css" href="/img/css/base.css" />
        <link
            rel="stylesheet"
            type="text/css"
            href="/img/css/backstageTopFooter.css"
        />
        <link
            rel="stylesheet"
            type="text/css"
            href="/img/css/backstage-home.css"
        />
        <link rel="stylesheet" type="text/css" href="/img/css/boxpublic.css" />
        <style>
            .attached {
                margin-right: 10px
            }
            .product-parameter-pop {
                width: 600px;
                margin: 16px auto 16px;
            }

            .product-parameter-warning {
                width: 686px;
                margin: 0px auto;
            }

            .product-parameter-pop .parameter-title {
                padding: 10px 6px;
                font-size: 15px;
                /*border: 1px solid #e0e8f4;*/
                border-bottom: none;
                font-weight: 500;
            }
            .product-parameter-pop .sub_parameter-title {
                padding: 10px 6px;
                font-size: 13px;
                /*border: 1px solid #e0e8f4;*/
                border-bottom: none;
                font-weight: 500;
                color: #989a9c;
            }

            .product-parameter-pop .parameter-table {
                width: 100%;
                border: 1px solid #e0e8f4;
            }

            .product-parameter-pop .parameter-table .des {
                word-break: break-all;
            }

            .product-parameter-pop .parameter-table .des .des-surface {
                display: inline-block;
                clear: both;
                overflow: hidden;
                margin-bottom: 5px;
            }

            .product-parameter-pop .parameter-table .parameter-width75 {
                width: 75%;
            }
            .product-parameter-pop .parameter-table .parameter-attribute1 {
                width: 25%;
            }

            .product-parameter-pop .parameter-table .parameter-attribute2 {
                width: 25%;
            }

            .product-parameter-pop .parameter-table .parameter-attribute3 {
                width: 25%;
            }

            .product-parameter-pop .parameter-table .parameter-attribute4 {
                width: 25%;
            }

            .product-parameter-pop .parameter-table tr td {
                padding: 8px 10px;
                border-top: 1px solid #e0e8f4;
                border-left: 1px solid #e0e8f4;
                color: #2a2a2a;
            }

            .product-parameter-pop .parameter-table tr td:first-child {
                border-left: none;
            }

            .product-parameter-pop .parameter-table tr:first-child + tr td {
                border-top: none;
            }

            .product-parameter-pop .parameter-table tr td:first-child {
                color: #777;
                background: #f4f7fc;
            }

            .product-parameter-pop
                .parameter-table
                tr
                td:first-child
                + td
                + td {
                color: #777;
                background: #f4f7fc;
            }

            .product-parameter-pop .parameter-table tr .des .state {
                color: #ff4e00;
                font-weight: 500;
            }

            .product-parameter-pop
                .parameter-table
                .price-summary
                td:first-child {
                background: #fff;
            }

            .product-parameter-pop .parameter-table .total-price {
                background: #fff;
                text-align: right;
                color: #999999;
                position: relative;
            }

            .product-parameter-pop .parameter-table .total-price .price {
                color: #f60;
                font-size: 18px;
                font-weight: bold;
            }
            td {
                font-size: 14px !important;
            }
        </style>
    </head>

    <body>
        <div class="product-parameter-pop">
            <div class="parameter-title">
                Basic Information: Standard PCB
            </div>
            <table class="parameter-table" cellpadding="0" cellspacing="0">
                <!-- title-->
                <tbody>
                    <tr>
                        <th class="parameter-attribute1"></th>
                        <th class="parameter-attribute2"></th>
                        <th class="parameter-attribute3"></th>
                        <th class="parameter-attribute4"></th>
                    </tr>
                    <tr>
                        <td>
                            Product No:
                        </td>
                        <td>
                            <div class="des">
                                ${returnValueOrNA(data.product_no)}
                            </div>
                        </td>
                        <td>
                            Order Status:
                        </td>
                        <td>
                            <div class="des">
                                <div class="state">
                                ${returnValueOrNA(data.order_status)}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Create Time:
                        </td>
                        <td>
                            <div class="des">
                                <div class="time">
                                ${returnValueOrNA(data.create_time)}
                                </div>
                            </div>
                        </td>
                        <td>
                            Build Time:
                        </td>
                        <td>
                            <div class="des">
                                <div class="time">
                                ${returnValueOrNA(data.build_time)}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Estimated Finish Time:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.estimated_finish_time)}
                            </div>
                        </td>
                        <td>
                            Gerber File:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.gerber_file_name)}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        ${
          data.layers && data.layers[0]
            ? `<div class="product-parameter-pop">
                        <div class="parameter-title">
                            Layer Assignment:
                        </div>
                        <table class="parameter-table" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <th class="parameter-width75"></th>
                                    <th class="parameter-attribute1"></th>
                                </tr>
                                ${newLayers
                                  .map((layer) => {
                                    return `<tr> <td> ${layer.file_name} </td> <td> ${layer.sequence_name} </td> </tr>`
                                  })
                                  .join("")}


                            </tbody>
                        </table>
                    </div>`
            : ""
        }
        <div class="product-parameter-pop">
            <div class="parameter-title">
            PCB Specification Selection:
            </div>
            <table class="parameter-table" cellpadding="0" cellspacing="0">
                <!-- title-->
                <tbody>
                    <tr>
                        <th class="parameter-attribute1"></th>
                        <th class="parameter-attribute2"></th>
                        <th class="parameter-attribute3"></th>
                        <th class="parameter-attribute4"></th>
                    </tr>
                    <tr>
                        <td>
                            Size:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.pcb_size)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="name">
                                Layer:
                            </div>
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.layer)}
                            </div>
                        </td>
                        <td>
                            Material:
                        </td>
                        <td>
                            <div class="des">
                                ${returnValueOrNA(data.material)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                    <td>
                        <div class="name">
                        FR4-TG:
                        </div>
                    </td>
                    <td>
                        <div class="des">
                        ${returnValueOrNA(data.fr4_tg)}
                        </div>
                    </td>
                    <td>
                        Thickness:
                    </td>
                    <td>
                        <div class="des">
                            ${returnValueOrNA(data.thickness)}
                        </div>
                    </td>
                </tr>
                    <tr>
                        <td>
                            <div class="name">
                            Min Track/Spacing:
                            </div>
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.min_track)}
                            </div>
                        </td>
                        <td>
                        Min Hole Size:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.min_hole_size)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="name">
                                Solder Mask:
                            </div>
                        </td>
                        <td>
                            <div class="des">${returnValueOrNA(
                              data.solder_mask
                            )}</div>
                        </td>
                        <td>
                            Silkscreen:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.silkscreen)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="name">
                                Edge connector:
                            </div>
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.edge_connector)}
                            </div>
                        </td>
                        <td>
                        Surface Finish:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.surface_finish)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="name">
                                Via process:
                            </div>
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.via_process)}
                            </div>
                        </td>
                        <td>
                        Finished Copper:
                        </td>
                        <td>
                            <div class="des">
                            ${returnValueOrNA(data.finished_copper)}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="sub_parameter-title">
            Additional Options: ${returnValueOrNA(data.additional_options)}
            <p>Other special request: </p>
            <span>${returnValueOrNA(data.other_1)}</span>
            <p>Attached files: ${returnValueOrNA(files_1)}</p>
            </div>

        </div>
    </body>
</html>
`
}
const getSizeValues = (sizeString: string) => {
  const parts = sizeString.split(",")
  return {
    length: parts[0]?.split("_")[0],
    width: parts[1]?.split("_")[0],
  }
}
export const validateSpecificationInput = (data: any) => {
  //Require size
  const sizeElement = data.find(
    (item) =>
      item.specification_part_id ===
      SPECIFICATIONS.PCB_SPECIFICATION_SELECTION.SIZE
  )
  if (!sizeElement) {
    return 'Size is required.'
  }
  const { length, width } = getSizeValues(sizeElement.value || "")
  if (isEmpty(length) || isEmpty(width)) {
    return 'Size is required.'
  }
  if (length === "0" || width === "0") {
    return 'Size is required.'
  }
  return true
}

import _ from "lodash"
import * as XLSX from "xlsx"
import WorkSheet from "./workbook"
import { getBufferFromBase64 } from "./utils"

class ExcelService {
  private xlsx: typeof XLSX
  constructor() {
    this.xlsx = XLSX
  }

  public readBase64File = (base64: string) => {
    const buffer = getBufferFromBase64(base64)
    return this.readFromBuffer(buffer)
  }

  public readBufferFile = (buffer: ArrayBuffer) => {
    return this.readFromBuffer(buffer)
  }

  public toExcel = (data: object[]) => {
    const workbook = this.xlsx.utils.book_new()
    this.xlsx.utils.book_append_sheet(
      workbook,
      this.xlsx.utils.json_to_sheet(data)
    )
    return this.xlsx.write(workbook, { type: "buffer" })
  }
  public toCsv = (data: object[]) => {
    return this.xlsx.utils.sheet_to_csv(this.xlsx.utils.json_to_sheet(data))
  }

  // ---------------------------------------------
  private readFromBuffer = (buffer: ArrayBuffer) => {
    return new WorkSheet(this.xlsx.read(buffer))
  }
  public addIndexColumn = (data: Object[]) => {
    return data.map((item, index) => {
      delete item["#"]
      return {
        "#": index + 1,
        ...item,
      }
    })
  }
}
export const excelService = new ExcelService()

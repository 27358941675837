interface ProgressBarProps {
  title?: string
  isShow: boolean
  currentValue: number
  totalValue: number
}
const ProgressBar = (props: ProgressBarProps) => {
  const { isShow, currentValue, totalValue } = props
  let currentPercent = Math.ceil((currentValue / (totalValue || 1)) * 100)
  // if (totalValue === 0) {
  //   currentPercent = 100
  // }
  return isShow ? (
    <div
      style={{
        color: "white",
        width: "200px",
        position: "absolute",
        bottom: "10px",
        right: "10px",
        zIndex: 10002,
      }}
    >
      <h3 style={{ paddingBottom: "2px" }}>{props.title || "loading..."}</h3>

      <div
        style={{
          width: "100%",
          border: "1px solid #e5e7eb",
          borderRadius: "15px",
        }}
      >
        <div
          style={{
            width: `${currentPercent}%`,
            maxWidth: "100%",
            borderRadius: "15px",
            background: "#56a012",
            textAlign: "center",
          }}
        >
          <span style={{ color: "white" }}>{currentPercent}%</span>
        </div>
      </div>
    </div>
  ) : null
}

export default ProgressBar

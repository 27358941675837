import React from "react"
import Typography from "components/Typography"
import { ProjectCardProps, ProjectDetail, ProjectStatus } from "../types"
import { TippyCustomzie } from "components/TippyCustomzie"
import { formatDate, formatDateForConversationMessage } from "helpers/utils"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { ReactComponent as IconFavorite } from "assets/images/icons/icon-favorite-project.svg"
import { ReactComponent as SecurityShieldIcon } from "assets/images/icons/icon-shield-security.svg"
import IconFavoriteActive from "assets/images/icons/icon-favorite.svg"
import StatusProject from "../molecules/StatusProject"
import { useBoolean } from "helpers/hooks"
import ActionItem from "../molecules/ActionItem"
import RoleUsersCard from "../molecules/RoleUsersCard"
import { Avatar } from "components/Avatar"
import { isUndefined } from "lodash"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import Tippy from "@tippyjs/react"

const ProjectCard = (props: ProjectCardProps) => {
  const { item, isDiable, handleChangeButton, loggedUserId, openToNewTab } =
    props
  const showTippy = useBoolean(false)
  const isDeleted = item.status === ProjectStatus.DeletedAttachment
  const handleChangeRouter = () => {
    if (!item.id || isDeleted) {
      return
    }
    if (openToNewTab) {
      window.open(`/project-detail/${item.id}`)
    } else
      pushTo(PATH.projectComponent, {
        idProject: item.id,
      })
  }

  const handleClickTippy = (event) => {
    event.stopPropagation()
    event.preventDefault()
    showTippy.setValue(!showTippy.value)
  }

  const onClickAction =
    (
      type: "share" | "edit" | "archive" | "favorite" | "delete_attachment",
      newItem: ProjectDetail
    ) =>
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (isDiable || isDeleted) {
        return
      }

      handleChangeButton(type, newItem, () => {
        showTippy.setValue(false)
      })
    }

  const renderRoleUser = () => {
    if (item.user && item.user?.id && isUndefined(item.role_users)) {
      return (
        <div className="flex items-center">
          <span
            className="font-medium"
            style={{
              color: "#7A7A7A",
              fontSize: 12,
              lineHeight: "18px",
              marginRight: 4,
            }}
          >
            Owner:
          </span>
          <Avatar
            width="24px"
            height="24px"
            name={item.user.fullname}
            avatar={item.user.avatar}
            textcolor="white"
            contentsize="11px"
            justOneLetter={true}
          />
        </div>
      )
    }
    return (
      <RoleUsersCard
        data={
          item.role_users?.filter((i) => i.user_id !== item.created_by) || []
        }
        max={3}
      />
    )
  }

  const renderActionProject = () => {
    if (item.is_archived !== 0 || isDeleted) {
      return null
    }
    return (
      <React.Fragment>
        <ActionItem title="Share" onClick={onClickAction("share", item)} />
        <ActionItem title="Edit" onClick={onClickAction("edit", item)} />
      </React.Fragment>
    )
  }
  const nameStatusActionArchived =
    item.is_archived === 0 ? "Archive" : "Unarchive"

  return (
    <div
      className={`rounded-md p-3 min-h-[246px] flex flex-col justify-between hover-project-card ${
        item.is_archived === 1 ? "archived-project bg-neutral3" : "bg-white"
      } ${
        isDeleted
          ? "bg-neutral3 bg-delete-element archived-project"
          : "cursor-pointer"
      }`}
      style={{
        border: "1px solid #E4E4E4",
      }}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center header-card">
          {!isDiable ? (
            <React.Fragment>
              {item.is_favorite ? (
                <img
                  src={IconFavoriteActive}
                  alt="icon"
                  className="mr-2"
                  style={{
                    width: 16,
                  }}
                  onClick={onClickAction("favorite", item)}
                />
              ) : (
                <IconFavorite
                  className="mr-2"
                  onClick={onClickAction("favorite", item)}
                />
              )}
            </React.Fragment>
          ) : null}

          <span
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#7A7A7A",
            }}
          >
            {item?.code}
          </span>
        </div>
        <div className="flex justify-center items-center">
        {item.is_encrypted ? (
          <Tippy
            className="custom-tippy-menu-left"
            placement="top"
            content={<span
              className="hidden md:block font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
              }}
            >
              This project is securely encrypted
            </span>}
          >
            <SecurityShieldIcon style={{
              color: (isDeleted || item.is_archived) ? '#7A7A7A' : '#2ba84d'
            }} />
          </Tippy>
        ) : null}
        {!isDeleted && !isDiable ? (
          <TippyCustomzie
            containerClass="component-card-menu"
            placement="bottom-end"
            interactive
            arrow={false}
            animation="scale"
            visible={showTippy.value}
            onClickOutside={() => showTippy.setValue(false)}
            content={
              <div
                style={{
                  background: "#222222",
                  borderRadius: 4,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 165,
                }}
              >
                {renderActionProject()}

                <ActionItem
                  title={nameStatusActionArchived}
                  onClick={onClickAction("archive", item)}
                />
                {loggedUserId === item.created_by ? (
                  <ActionItem
                    className="hidden md:flex"
                    title={"Delete"}
                    onClick={onClickAction("delete_attachment", item)}
                  />
                ) : null}
              </div>
            }
            allowHTML
          >
            <div className="flex items-center" onClick={handleClickTippy}>
              <ActionIconHistory
                icon="three-dots"
                tooltip="Action"
                styleIcon={{
                  marginRight: 0,
                }}
              />
            </div>
          </TippyCustomzie>
        ) : (
          <div />
        )}
        </div>
      </div>

      <div className="flex flex-1 flex-col" onClick={handleChangeRouter}>
        <div className="flex items-center status-title-card">
          <StatusProject
            status={item.is_archived ? ProjectStatus.Archive : item.status || 4}
          />
          <p
            className="font-semibold name-card"
            style={{
              color: "#111111",
              fontSize: 14,
              lineHeight: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.name}
          </p>
        </div>

        <div className="h-42 mt-3 description-project-card">
          <Typography
            style={{
              color: "#111111",
              fontSize: 13,
              lineHeight: "22px",
            }}
            textClass="line-two-text whitespace-pre-line font-normal"
          >
            {item?.description}
          </Typography>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="flex items-center flex-wrap gap-2 pt-3 min-h-[38px]">
            {item.categories?.length
              ? item.categories.map((category, index) => (
                  <div
                    key={index}
                    className="rounded category-project-card"
                    style={{
                      background: "#F7F7F7",
                      padding: "4px 8px",
                    }}
                  >
                    <p
                      style={{
                        color: "#7A7A7A",
                        fontSize: 12,
                        lineHeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {category.name}
                    </p>
                  </div>
                ))
              : null}
          </div>
          <div className="flex items-end justify-between time-project-card mt-6">
            <div className="flex flex-col">
              <span className="text-[#7A7A7A] font-medium text-[12px] leading-[18px]">
                {item?.updated_at
                  ? `Edited ${formatDateForConversationMessage(
                      item.updated_at
                    )}`
                  : "No update"}
              </span>
              <span className="text-[#7A7A7A] font-medium text-[12px] leading-[18px]">
                {`Created date: ${formatDate(item.created_at, "DD MMM, yyyy")}`}
              </span>
            </div>

            {renderRoleUser()}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProjectCard

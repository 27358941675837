import React from "react"
import { Route, Redirect } from "react-router-dom"
import { PATH } from "constants/path"
import { isLogin } from "config/environments"
import { isEmpty } from "lodash"
import { useQuery } from "helpers/history"

const PublicRoute = ({ component: Component, ...rest }) => {
  const query = useQuery()
  const accessToken = query.get("access_token")
  const projectId = query.get("project")
  return (
    <Route
      {...rest}
      render={() =>
        !isLogin() ? (
          Component
        ) : !isEmpty(accessToken) && !isEmpty(projectId) ? (
          <Redirect
            to={`/projects?project=${projectId}&access_token=${accessToken}`}
          />
        ) : (
          <Redirect to={PATH.projects} />
        )
      }
    />
  )
}

export default PublicRoute

import moment from "moment"
import { useEffect, useState } from "react"

export const Clock = () => {
  const [current, setCurrent] = useState<number>(moment.now())
  useEffect(() => {
    setInterval(() => {
      setCurrent(moment.now())
    }, 1000)
  }, [])
  const timezone = localStorage.getItem("timezone") || "+0000"
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "1.25rem",
        color: "white",
      }}
    >
      <span>{moment(current).utcOffset(timezone).format("HH:mm:ss")}</span>
    </div>
  )
}

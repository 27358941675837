import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import {
  BUILD_INVITEE_STATUS,
  emptyInviteeBuildComponentDetail,
  InviteeBuilComponentDetail,
  ProjectBuildInvitessDetail,
} from "../project-build.type"
import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean, useNumber } from "helpers/hooks"
import React, { useContext, useEffect, useRef, useState } from "react"
import { HashLoader } from "react-spinners"
import {
  deleteProjectBuildInviteeAddComponentMiddleware,
  getProjectBuildInviteeComponentMiddleware,
  postCopyLinkInviteeMiddleware,
  postProjectBuildInviteeActivateMiddleware,
  postProjectBuildInviteeAddComponentMiddleware,
  postProjectBuildInviteeSyncMiddleware,
  postProjectBuildSendInviteeMiddleware,
  updateInviteeModifyComponentPermissionMiddleWare,
} from "../api.services"
import ComponentBuildItemCard from "../molecules/ComponentBuildItemCard"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { cloneDeep, isUndefined, remove } from "lodash"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import Button from "components/Button/Button"
import { ProjectComponentStatus, STATUS_BUILD } from "components/Status/types"
import { umamiTracking, customLocalStorageHandler } from "helpers/utils"
import { EVENT } from "constants/events"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import { useAppSelector } from "hooks/useApp"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import ActionItem from "pages/projects/molecules/ActionItem"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { MENU_TAB_CONVERSATION } from "pages/conversations/conversations.type"
import Tippy from "@tippyjs/react"
import { ReactComponent as IconMessagerDeleteComponent } from "assets/images/icons/icon-messager-delete-component.svg"
import FormCopyLink from "./FormCopyLink"

interface Props {
  originInvites: ProjectBuildInvitessDetail
  sumComponent: number
  onEditInvites: (oldInvite: ProjectBuildInvitessDetail) => () => void
  isViewShare: boolean
  handleChangeData: (newInvitees?: ProjectBuildInvitessDetail) => void
  isDeleted?: boolean
}
const InviteesItemCard = (props: Props) => {
  const {
    originInvites,
    onEditInvites,
    isViewShare,
    handleChangeData,
    sumComponent,
    isDeleted,
  } = props
  const { projectBuild, archiveProject, archiveBuild } = useContext(
    PermissionProjectBuildPage
  )
  const userInfo = useAppSelector((state) => state.userInfo)
  const showTippyAddComponent = useBoolean(false)
  const isCopyLink = useBoolean(false)
  const refSelectComponent = useRef<HTMLDivElement>(null)
  const isLoadingComponent = useBoolean(false)
  const isLoading = useBoolean(false)
  const [buildComponents, setBuildComponents] = useState<
    InviteeBuilComponentDetail[]
  >([])
  const [inviteBuilComponent, setInviteBuilComponent] = useState<
    InviteeBuilComponentDetail[]
  >([])
  const isSentInvitee = useNumber(0)
  const [oldBuildComponent, setOldBuildComponent] =
    useState<InviteeBuilComponentDetail>(emptyInviteeBuildComponentDetail)
  const isFormDeleteComponent = useBoolean(false)
  const statusInvites = useNumber(BUILD_INVITEE_STATUS.ACTIVATE)
  const isSyncComponent = useBoolean()

  const deactivateTab = statusInvites.value === BUILD_INVITEE_STATUS.DEACTIVATE
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )
  const showTippyAction = useBoolean()
  useEffect(() => {
    setInviteBuilComponent(originInvites.project_build_components)
    isSentInvitee.setValue(originInvites.is_sent)
    statusInvites.setValue(
      originInvites.status === BUILD_INVITEE_STATUS.DEACTIVATE
        ? BUILD_INVITEE_STATUS.DEACTIVATE
        : BUILD_INVITEE_STATUS.ACTIVATE
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originInvites])

  const onShowComponent = async () => {
    showTippyAddComponent.setValue(!showTippyAddComponent.value)
    if (!originInvites.id || showTippyAddComponent.value) {
      return
    }
    isLoadingComponent.setValue(true)
    try {
      const dataRes = await getProjectBuildInviteeComponentMiddleware(
        originInvites.id
      )
      setBuildComponents(dataRes)
      isLoadingComponent.setValue(false)
    } catch (error) {
      isLoadingComponent.setValue(false)
    }
  }
  const onClickOutsideAddComponent = () => {
    if (isLoadingComponent.value) {
      return
    }
    showTippyAddComponent.setValue(false)
    setBuildComponents([])
  }
  const onClickAddComponentInInvitess =
    (oldComponent: InviteeBuilComponentDetail) => () => {
      if (!oldComponent.project_build_component_id || !originInvites.id) {
        return
      }
      isLoadingComponent.setValue(true)
      postProjectBuildInviteeAddComponentMiddleware(
        originInvites.id,
        oldComponent.project_build_component_id,
        `${oldComponent.code}/${oldComponent.version}`,
        originInvites.conversation_id,
        (
          type: STATUS_RESPONSE,
          messenger: string,
          dataResponse?: InviteeBuilComponentDetail
        ) => {
          isLoadingComponent.setValue(false)
          toast(<LabelNotificationPage messenger={messenger} type={type} />)

          if (type === STATUS_RESPONSE.SUCCESS && dataResponse) {
            const newBuildComponents = cloneDeep(buildComponents)
            const newInvitesComponent = cloneDeep(inviteBuilComponent)
            remove(
              newBuildComponents,
              (el) =>
                el.project_build_component_id ===
                oldComponent.project_build_component_id
            )
            newInvitesComponent.push({ ...oldComponent, id: dataResponse.id })
            setInviteBuilComponent(newInvitesComponent)
            setBuildComponents(newBuildComponents)
            if (!newBuildComponents.length) {
              showTippyAddComponent.setValue(false)
            }
          }
        }
      )
    }
  const onClickDeleteComponentInInvitess =
    (oldComponent: InviteeBuilComponentDetail) => () => {
      if (!oldComponent.id) {
        return
      }
      if (Boolean(isSentInvitee.value)) {
        setOldBuildComponent(oldComponent)
        isFormDeleteComponent.setValue(true)
        return
      }
      showTippyAddComponent.setValue(false)
      deleteComponent(oldComponent)
    }
  const onDeleteComponentForm = () => {
    if (!oldBuildComponent.id) {
      return
    }
    deleteComponent(oldBuildComponent)
  }
  const deleteComponent = (oldComponent: InviteeBuilComponentDetail) => {
    isLoading.setValue(true)
    deleteProjectBuildInviteeAddComponentMiddleware(
      oldComponent.id,
      `${oldComponent.code}/${oldComponent.version}`,
      originInvites.conversation_id,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)

        if (type === STATUS_RESPONSE.SUCCESS) {
          const newBuildComponents = cloneDeep(buildComponents)
          const newInvitesComponent = cloneDeep(inviteBuilComponent)
          remove(newInvitesComponent, (el) => el.id === oldComponent.id)
          newBuildComponents.push(oldComponent)
          setInviteBuilComponent(newInvitesComponent)
          setBuildComponents(newBuildComponents)
          isFormDeleteComponent.setValue(false)
          setOldBuildComponent(emptyInviteeBuildComponentDetail)
        }
      }
    )
  }
  const openSendInvite = () => {
    umamiTracking(EVENT.INVITEE.SEND_INVITATION)
    if (!originInvites.id) {
      return
    }
    ///
    if (originInvites.email === "") {
      return toast(
        <LabelNotificationPage
          messenger="Please update invitee email!"
          type="warning"
        />
      )
    }
    ///
    showTippyAddComponent.setValue(false)
    isLoading.setValue(true)
    postProjectBuildSendInviteeMiddleware(
      originInvites.id,
      originInvites.user_id,
      Boolean(isSentInvitee.value) ? "Resend" : "Send",
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isSentInvitee.setValue(1)
          handleChangeData()
        }
      },
      originInvites.conversation_id
    )
  }
  const onChangeStatusInvitees = () => {
    if (!originInvites.id) {
      return
    }
    showTippyAction.setValue(false)
    isLoading.setValue(true)
    postProjectBuildInviteeActivateMiddleware(
      originInvites.id,
      statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
        ? "Deactivate"
        : "Activate",
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          statusInvites.setValue(
            statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
              ? BUILD_INVITEE_STATUS.DEACTIVATE
              : BUILD_INVITEE_STATUS.ACTIVATE
          )
        }
      },
      originInvites.email
    )
  }
  const onRedirectConversationDetail = () => {
    if (!originInvites.project_build_id || !originInvites.conversation_id) {
      return
    }
    //reset filter on conversation overview page to default
    handleSetLocalStorage({
      tab:
        projectBuild.created_by === userInfo.id
          ? MENU_TAB_CONVERSATION.FROM_ME
          : MENU_TAB_CONVERSATION.OTHERS,
    })
    pushTo(PATH.conversations, undefined, undefined, {
      conversationId: originInvites.conversation_id,
    })
  }
  const onSynComponentInvitee = () => {
    if (!originInvites.id) {
      return
    }
    isLoading.setValue(true)
    postProjectBuildInviteeSyncMiddleware(
      originInvites.id,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isSyncComponent.setValue(false)
          handleChangeData()
        }
      }
    )
  }

  const renderButtonActivate = () => {
    const nameButton = deactivateTab ? "Activate" : "Deactivate"
    return (
      <Button
        className="hidden md:block"
        title={nameButton}
        sizeBtn="small"
        colorBtn="white"
        onClick={onChangeStatusInvitees}
        styleButton={{
          marginLeft: 8,
        }}
      />
    )
  }

  const renderDotsIconConversation = () => {
    if (isUndefined(originInvites.is_read) || originInvites.is_read) {
      return
    }
    return (
      <div
        className="absolute"
        style={{
          height: 11,
          width: 11,
          background: "#EA4545",
          border: "3px solid #FFFFFF",
          borderRadius: "50%",
          top: 1,
          right: 1,
        }}
      ></div>
    )
  }
  const renderIconShowComponent = () => {
    if (
      !isViewShare &&
      Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE) &&
      sumComponent - inviteBuilComponent.length > 0
    ) {
      return (
        <div
          className="h-7 flex items-center cursor-pointer"
          onClick={onShowComponent}
        >
          <IconButton />
        </div>
      )
    }
    return null
  }
  const renderButtonSend = () => {
    if (isViewShare || !originInvites.email) {
      return null
    }
    const nameButtonSend = Boolean(isSentInvitee.value)
      ? "Resend"
      : "Send invite"
    return (
      <Button
        className="mt-2"
        title={nameButtonSend}
        sizeBtn="small"
        colorBtn="black"
        onClick={openSendInvite}
        disabled={deactivateTab}
        isDisabledBtn
      />
    )
  }
  const renderSelectComponent = () => (
    <div
      className="flex items-center justify-center cursor-pointer w-full h-7"
      onClick={
        !isViewShare &&
        Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE)
          ? onShowComponent
          : undefined
      }
    >
      {!isViewShare &&
      Boolean(statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE) ? (
        <React.Fragment>
          <IconButton />
          <p
            className="font-normal ml-1"
            style={{
              color: "#0A5AF5",
              lineHeight: "24px",
              fontSize: 14,
            }}
          >
            Select component
          </p>
        </React.Fragment>
      ) : null}
    </div>
  )
  const onChangeInviteePermissionToModifyComponent = () => {
    isLoading.setValue(true)
    updateInviteeModifyComponentPermissionMiddleWare(
      originInvites.id,
      originInvites.can_modify_component === 0 ? 1 : 0
    )
      .then((res) => {
        onEditInvites({
          ...originInvites,
          can_modify_component:
            originInvites.can_modify_component === 0 ? 1 : 0,
        })
        handleChangeData()
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message || "failed"}
            type="error"
          />
        )
      })
      .finally(() => {
        isLoading.setValue(false)
      })
  }

  return (
    <div
      className="flex flex-col bg-white px-3 pt-3 mb-6 last:mb-0"
      ref={refSelectComponent}
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 6,
        paddingBottom: 4,
      }}
    >
      <div
        className="flex flex-col pb-3 h-fit w-full gap-1"
        style={{
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-4">
            <p
              className="font-semibold max-w-461 overflow-hidden text-center text-ellipsis "
              style={{
                fontSize: 14,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#111111",
              }}
            >
              {" "}
              {originInvites.conversation_code}
            </p>
            {originInvites.project_build_components.some(
              (item) => !item.is_read
            ) && (
              <div className="items-center gap-1 hidden md:flex">
                <IconMessagerDeleteComponent className="icon-warning-messager" />
                <p
                  className="font-normal text-13 leading-22"
                  style={{
                    color: "#e87a16",
                  }}
                >
                  {"New version of components is submitted"}
                </p>
              </div>
            )}
          </div>

          <div className="flex items-center justify-between ">
            <Tippy
              // className="custom-tippy-menu-left"
              placement="top"
              arrow={false}
              content={
                <span
                  className="hidden md:block font-normal"
                  style={{
                    fontSize: 13,
                    lineHeight: "22px",
                  }}
                >
                  Allow user to create a new version of component and submit it
                  for approval. Once the version is approved, it will be
                  committed
                </span>
              }
            >
              <div>
                <SwitchButtonDefault
                  containerClass="ml-2"
                  checked={Boolean(originInvites.can_modify_component)}
                  disabled={isViewShare || deactivateTab}
                  labelButton="Allow edit"
                  onChange={onChangeInviteePermissionToModifyComponent}
                />
              </div>
            </Tippy>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="md:flex items-center overflow-hidden">
            <p
              className="font-semibold max-w-461 overflow-hidden text-ellipsis "
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#111111",
              }}
            >
              {originInvites.name}
            </p>
            <div
              className="hidden md:block h-1 w-1 mx-2"
              style={{
                backgroundColor: deactivateTab ? "#C7C7C7" : "#7A7A7A",
                borderRadius: "50%",
              }}
            />
            <p
              className="font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: deactivateTab ? "#C7C7C7" : "#7A7A7A",
              }}
            >
              {originInvites.email}
            </p>
            {!isViewShare ? renderButtonActivate() : null}
          </div>

          <div className="flex items-center">
            <ActionIconHistory
              classIcon="hidden md:block"
              icon="copy-link"
              tooltip="Copy link"
              styleIcon={{
                marginLeft: 12,
                marginRight: 0,
              }}
              disabled={isViewShare || deactivateTab}
              onClick={() => {
                if (!(isViewShare || deactivateTab)) isCopyLink.setValue(true)
              }}
            />
            {!isViewShare ? (
              <div>
                <ActionIconHistory
                  classIcon="hidden md:block"
                  icon="edit"
                  tooltip="Edit"
                  styleIcon={{
                    marginLeft: 12,
                    marginRight: 0,
                  }}
                  disabled={isViewShare || deactivateTab}
                  onClick={onEditInvites({
                    ...originInvites,
                    is_sent: isSentInvitee.value,
                    status: statusInvites.value,
                  })}
                />
                {/* tippy action on mobile */}
                <TippyCustomzie
                  containerClass="component-card-menu reset-tippy md:hidden"
                  placement="bottom-end"
                  interactive
                  arrow={false}
                  offset={[30, 5]}
                  animation="scale"
                  visible={showTippyAction.value}
                  onClickOutside={() => showTippyAction.setValue(false)}
                  content={
                    <div
                      style={{
                        background: "#222222",
                        borderRadius: 4,
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 165,
                      }}
                    >
                      {!deactivateTab && (
                        <ActionItem
                          title={"Edit invitee"}
                          onClick={onEditInvites({
                            ...originInvites,
                            is_sent: isSentInvitee.value,
                            status: statusInvites.value,
                          })}
                        />
                      )}
                      <ActionItem
                        title={deactivateTab ? "Activate" : "Deactivate"}
                        onClick={onChangeStatusInvitees}
                      />
                    </div>
                  }
                  allowHTML
                >
                  <div
                    className="flex items-center w-[30px] h-[30px] cursor-pointer"
                    onClick={() =>
                      showTippyAction.setValue(!showTippyAction.value)
                    }
                  >
                    <ActionIconHistory
                      icon="three-dots"
                      tooltip="Action"
                      styleIcon={{
                        marginRight: 0,
                      }}
                    />
                  </div>
                </TippyCustomzie>
              </div>
            ) : null}

            <div className="relative">
              <ActionIconHistory
                onClick={onRedirectConversationDetail}
                icon="conversation"
                tooltip={"Conversation"}
                disabled={
                  // !Boolean(isSentInvitee.value) ||
                  !originInvites.conversation_id || deactivateTab
                }
                styleIcon={{
                  marginLeft: 12,
                  marginRight: 0,
                }}
              />
              {renderDotsIconConversation()}
            </div>
          </div>
        </div>
        {originInvites.project_build_components.some(
          (item) => !item.is_read
        ) && (
          <div className="items-center gap-1 flex md:hidden">
            <IconMessagerDeleteComponent className="icon-warning-messager" />
            <p
              className="font-normal text-13 leading-22"
              style={{
                color: "#e87a16",
              }}
            >
              {"New version of components is submitted"}
            </p>
          </div>
        )}
      </div>
      {isCopyLink.value ? (
        <FormCopyLink
          onCloseModal={() => isCopyLink.setValue(false)}
          openModal={isCopyLink.value}
          originInvites={originInvites}
        />
      ) : null}
      <div className="flex my-3 w-full">
        <TippyCustomzie
          containerClass={`component-card-menu flex w-full ${
            inviteBuilComponent.length ? "" : "justify-center"
          } reset-tippy`}
          placement={inviteBuilComponent.length ? "bottom-start" : "bottom"}
          interactive
          arrow={false}
          animation="scale"
          visible={showTippyAddComponent.value}
          onClickOutside={onClickOutsideAddComponent}
          offset={[-12, 15]}
          allowHTML
          content={
            <div
              className="flex items-center gap-2 flex-wrap bg-white px-3 py-3 relative"
              style={{
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                borderRadius: 6,
                minHeight: 52,
                width: refSelectComponent.current?.clientWidth,
              }}
            >
              {buildComponents.length
                ? buildComponents.map((component, index) => (
                    <ComponentBuildItemCard
                      key={index}
                      component={component}
                      onClickRoot={onClickAddComponentInInvitess}
                      isDeleted={
                        component.status ===
                          ProjectComponentStatus.DeletedAttachment || isDeleted
                      }
                    />
                  ))
                : null}

              {isLoadingComponent.value ? (
                <div className="w-full flex justify-center items-center absolute top-0 left-0 right-0 bottom-0">
                  <HashLoader color="#F7AC1B" loading={true} size={28} />
                </div>
              ) : null}
            </div>
          }
        >
          <div className="flex items-center w-full">
            {inviteBuilComponent.length ? (
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full gap-2">
                  <div className="flex items-center flex-wrap gap-2">
                    {inviteBuilComponent.map((buildComponent, index) => (
                      <ComponentBuildItemCard
                        key={index}
                        component={buildComponent}
                        hasDeleteIcon={
                          !isViewShare &&
                          statusInvites.value === BUILD_INVITEE_STATUS.ACTIVATE
                        }
                        isDisabled={
                          statusInvites.value ===
                          BUILD_INVITEE_STATUS.DEACTIVATE
                        }
                        onDelete={onClickDeleteComponentInInvitess}
                        isDeleted={
                          buildComponent.status ===
                            ProjectComponentStatus.DeletedAttachment ||
                          isDeleted
                        }
                        tooltip={
                          <span
                            className="font-normal"
                            style={{
                              fontSize: 13,
                              lineHeight: "22px",
                              wordBreak: "break-word",
                            }}
                          >
                            Invitee submitted a new version of this component.
                          </span>
                        }
                      />
                    ))}
                  </div>
                  {renderIconShowComponent()}
                </div>
                {renderButtonSend()}
              </div>
            ) : (
              renderSelectComponent()
            )}
          </div>
        </TippyCustomzie>
      </div>

      {isFormDeleteComponent.value && (
        <ModalDelete
          onCloseModal={() => isFormDeleteComponent.setValue(false)}
          title={`Do you want to delete the component?`}
          content="Invitee will no longer view this component detail and this action can not be undo."
          label={`Confirmation`}
          onSubmit={onDeleteComponentForm}
          titleButton="Delete"
          styleContent={{
            textAlign: "center",
          }}
        />
      )}

      {isSyncComponent.value && (
        <ModalDelete
          onCloseModal={() => isSyncComponent.setValue(false)}
          title={`This action will sync your data with invitee and cannot be undone.`}
          content=""
          label={`Confirmation`}
          onSubmit={onSynComponentInvitee}
          colorYellowButton
          titleButton="Confirm"
          styleTitle={{
            textAlign: "center",
          }}
        />
      )}
    </div>
  )
}
export default InviteesItemCard

import Button from "components/Button/Button"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { useBoolean, useNumber } from "helpers/hooks"
import { useEffect, useState } from "react"
import { configureStore } from "stores/configureStore"
import { startLoading, closeLoading } from "reducers/loading"

import {
  getFileContent,
  getProjectBackupAssetURI,
  validateConversationNoteFile,
} from "helpers/utils"
import ProgressBar from "components/ProgressBar/ProgressBar"
import {
  getUserNoteFilesMiddleware,
  postSyncNoteFileMiddleware,
} from "pages/conversations/conversations.api"
import ConversationSyncTable from "./ConversationSyncTable"
import moment from "moment"

const ConversationSync = (props: {
  onchangeDoneSyncConversationNote: (isDone: boolean) => void
  isStartSync: boolean
}) => {
  const isLoading = useBoolean()
  const isShowProgressBar = useBoolean(false)
  const totalNumber = useNumber(0)
  const doneNumber = useNumber(0)
  let log = "File path, Hash of raw, Hash after decrypting, Status, Time"
  const [data, setData] = useState<any>([{ file_key: "", is_synced: 0 }])
  useEffect(() => {
    // console.log(props.isStartSync)
    if (!props.isStartSync) return
    const pendingNumber = data.filter((item) => !item.is_synced).length
    if (pendingNumber === 0) {
      props.onchangeDoneSyncConversationNote(true)
      localStorage.setItem("isDoneSyncConversationNote", "1")
    } else {
      props.onchangeDoneSyncConversationNote(false)
      localStorage.setItem("isDoneSyncConversationNote", "0")
      {
        handleOnclickSyncFile()
      }
    }
  }, [JSON.stringify(data), props.isStartSync])

  const getConversationNoteFiles = async () => {
    const userNoteFiles = await getUserNoteFilesMiddleware()
    if (userNoteFiles.length === 0) {
      props.onchangeDoneSyncConversationNote(true)
      localStorage.setItem("isDoneSyncConversationNote", "1")
    }
    totalNumber.setValue(userNoteFiles.length)
    doneNumber.setValue(userNoteFiles.filter((item) => item.is_synced).length)
    setData(userNoteFiles)
  }
  useEffect(() => {
    getConversationNoteFiles()
  }, [])

  const syncFile = async () => {
    isShowProgressBar.setValue(true)
    configureStore.dispatch(startLoading())

    //Sync conversation note files here
    await syncConversationNoteFiles(data)
    /* ------------------------------ */

    isShowProgressBar.setValue(false)
  }
  const syncConversationNoteFiles = async (
    files: { file_key: string; is_synced: boolean }[]
  ) => {
    const conversationNoteEncryptionKey =
      localStorage.getItem(EncryptionKeys.conversationNoteEncryptionKey) || ""
    for (let j = 0; j < files.length; j++) {
      const file = files[j]
      if (file.is_synced) {
        continue
      }
      const fileUrl = getProjectBackupAssetURI(file.file_key)
      const fileContentResponse = await getFileContent(fileUrl, true)
      const fileData = fileContentResponse.content
      const contentType = fileContentResponse.content_type
      const fileDataText = await fileData.text()
      // console.log(fileDataText)
      const rawHash = encryptionHelper.createFileHash(fileDataText)
      console.log("fileKey: ", file.file_key)
      console.log(
        `${moment().format("YYYY-MM-DD HH:mm:ss")} - hash of raw: `,
        rawHash
      )
      const fileKeyParts = file.file_key.split("/")
      const fileName = fileKeyParts[fileKeyParts.length - 1]
      const fileFormData = new FormData()
      const encryptedFileData = await encryptionController().encrypt(fileData, {
        dataType: "file",
        type: "conversation_note",
        encryptionKey: conversationNoteEncryptionKey,
      })
      const newBlob = new Blob([encryptedFileData], {
        type: contentType,
      })
      fileFormData.append("file", newBlob, fileName)
      fileFormData.append("key", file.file_key)
      await postSyncNoteFileMiddleware(fileFormData).then(async () => {
        doneNumber.setValue((prev) => prev + 1)
        // setData((prev) => {
        //   return prev.map((item, index) => {
        //     if (index === j) {
        //       return { ...item, is_synced: 1 }
        //     }
        //     return item
        //   })
        // })
        const logData = await validateConversationNoteFile(rawHash, file)
        if (logData) {
          log = log + logData
        }
      })
    }
    getConversationNoteFiles()
    // downloadLogCsv(log)
  }
  const downloadLogCsv = (data: string) => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "text/csv",
        })
      )

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "conversation_note_log.csv")
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnclickSyncFile = async () => {
    isLoading.setValue(true)

    await syncFile()
  }

  return data.length > 0 ? (
    <div className="p-6 pt-0">
      <h2 className="text-[16px] font-semibold lh-26 text-black mb-[8px] mt-[24px]">
        Sync Conversation notes status
      </h2>
      <ProgressBar
        title={"Sync note files ..."}
        isShow={isShowProgressBar.value}
        currentValue={doneNumber.value}
        totalValue={totalNumber.value}
      />
      <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
        <ConversationSyncTable data={data} />
      </div>
    </div>
  ) : null
}

export default ConversationSync

import Axios, { AxiosResponse } from "axios"
import { encryptionController } from "controllers/EncryptionController"
import { activityLogHelper } from "helpers/activity_log"
import { ActivityItemDetail, ParamsActivityLogs } from "./activity-log.type"

export const getActivitiesMiddleware = async (params: ParamsActivityLogs) => {
  const response: AxiosResponse<{
    data: ActivityItemDetail[]
  }> = await Axios.get("api/activity-log", {
    params,
  })
  return Promise.all(
    response.data.data.map(async (item) => {
      let result = await encryptionController().decrypt(item.content, {
        dataType: "string",
        type: "project",
      })
      if (item.param) {
        // console.log(result, JSON.parse(item.param))
        result = activityLogHelper.createStringFromTemplate(
          result,
          JSON.parse(item.param)
        )
      }
      return {
        ...item,
        content: result,
      }
    })
  )
}

import { useState, useEffect } from 'react';
import ModalCustom from "components/ModalCustom"
import { ReactComponent as LockIcon } from "assets/images/icons/lock-icon.svg"
import { ReactComponent as CopyIcon } from "assets/images/icons/icon-copy.svg"
import Typography from "components/Typography"
import Button from "components/Button/Button"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import {isFunction} from 'lodash';

interface Props {
  backUpKey: string
  onCloseModal: () => void
  onClickDownLoad: () => void
  onClickSubmit: () => void
  cancelText?: string
  submitText?: string
  handleDisplayCloseIcon?: () => () => void;
}
const RecoveryModal = ({
  backUpKey, onCloseModal, onClickDownLoad, onClickSubmit,
  cancelText = 'Close',
  submitText = 'Download',
  handleDisplayCloseIcon,
}: Props) => {
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    if (timeLeft === 0) {
       onClickDownLoad();
       return;
    }
    //
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000);
    //
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const copyToClipboard = () => {
    if (isFunction(navigator?.clipboard?.writeText)) {
      navigator.clipboard.writeText(backUpKey);
    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = backUpKey;
      // Prevent scrolling to bottom of page in MS Edge
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        // Security exception may be thrown by some browsers
        return document.execCommand("copy");
      } catch (ex) {
        toast(<LabelNotificationPage type="error" messenger="Failed to copy backup key." />)
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
    //
    toast(<LabelNotificationPage type="success" messenger={"Copied"} />)
  };

  return (
    <ModalCustom
      handleChangeButton={handleDisplayCloseIcon}
      renderHeader={
        <div className="flex gap-2 items-center">
          <LockIcon />
          <Typography
            className="text-base font-semibold"
            style={{
              color: "#222222",
              lineHeight: "26px",
            }}
          >
            Recovery code
          </Typography>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col gap-6">
          <div
            className="flex flex-col items-center gap-6"
            // style={contentWrapperStyles}
          >
            <p className="text-[#222222] text-[14px] font-normal leading-6">
              Below is your permanent 12-word recovery code. This code is
              required to reset your password.
            </p>
            <div className="text-black bg-grayWhite2 p-2 flex justify-center items-center">
              <p className="font-semibold leading-6 text-[14px] pr-[16px]">
                {backUpKey}
              </p>
              <div>
                <CopyIcon className="cursor-pointer" onClick={copyToClipboard} title="copy backup key" />
              </div>

            </div>

            <p className="text-[#222222] text-[14px] font-normal leading-6">
              Your recovery code download has started automatically<span className="font-semibold">{timeLeft > 0 ? ` in ${timeLeft} second${timeLeft === 1 ? '' : 's'}`: ''}</span>. If the
              download does not start, please click the Download button before
              closing this modal.
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
            <Button
              widthBtn={"100%"}
              title={cancelText}
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title={submitText}
              colorBtn="yellow"
              onClick={onClickSubmit}
            />
          </div>
        </div>
      }
    />
  )
}
export default RecoveryModal

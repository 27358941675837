import * as XLSX from "xlsx"
import { mapValues } from "lodash"

export default class WorkBook {
  private workBook: XLSX.WorkBook
  constructor(workBook: XLSX.WorkBook) {
    this.workBook = workBook
  }

  public toJSON = () => {
    return mapValues(this.workBook.Sheets, (sheetData) => {
      return XLSX.utils.sheet_to_json(sheetData)
    })
  }

  public getSheetNames = () => {
    return this.workBook.SheetNames
  }

  public getFirstSheetJSON = () => {
    return XLSX.utils.sheet_to_json(
      this.workBook.Sheets[this.workBook.SheetNames[0]],
      { defval: "" }
    ) as any[]
  }
}

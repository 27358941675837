import PageLayout from "pages/layout/PageLayout"
import { useWindowSize } from "helpers/hooks"
import TwoFactorSetting from "./organisms/TwoFactorSetting"
import { useAppSelector } from "hooks/useApp"
import { ReactComponent as IconNoAvatar } from "assets/images/icons/icon-no-avatar.svg"
import { API_URL } from "config/environments";
import { showImageUrl } from "helpers/utils"

const General = () => {
  const userInfo = useAppSelector((state) => state.userInfo)
  const size = useWindowSize()

  const URLAPIAvatar =
    userInfo && userInfo.avatar && userInfo.avatar.includes("public/")
      ? API_URL
      : undefined

  return (
    <PageLayout heightHeader={0} minWidth="min-w-[500px]">
      <div className="flex w-full h-full">
        <div
          className={`flex w-full justify-center ${
            size.height > 683 ? "items-center" : ""
          }`}
        >
          <div className="relative m-6 pt-12 w-[440px] hide-scroll">
            <div
              className="w-full bg-white pt-5 px-3 pb-3 flex flex-col"
              style={{
                border: "1px solid #E4E4E4",
                borderRadius: 6,
                minHeight: 650,
              }}
            >
              <div
                className="flex flex-col"
                style={{
                  paddingLeft: 150,
                  minHeight: 48,
                }}
              >
                <p
                  className="font-semibold"
                  style={{
                    fontSize: 16,
                    lineHeight: "26px",
                    color: "#111111",
                  }}
                >
                  {userInfo.fullname}
                </p>
                <p
                  className="font-medium mt-1"
                  style={{
                    fontSize: 12,
                    lineHeight: "18px",
                    color: "#7A7A7A",
                  }}
                >
                  {userInfo.id}
                </p>
              </div>
              <div className="flex mt-6 mb-3">
                <TwoFactorSetting forceEncryption />
              </div>
            </div>
            <div
              className={`absolute top-0 flex items-center justify-center
                ${
                  userInfo.avatar
                    ? " profile-avatar bg-no-repeat bg-center bg-cover"
                    : ""
                }
              `}
              style={{
                border: "1px solid #E4E4E4",
                backgroundImage: userInfo.avatar
                  ? `url(${showImageUrl(userInfo.avatar, URLAPIAvatar)})`
                  : "inherit",
                width: 128,
                height: 128,
                borderRadius: "50%",
                left: 12,
                backgroundColor: "#F7F7F7",
              }}
            >
              {!userInfo.avatar ? <IconNoAvatar /> : null}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default General

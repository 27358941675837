import { TippyCustomzie } from "components/TippyCustomzie"
import { useNumber } from "helpers/hooks"
import { ReactComponent as IconActionTable } from "assets/images/icons/icon-action-table-bom.svg"
import { cloneDeep, remove } from "lodash"
import { useEffect, useState } from "react"
import { handlePasteFromCell } from "helpers/bom"

interface Props {
  dataAddColumns: any
  setDataAddColumns: (newValue: any) => void
  isDisable: boolean
  defaultBomValues: string[]
}
const InviteeTableBOMAddColumn = (props: Props) => {
  const { dataAddColumns, setDataAddColumns, isDisable, defaultBomValues } =
    props
  const fieldActive = useNumber(-1)
  const [listActive, setListActive] = useState<boolean[]>([])

  useEffect(() => {
    setListActive(dataAddColumns.map(() => false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataAddColumns)])

  const handleOpenDeleteColumn = (oldIndex: number) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    const newListActive = cloneDeep(listActive)
    newListActive[oldIndex] = false
    setListActive(newListActive)
    const newData = cloneDeep(dataAddColumns)
    remove(newData, (_el, index) => index === oldIndex)
    setDataAddColumns(newData)
    fieldActive.setValue(-1)
  }
  const handleClickActionColumn = (newIdx: number) => () => {
    const newListActive = cloneDeep(listActive)
    newListActive[newIdx] = !newListActive[newIdx]
    setListActive(newListActive)
    fieldActive.setValue(newIdx)
  }

  const handleChangeInputHeader = (oldIndex: number) => (event) => {
    const newData = cloneDeep(dataAddColumns)
    newData[oldIndex].key = event.target.value
    setDataAddColumns(newData)
  }
  const handleChangeInputBody =
    (oldIndex: number, oldIndexRow: number) => (event) => {
      const newData = cloneDeep(dataAddColumns)
      newData[oldIndex].values[oldIndexRow] = event.target.value
      setDataAddColumns(newData)
    }
  const handleOnClickOutside = () => {
    const newListActive = cloneDeep(listActive)
    setListActive(newListActive.map((_el) => false))
    fieldActive.setValue(-1)
  }

  const onPaste =
    (type: "header" | "body", columnIndex: number, rowIndex: number) =>
    (event: any) => {
      const copiedData = event.clipboardData.getData("text/plain")
      if (copiedData === "") return

      const newData = handlePasteFromCell(
        dataAddColumns,
        defaultBomValues,
        copiedData,
        type,
        columnIndex,
        rowIndex,
        event
      )
      if (newData) {
        setDataAddColumns(newData)
      }
    }
  return (
    <div
      className="flex items-center"
      style={{
        borderRight: "1px solid #E4E4E4",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        marginTop: 10,
        backgroundColor: "#FFFFFF",
        paddingBottom: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      }}
    >
      {dataAddColumns.map((addColumn, indexAddColumn: number) => (
        <div
          key={indexAddColumn}
          className="flex flex-col cursor-pointer"
          style={{
            border:
              fieldActive.value === indexAddColumn
                ? "1px solid #FDBE44"
                : "none",
          }}
        >
          <div
            className={`flex relative cursor-pointer ${
              !isDisable ? "hover-disabled-button-action-table" : ""
            }`}
            style={{
              height: 48,
              width: 170,
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            <input
              className="focus:outline-none font-semibold px-3 focus-input-table"
              placeholder="Empty"
              value={addColumn.key}
              onChange={handleChangeInputHeader(indexAddColumn)}
              onPaste={onPaste("header", indexAddColumn, 0)}
              disabled={isDisable}
              style={{
                height: 47,
                width: 170,
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                backgroundColor: "transparent",
              }}
            />
            <TippyCustomzie
              containerClass="component-card-menu"
              placement="bottom"
              interactive
              arrow={false}
              animation="scale"
              visible={listActive[indexAddColumn]}
              onClickOutside={handleOnClickOutside}
              offset={[0, 0]}
              content={
                <div
                  className="flex items-center"
                  onClick={handleOpenDeleteColumn(indexAddColumn)}
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    padding: "0px 12px",
                    width: 140,
                    height: 48,
                  }}
                >
                  <p
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      color: "#EA4545",
                    }}
                  >
                    Delete
                  </p>
                </div>
              }
              allowHTML
            >
              <div
                className={`absolute flex items-center justify-center cursor-pointer ${
                  fieldActive.value === indexAddColumn
                    ? "active-button-action-table"
                    : "disabled-button-action-table"
                }`}
                onClick={handleClickActionColumn(indexAddColumn)}
                style={{
                  top: -10,
                  left: 71,
                  height: 20,
                  width: 28,
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                }}
              >
                <IconActionTable />
              </div>
            </TippyCustomzie>
          </div>
          <div>
            {addColumn.values.map((row, indexRow) => (
              <div
                className={`flex items-center`}
                style={{
                  height: 48,
                  width: 170,
                  backgroundColor: indexRow % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
                }}
                key={indexRow}
              >
                <input
                  className="focus:outline-none font-normal px-3 focus-input-table"
                  value={row}
                  disabled={isDisable}
                  placeholder="Empty"
                  onChange={handleChangeInputBody(indexAddColumn, indexRow)}
                  onPaste={onPaste("body", indexAddColumn, indexRow)}
                  style={{
                    height: 48,
                    width: 170,
                    fontSize: 14,
                    lineHeight: "24px",
                    color: "#111111",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default InviteeTableBOMAddColumn

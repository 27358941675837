import React from "react"
import { LayersRenderCardProps } from "../types"
import SvgLayerCard from "./SvgLayerCard"
import { showImageUrl } from "helpers/utils"
import PuffLoader from "react-spinners/PuffLoader"

const LayersRenderCard = (props: LayersRenderCardProps) => {
  const { layers, layerVisibility, isEncrypted } = props
  const [isLoading, setIsLoading] = React.useState(true)
  React.useEffect(() => {
    async function loadImages() {
      setIsLoading(true)
      const calls: Promise<unknown>[] = []
      layers.forEach((layer) => {
        calls.push(processLoadImage(layer.file))
      })
      await Promise.all(calls)
      setIsLoading(false)
    }
    if (!isLoading) {
      return
    }
    loadImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(layers)])

  const processLoadImage = (fileUrl: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = showImageUrl(fileUrl)
      img.onload = () => {
        resolve("success")
      }
      img.onerror = (e) => {
        reject(e)
      }
    })
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <PuffLoader color="#F7AC1B" loading={true} size={120} />
        </div>
      ) : null}
      {!isLoading &&
        layers.map((layer, index) => {
          const decoder = new TextDecoder("utf-8")
          const svgContent = decoder.decode(layer.file)
          return (
            <SvgLayerCard
              key={index}
              file={svgContent}
              color={layer.color}
              active={layerVisibility[index]}
              isSvgContent={isEncrypted}
            />
          )
        })}
    </React.Fragment>
  )
}

export default LayersRenderCard

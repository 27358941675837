import Button from "components/Button/Button"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import {
  encryptionController,
  EncryptionKeys,
  ProjectSensitiveData,
  ProjectSyncKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { useBoolean, useNumber } from "helpers/hooks"
import { addKeyToLocalStorageObject } from "helpers/local_storage"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { configureStore } from "stores/configureStore"
import { startLoading, closeLoading } from "reducers/loading"
import ProjectSyncTable from "./ProjectSyncTable"
import {
  getProjectSensitiveDataMiddleware,
  getUnEncryptedProjectsMiddleware,
  putProjectSyncContextDataMiddleware,
  getProjectFilesMiddleware,
  postSyncProjectBinaryFileMiddleware,
  postFileHashKeyMiddleware,
} from "./services/api"
import { ProjectDetail } from "./types"

import {
  getFileContent,
  getProjectBackupAssetURI,
  validateEncryptedFile,
} from "helpers/utils"
import ProgressBar from "components/ProgressBar/ProgressBar"
import PageLayout from "pages/layout/PageLayout"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import ConversationSync from "pages/conversations/ConversationSync"
import moment from "moment"

const ProjectSync = () => {
  const isLoading = useBoolean()
  const isClickedSync = useBoolean(false)
  const isShowProgressBar = useBoolean(false)
  const totalNumber = useNumber(0)
  const doneNumber = useNumber(0)
  let log = "File path, Hash of raw, Hash after decrypting, Status, Time"
  const [data, setData] = useState<any>([])
  const createProjectSyncKeys = (
    projectId: string,
    conversationIds: string[]
  ) => {
    const projectKey = encryptionHelper.createRandomKey()
    return {
      projectId: projectId,
      projectKey: projectKey,
      conversations: conversationIds.map((conversation) => ({
        conversationId: conversation,
        conversationKey: encryptionHelper.createRandomKey(),
      })),
    }
  }
  useEffect(() => {
    getProjects()
  }, [])
  const saveSyncKeysToLocal = (data: ProjectSyncKeys) => {
    addKeyToLocalStorageObject(EncryptionKeys.projectEncryptionKeys, {
      [data.projectId]: data.projectKey,
    })
    const conversationObject = data.conversations.reduce((pre, cur) => {
      return {
        ...pre,
        [cur.conversationId]: cur.conversationKey,
      }
    }, {})
    addKeyToLocalStorageObject(
      EncryptionKeys.conversationEncryptionKeys,
      conversationObject
    )
  }
  const encryptSyncKeys = (data: ProjectSyncKeys, userVaultKey: string) => {
    return {
      projectId: data.projectId,
      projectKey: encryptionHelper.encrypt(userVaultKey, data.projectKey),
      conversations: data.conversations.map((conversation) => ({
        conversationId: conversation.conversationId,
        conversationKey: encryptionHelper.encrypt(
          userVaultKey,
          conversation.conversationKey
        ),
      })),
    }
  }
  const encryptProjMessage = (message: string, key: string) => {
    return encryptionController().encrypt(message, {
      dataType: "string",
      type: "project",
      encryptionKey: key,
    })
  }
  const encryptSyncData = async (
    data: ProjectSensitiveData,
    keys: ProjectSyncKeys
  ) => {
    return {
      ...data,
      versions: await Promise.all(
        data.versions.map(async (version) => ({
          ...version,
          commit: await encryptProjMessage(version.commit, keys.projectKey),
        }))
      ),
      boms: await Promise.all(
        data.boms.map(async (bom) => ({
          ...bom,
          additional_json: await encryptProjMessage(
            bom.additional_json,
            keys.projectKey
          ),
          mouser_data: await encryptProjMessage(
            bom.mouser_data,
            keys.projectKey
          ),
        }))
      ),
      firmwares: await Promise.all(
        data.firmwares.map(async (firmware) => ({
          ...firmware,
          description: await encryptProjMessage(
            firmware.description,
            keys.projectKey
          ),
        }))
      ),
      softwares: await Promise.all(
        data.softwares.map(async (software) => ({
          ...software,
          description: await encryptProjMessage(
            software.description,
            keys.projectKey
          ),
        }))
      ),
      mechanicals: await Promise.all(
        data.mechanicals.map(async (mechanical) => ({
          ...mechanical,
          description: await encryptProjMessage(
            mechanical.description,
            keys.projectKey
          ),
        }))
      ),
      miscellaneous: await Promise.all(
        data.miscellaneous.map(async (miscellaneousItem) => ({
          ...miscellaneousItem,
          description: await encryptProjMessage(
            miscellaneousItem.description,
            keys.projectKey
          ),
        }))
      ),
      build_additional_infos: await Promise.all(
        data.build_additional_infos.map(async (build_additional_info) => ({
          ...build_additional_info,
          description: await encryptProjMessage(
            build_additional_info.description,
            keys.projectKey
          ),
        }))
      ),
      build_extra_infos: await Promise.all(
        data.build_extra_infos.map(async (build_extra_info) => ({
          ...build_extra_info,
          description: await encryptProjMessage(
            build_extra_info.description,
            keys.projectKey
          ),
        }))
      ),
      logs: await Promise.all(
        data.logs.map(async (log) => ({
          ...log,
          content: await encryptProjMessage(log.content, keys.projectKey),
        }))
      ),
      project_comments: await Promise.all(
        data.project_comments.map(async (project_comment) => ({
          ...project_comment,
          content: await encryptProjMessage(
            project_comment.content,
            keys.projectKey
          ),
        }))
      ),
      conversation_messages: await Promise.all(
        data.conversation_messages.map(async (message) => {
          const conversationKey = keys.conversations.find(
            (conversation) =>
              conversation.conversationId === message.conversation_id
          )
          if (conversationKey?.conversationKey) {
            return {
              ...message,
              content: await encryptionController().encrypt(message.content, {
                dataType: "string",
                type: "conversation",
                relationId: message.conversation_id,
              }),
            }
          }
          return message
        })
      ),
    }
  }
  const syncProjectContextData = async (project: ProjectDetail) => {
    const projectSensitiveData = await getProjectSensitiveDataMiddleware(
      project.id
    )
    const projectKeys: ProjectSyncKeys = createProjectSyncKeys(
      project.id,
      projectSensitiveData.conversation_ids
    )

    const userVaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    if (!isEmpty(userVaultKey)) {
      saveSyncKeysToLocal(projectKeys)
      const encryptedSyncKeys = encryptSyncKeys(projectKeys, userVaultKey)
      const encryptedData = await encryptSyncData(
        projectSensitiveData,
        projectKeys
      )
      await putProjectSyncContextDataMiddleware(project.id, {
        context_data: encryptedData,
        keys: encryptedSyncKeys,
      }).then((_res) => {
        setData((pre) => {
          return pre.map((item) => {
            if (item.id === project.id) {
              return {
                ...item,
                is_synced_context: 1,
              }
            }
            return item
          })
        })
      })
    } else {
      // isLoading.setValue(false)
      toast(
        <LabelNotificationPage
          messenger={"Not found user vault key!"}
          type={"error"}
        />
      )
    }
  }
  const getProjects = async () => {
    configureStore.dispatch(startLoading())
    const response = await getUnEncryptedProjectsMiddleware()
    const isDoneSyncConversationNote = localStorage.getItem(
      "isDoneSyncConversationNote"
    )
    if (!response.data[0] && isDoneSyncConversationNote === "1") {
      localStorage.setItem("isDoneSyncEncrypted", "1")
      pushTo(PATH.projects)
    }
    setData(response.data)
    totalNumber.setValue(response.total)
    doneNumber.setValue(response.done)
    response.data.map((project) => {
      if (!project.is_synced_context) {
        // isDisableSyncContext.setValue(false)
      }
      if (!project.is_synced_file || project.is_synced_file === -1) {
        // isDisableSyncFile.setValue(false)
      }
    })

    closeLoading()
  }
  const syncProjectData = async (projects) => {
    configureStore.dispatch(startLoading())
    for (let i = 0; i < projects.length; i++) {
      const project = projects[i]
      if (!project.is_synced_context) await syncProjectContextData(project)
    }
    // closeLoading()
  }
  const encryptProjectFileContent = (
    content: any,
    file: {
      file_key: string
      conversation_id?: string
    },
    projectKey: string,
    conversationKeys: any
  ) => {
    const encryptionKey = file.conversation_id
      ? conversationKeys[file.conversation_id]
      : projectKey
    if (encryptionKey) {
      return encryptionController().encryptUnknowFile(content, encryptionKey)
    }
  }

  const syncProjectFile = async (projects) => {
    isShowProgressBar.setValue(true)
    configureStore.dispatch(startLoading())
    //
    for (let i = 0; i < projects.length; i++) {
      const project = projects[i]
      console.log("-------------------------------------")
      console.log("projectId: ", project.id)
      const projectKey = JSON.parse(
        localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
      )[project.id]

      const conversationKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
      )

      if (project.is_synced_file !== 1) {
        //sync file data here
        const files: {
          file_key: string
          conversation_id?: string
        }[] = await getProjectFilesMiddleware(project.id)
        for (let j = 0; j < files.length; j++) {
          const file = files[j]
          const fileUrl = getProjectBackupAssetURI(file.file_key)
          const fileContentResponse = await getFileContent(fileUrl, true)
          // if (fileContentResponse.content_type === "") {
          //   continue
          // }
          const fileData = fileContentResponse.content
          const contentType = fileContentResponse.content_type
          const fileDataText = await fileData.text()
          // console.log(fileDataText)
          const rawHash = encryptionHelper.createFileHash(fileDataText)
          console.log("fileKey: ", file.file_key)
          console.log(
            `${moment().format("YYYY-MM-DD HH:mm:ss")} - hash of raw: `,
            rawHash
          )
          // save hash key here
          // console.log("ENCRYPT: ", project.id, file.conversation_id)
          await postFileHashKeyMiddleware(file.file_key, rawHash)
          const fileKeyParts = file.file_key.split("/")
          const fileName = fileKeyParts[fileKeyParts.length - 1]
          const fileFormData = new FormData()
          const encryptedFileData = await encryptProjectFileContent(
            fileData,
            file,
            projectKey,
            conversationKeys
          )
          const newBlob = new Blob([encryptedFileData], {
            type: contentType,
          })
          fileFormData.append("file", newBlob, fileName)
          fileFormData.append("key", file.file_key)
          await postSyncProjectBinaryFileMiddleware(
            project.id,
            fileFormData
          ).then(async () => {
            doneNumber.setValue((prev) => prev + 1)
            //upload binary
            // Validate file after encrypting.
            const logData = await validateEncryptedFile(
              rawHash,
              file,
              project.id
            )
            if (logData) {
              log = log + logData
            }
          })
        }
      }
    }
    isShowProgressBar.setValue(false)

    getProjects()
    // downloadLogCsv(log)
    isClickedSync.setValue(false)
    closeLoading()
  }

  const downloadLogCsv = (data: string) => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "text/csv",
        })
      )

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "project_log.csv")
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnclickSyncContext = async () => {
    isLoading.setValue(true)
    const projects = data

    await syncProjectData(projects)
    handleOnclickSyncFile()
  }
  const handleOnclickSyncFile = async () => {
    isLoading.setValue(true)
    const projects = data

    await syncProjectFile(projects)
  }
  const handleChangeSyncConversationNote = (isDone: boolean) => {
    console.log(isDone)
    if (isDone) {
      handleOnclickSyncContext()
    }
  }
  const handleSyncData = async () => {
    isClickedSync.setValue(true)
  }
  return (
    <PageLayout heightHeader={0} minWidth="min-w-[500px]">
      <div className="p-6 pb-0">
        <p style={{ paddingBottom: 10, color: "red" }}>
          * For security purpose, we require you to sync all un-encrypted data
          to be encrypted. The process maybe long. Please ensure to keep the
          browser open while the sync process!
        </p>
      </div>
      <div className="p-6 flex flex-row justify-end">
        <div className="my-3">
          <Button
            title="Sync Data"
            onClick={handleSyncData}
            disabled={isClickedSync.value}
            isDisabledBtn
            sizeBtn="large"
          />
        </div>
      </div>
      <ConversationSync
        onchangeDoneSyncConversationNote={handleChangeSyncConversationNote}
        isStartSync={isClickedSync.value}
      />
      <div className="p-6 pt-0">
        <h2 className="text-[16px] font-semibold lh-26 text-black mb-[8px] mt-[24px]">
          Sync Project status
        </h2>
        <ProgressBar
          title={"Sync project files ..."}
          isShow={isShowProgressBar.value}
          currentValue={doneNumber.value}
          totalValue={totalNumber.value}
        />
        <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
          <ProjectSyncTable data={data} />
        </div>
      </div>
    </PageLayout>
  )
}

export default ProjectSync

import React from "react"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  UnreadConversation,
  emptyUnreadBuild,
  emptyUnreadConversation,
} from "./../../conversations/conversations.type"
import Axios, { AxiosResponse, AxiosError } from "axios"
import {
  SignUpRequest,
  LoginRequest,
  ForgetPasswordRequest,
  PasswordResetRequest,
  UserInfo,
  ChangePasswordRequest,
  InformationUserRequest,
  PrivacyDetail,
  CountryDataProp,
  InviteSentSignUpRequest,
  LoginResponse,
  EnableEncryptionRequest,
  CompanyRole,
} from "../types"
import { STATUS_RESPONSE } from "types"
import {
  setUnreadBuildAction,
  setUnreadConversationAction,
  setUserInfoAction,
} from "../stores/actions"
import { configureStore } from "stores/configureStore"
import { UnreadBuild } from "pages/conversations/conversations.type"
import { encryptionHelper } from "helpers/encryption"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { toast } from "react-toastify"
const {
  generateMasterKeyAndPasswordHashed,
  decryptVaultKeyAndStore,
  decryptConversationNoteKeyAndStore,
} = encryptionController()
export const signUpMiddleware = async (
  request: SignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  const { password, email, backupKey } = request
  const { masterKey, passwordHash } = await generateMasterKeyAndPasswordHashed(
    password,
    email
  )
  const vaultKey = encryptionHelper.createRandomKey()
  const encryptedVaultkey = encryptionHelper.encrypt(
    masterKey,
    vaultKey
  ) as string
  const encryptedBackUpkey = encryptionHelper.encrypt(
    backupKey,
    vaultKey
  ) as string
  const conversationNoteKey = encryptionHelper.createRandomKey()
  const encryptedConversationNoteKey = encryptionHelper.encrypt(
    vaultKey,
    conversationNoteKey
  ) as string
  const newRequest = {
    ...request,
    password: passwordHash,
    encryptedVaultkey,
    encryptedBackUpkey,
    encryptedConversationNoteKey,
  }
  Axios.post(`/api/auth/register`, newRequest)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Sign up failed!"
      )
    })
}

export const checkEmailExist = async (email: string) => {
  try {
    await Axios.get(`/api/auth/check-email`, {
      params: {
        email,
      },
    })
    return false
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      toast(
        React.createElement(LabelNotificationPage, {
          messenger: MESSENGER_NOTIFICATION.EMAIL_EXIST,
          type: "error",
        })
      )
      return true
    }

    return false
  }
}

export const loginMiddleware = async (request: LoginRequest) => {
  const { password, email } = request
  let newRequest = request
  let generatedMasterKey = ""
  const isEncryptedUser: AxiosResponse<{
    data: {
      is_encrypted: boolean
    }
  }> = await Axios.get("/api/user/is-encrypted", {
    params: {
      email,
    },
  })
  if (isEncryptedUser.data.data.is_encrypted) {
    const { masterKey, passwordHash } =
      await generateMasterKeyAndPasswordHashed(password, email)
    generatedMasterKey = masterKey
    newRequest = { ...request, password: passwordHash }
  }

  return Axios.post<LoginResponse>(`/api/auth/login`, newRequest).then(
    (response) => {
      //decrypt encrypted vaultKey
      if (isEncryptedUser.data.data.is_encrypted) {
        decryptVaultKeyAndStore(
          response.data.encryptedVaultkey,
          generatedMasterKey
        )
        const vaultKey = encryptionHelper.decrypt(
          generatedMasterKey,
          response.data.encryptedVaultkey
        ) as string
        decryptConversationNoteKeyAndStore(
          response.data.encryptedConversationNoteKey,
          vaultKey
        )
      }

      localStorage.setItem(
        "isDoneSyncEncrypted",
        response.data.isDoneSyncEncrypted
      )

      localStorage.setItem(
        "enabled_encrypted",
        isEncryptedUser.data.data.is_encrypted ? "1" : "0"
      )
      if (response.data.encryptedVaultkey && response.data.projectKeys) {
        const projectEncryptionKeys = JSON.parse(
          localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
        )
        if (response.data.projectKeys) {
          const userVaultKey = localStorage.getItem(
            EncryptionKeys.userVaultKey
          ) as string
          const decryptedKeys = response.data.projectKeys.reduce((pre, cur) => {
            return {
              ...pre,
              [cur.id]: encryptionHelper.decrypt(
                userVaultKey,
                cur.encryptedKey
              ),
            }
          }, {})
          localStorage.setItem(
            EncryptionKeys.projectEncryptionKeys,
            JSON.stringify({
              ...projectEncryptionKeys,
              ...decryptedKeys,
            })
          )
        }
      }
      return response.data
    }
  )
}

export const enableEncryptionMiddleware = async (
  request: EnableEncryptionRequest
) => {
  return Axios.post("/api/user/enable-encryption", request)
}

export const forgetPasswordMiddleware = (
  request: ForgetPasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/forgot-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Forgot password failed!"
      )
    })
}
export const passwordResetMiddleware = (
  request: PasswordResetRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/reset-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Password Reset failed!"
      )
    })
}

export const verifyEmailMiddleware = (
  verificationToken: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/verify/${verificationToken}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorVerify) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorVerify.response?.data?.message ?? "Email verification failed!"
      )
    })
}
export const reSendEmailMiddleware = (
  type: string,
  email: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/resend-email/${type}/${email}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorResend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorResend.response.data?.message ?? "Email verification failed!"
      )
    })
}

export const getUserMiddleware = (
  callback?: (type: STATUS_RESPONSE) => void
) => {
  Axios.get(`/api/user/me`)
    .then((response: AxiosResponse<{ data: UserInfo }>) => {
      configureStore.dispatch(setUserInfoAction(response.data.data))
      localStorage.setItem("user_id", response.data.data.id)
      localStorage.setItem("userEmail", response.data.data.email)
      localStorage.setItem("userFullName", response.data.data.fullname)
      localStorage.setItem(
        "enabled_encrypted",
        response.data.data.is_encrypted ? "1" : "0"
      )

      // setting timezone
      if (response.data.data.timezone) {
        localStorage.setItem("timezone", response.data.data.timezone)
      }
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS)
      }
    })
    .catch((_error: AxiosError) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR)
      }
    })
}
export const getUnreadBuildMiddleware = () => {
  Axios.get("/api/conversation/unread-build")
    .then((response: AxiosResponse<{ data: UnreadBuild }>) => {
      configureStore.dispatch(setUnreadBuildAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(setUnreadBuildAction(emptyUnreadBuild))
    })
}

export const getUnreadConversationMiddleware = () => {
  Axios.get("/api/conversation/unread")
    .then((response: AxiosResponse<{ data: UnreadConversation }>) => {
      configureStore.dispatch(setUnreadConversationAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(
        setUnreadConversationAction(emptyUnreadConversation)
      )
    })
}

export const changePasswordMiddleware = async (
  request: ChangePasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  let payload = request
  const enableEncrypted = localStorage.getItem("enabled_encrypted")
  if (enableEncrypted === "1") {
    const { generatePasswordHashed } = encryptionController()
    payload = {
      currentPassword: await generatePasswordHashed(request.currentPassword),
      newPassword: await generatePasswordHashed(request.newPassword),
      confirmedNewPassword: await generatePasswordHashed(
        request.confirmedNewPassword
      ),
    }
  }

  Axios.post(`/api/user/change-password`, payload)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Change password failed!"
      )
    })
}

export const updateInformationMiddleware = (
  request: InformationUserRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.put(`/api/user/update`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Update Information failed!"
      )
    })
}

export const getFAQMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/faq`)
  return response.data.data
}

export const getPrivacyPolicyMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/privacy-policy`)
  return response.data.data
}

export const getTermOfUseMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/term-of-use`)
  return response.data.data
}

// Order API
export const getCountryMiddleware = async (params?: {
  filter: { country: string }
}) => {
  const response: AxiosResponse<{
    data: CountryDataProp[]
  }> = await Axios.get(`/api/order/country`, { params })
  return response.data.data
}

export const getStatesMiddleware = async (params: {
  filter?: { state: string }
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: string[]
  }> = await Axios.get(`/api/order/state`, { params })
  return response.data.data
}

export const getCitiesMiddleware = async (params: {
  filter?: { city: string }
  state?: string
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: { postalCode: string; city: string }[]
  }> = await Axios.get(`/api/order/city`, { params })
  return response.data.data
}
export const checkUserIsActivatedMiddleware = async (email: string) => {
  const response = await Axios.get(`/api/auth/is-activated?email=${email}`)
  return response.data.isActivated
}
export const inviteSentSignupMiddleware = async (
  verificationToken: string,
  request: InviteSentSignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  const { email, password, backupKey, ...rest } = request
  const { masterKey, passwordHash } = await generateMasterKeyAndPasswordHashed(
    password,
    email
  )
  const vaultKey = encryptionHelper.createRandomKey()
  const encryptedVaultKey = encryptionHelper.encrypt(
    masterKey,
    vaultKey
  ) as string
  const encryptedBackupKey = encryptionHelper.encrypt(
    masterKey,
    backupKey
  ) as string
  const conversationNoteKey = encryptionHelper.createRandomKey()
  const encryptedConversationNoteKey = encryptionHelper.encrypt(
    vaultKey,
    conversationNoteKey
  ) as string
  const newRequest = {
    ...rest,
    email,
    password: passwordHash,
    confirmedPassword: passwordHash,
    encryptedVaultKey,
    encryptedBackupKey,
    encryptedConversationNoteKey,
  }
  Axios.post(
    `/api/auth/create-password-and-verify/${verificationToken}`,
    newRequest
  )
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? " Invite Sent Sign up failed!"
      )
    })
}

export const settingTwoFactor = async (type: "enable" | "disable") => {
  return Axios.post(`/api/auth/two-factor/${type}`).then((response) => {
    getUserMiddleware()
    return response.data
  })
}
export const resendCodeTwoFactor = async (token: string) => {
  return Axios.post<LoginResponse>(
    `/api/auth/two-factor/resend-code?token=${token}`
  ).then((response) => {
    return response.data
  })
}
export const verifyTwoFactor = async (code: string, token: string) => {
  return Axios.post<LoginResponse>(
    `/api/auth/two-factor/verify?token=${token}`,
    { code }
  ).then((response) => {
    return response.data
  })
}

export const getCompanyRoles = async () => {
  const res = await Axios.get<{ data: CompanyRole[] }>(
    "/api/auth/get-company-roles"
  )
  return res.data.data
}

import {
  SpecificationCardProps,
  TYPE_PARTS_SPECIFICATION,
  PartsSpecificationDetail,
} from "../types"
import RootPartSingleCheck from "../molecules/RootPartSingleCheck"
import SpecificationSingleInputCard from "../molecules/SpecificationSingleInputCard"
import AdditionalOptionsSpecification from "../molecules/AdditionalOptionsSpecification"
import SpecificationEditor from "../molecules/SpecificationEditor"
import SingleTwoLinesCheck from "../molecules/SingleTwoLinesCheck"
import RadioButton from "../molecules/RadioButton"
import CheckboxSingle from "../molecules/CheckboxSingle"
import SelectSpecification from "../molecules/SelectSpecification"
import MultipleCheckSpecification from "../molecules/MultipleCheckSpecification"
import SpecificationSingleCheckCard from "../molecules/SpecificationSingleCheckCard"

const SpecificationCard = (props: SpecificationCardProps) => {
  const { item, disabled = false, conversationId, versionId, handleAddMultipartFile } = props
  const handleAddFile = (files: any) => {
    handleAddMultipartFile(files)
  }
  const renderItemCard = (part: PartsSpecificationDetail) => {
    switch (part.type) {
      case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_IMAGE:
      case TYPE_PARTS_SPECIFICATION.SINGLE_IMAGE_CHECK:
      case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK:
        if (!part.answers) {
          return <div />
        }
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={
              <SpecificationSingleCheckCard item={part} disabled={disabled} />
            }
          />
        )
      case TYPE_PARTS_SPECIFICATION.SINGLE_CHECK_OR_INPUT:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={
              <SpecificationSingleCheckCard
                item={part}
                isInput
                disabled={disabled}
              />
            }
          />
        )
      case TYPE_PARTS_SPECIFICATION.DOUBLE_INPUT:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            requiredName={Number(part.required) === 1 ? true : false}
            rootClassInfo="flex items-center h-[30px]"
            childrenBody={
              <SpecificationSingleInputCard
                minInput={0}
                item={part}
                unit="X"
                nameSpecification={item.name}
                heightImage={67}
                disabled={disabled}
              />
            }
          />
        )
      case TYPE_PARTS_SPECIFICATION.CHECKBOX:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={<CheckboxSingle item={part} disabled={disabled} />}
          />
        )
      case TYPE_PARTS_SPECIFICATION.EDITOR:
        return (
          <SpecificationEditor
            item={part}
            readOnly={disabled}
            conversationId={conversationId}
            versionId={versionId}
            handleChangeFiles={handleAddFile}
          />
        )
      case TYPE_PARTS_SPECIFICATION.SELECT:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={
              <SelectSpecification item={part} disabled={disabled} />
            }
          />
        )
      case TYPE_PARTS_SPECIFICATION.SINGLE_TWO_LINES_CHECK:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={
              <SingleTwoLinesCheck item={part} disabled={disabled} />
            }
          />
        )
      case TYPE_PARTS_SPECIFICATION.RADIO_BUTTON:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={<RadioButton item={part} disabled={disabled} />}
          />
        )
      case TYPE_PARTS_SPECIFICATION.MULTIPLE_CHECK:
        return (
          <RootPartSingleCheck
            name={part.name}
            tooltip={part.tooltip}
            note={part.note}
            childrenBody={
              <MultipleCheckSpecification item={part} disabled={disabled} />
            }
          />
        )
      default:
        return (
          <AdditionalOptionsSpecification item={part} disabled={disabled} />
        )
    }
  }

  return (
    <div className="flex flex-col">
      {item.parts.length
        ? item.parts.map((part, index) => (
            <div key={index} className="flex flex-col">
              {renderItemCard(part)}
            </div>
          ))
        : null}
    </div>
  )
}

export default SpecificationCard

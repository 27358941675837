import React, { useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import { PasswordResetRequest, defaultPasswordResetRequest } from "./types"
import { useBoolean } from "helpers/hooks"
import { pushTo } from "helpers/history"
import { STATUS_RESPONSE } from "types"
import { PATH } from "constants/path"
import { passwordResetMiddleware } from "./services/api"
import {
  isValidEmail,
  searchParams,
  umamiTracking,
  validationPassword,
} from "helpers/utils"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import Button from "components/Button/Button"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { EVENT } from "constants/events"
import { encryptionHelper } from "helpers/encryption"
import { encryptionController } from "controllers/EncryptionController"
import { cloneDeep } from "lodash"
const PasswordReset = () => {
  const {
    statusInputConfirm,
    statusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  } = useChangeLabelStatusInputPasswordAndConfirm()
  const { generateMasterKeyAndPasswordHashed } = encryptionController()
  const [request, setRequest] = useState<PasswordResetRequest>(
    defaultPasswordResetRequest
  )
  const isLoading = useBoolean()
  const [backUpKeyArray, setBackupKeyArray] = useState<string[]>(
    Array(12).fill("")
  )
  const newResetPasswordToken = searchParams("token") || ""
  const encryptedBackUpVaultkey = decodeURIComponent(searchParams("key") || "")
  const email = decodeURIComponent(searchParams("email") || "")
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleChangeInput =
    (key: "confirmedPassword" | "password") => (event) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      })
      if (key === "password") {
        if (request.confirmedPassword) {
          getStatusInputConfirm(event.target.value, request.confirmedPassword)
        }
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedPassword") {
        getStatusInputConfirm(event.target.value, request.password)
      }
    }

  const isBackUpArrayFilledUp = () => {
    for (let index = 0; index < backUpKeyArray.length; index++) {
      if (!backUpKeyArray[index]) return false
    }
    return true
  }
  const handleDisableButton = () => {
    if (
      !validationPassword(request.password) ||
      request.password !== request.confirmedPassword ||
      (encryptedBackUpVaultkey && !isBackUpArrayFilledUp())
    ) {
      return true
    }
    return false
  }

  const onClickButton = async () => {
    if (!executeRecaptcha) {
      return toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
    }
    const newRequest = cloneDeep(request)

    let vaultKey = ""
    isLoading.setValue(true)
    try {
      if (encryptedBackUpVaultkey) {
        if (!isValidEmail(email)) {
          return toast(
            <LabelNotificationPage messenger={"Invalid email."} type="error" />
          )
        }
        const backupKey = backUpKeyArray.join(" ")
        vaultKey = encryptionHelper.decrypt(
          backupKey,
          encryptedBackUpVaultkey
        ) as string
        if (!vaultKey) {
          return toast(
            <LabelNotificationPage
              messenger={"Your recovery code is incorrect!"}
              type="error"
            />
          )
        }
        const { masterKey, passwordHash } =
          await generateMasterKeyAndPasswordHashed(request.password, email)
        newRequest.password = passwordHash
        newRequest.confirmedPassword = passwordHash
        newRequest.encryptedVaultKey = encryptionHelper.encrypt(
          masterKey,
          vaultKey
        ) as string
      }
    } catch (error) {
      return toast(
        <LabelNotificationPage
          messenger={"Your recovery code is incorrect!"}
          type="error"
        />
      )
    }
    try {
      const token = await executeRecaptcha(ACTION_RECAPTCHA.PASSWORD_RESET)
      const dataRequest: PasswordResetRequest = {
        ...newRequest,
        captcha: token,
        resetPasswordToken: newResetPasswordToken,
      }
      umamiTracking(EVENT.FORGOT_PASSWORD)
      passwordResetMiddleware(
        dataRequest,
        (type: STATUS_RESPONSE, messenger) => {
          isLoading.setValue(false)
          if (type === STATUS_RESPONSE.SUCCESS) {
            pushTo(PATH.resetSuccess)
          } else {
            toast(
              <LabelNotificationPage messenger={messenger ?? ""} type="error" />
            )
          }
        }
      )
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }
  const onPasteBackUpKey = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const backUpKey = e.clipboardData.getData("text")
    const newBackUpKeyArray = backUpKey.split(" ")
    if (newBackUpKeyArray.length === 1) return
    e.preventDefault()
    e.stopPropagation()
    const unFillKeyLength = 12 - newBackUpKeyArray.length
    const unFillKey = Array(unFillKeyLength).fill("")
    setBackupKeyArray([...newBackUpKeyArray, ...unFillKey])
  }
  const onChangeBackUpKey = (index: number, value: string) => {
    backUpKeyArray[index] = value
    setBackupKeyArray([...backUpKeyArray])
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        <Header title="Set new password" />
        <div className="my-6 ">
          {encryptedBackUpVaultkey && (
            <FormInputCard title="Enter 12 words of your recovery code in the correct order, or copy and paste all words into the first field.">
              <div className="flex flex-auto justify-center md:justify-start flex-wrap gap-3 mt-2">
                {backUpKeyArray.map((value, index) => {
                  return (
                    <div className="flex gap-2 justify-end items-center w-[150px]">
                      <p className="text-sm font-semibold text-black font-body">
                        {index + 1}.
                      </p>
                      <InputDefault
                        type="text"
                        styleRootInput={{
                          width: 124,
                        }}
                        onPaste={onPasteBackUpKey}
                        value={value}
                        onChange={(e) =>
                          onChangeBackUpKey(index, e.target.value)
                        }
                      />
                    </div>
                  )
                })}
              </div>
            </FormInputCard>
          )}
          <FormInputCard
            title="New Password"
            isTippy
            label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
          >
            <InputDefault
              type="password"
              isPassword
              value={request.password}
              onChange={handleChangeInput("password")}
              onKeyPress={onKeyPress}
              status={statusInputPassword.status}
              labelStatus={statusInputPassword.label}
            />
          </FormInputCard>
          <FormInputCard title="Confirm New Password">
            <InputDefault
              type="password"
              isPassword
              value={request.confirmedPassword}
              onChange={handleChangeInput("confirmedPassword")}
              onKeyPress={onKeyPress}
              status={statusInputConfirm.status}
              labelStatus={statusInputConfirm.label}
            />
          </FormInputCard>
        </div>
        <Button
          title="Save"
          onClick={onClickButton}
          isDisabledBtn
          widthBtn="100%"
          disabled={handleDisableButton()}
        />
      </div>
    </PageLayoutAuth>
  )
}

export default PasswordReset

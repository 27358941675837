import ItemWrapper from "./ItemWrapper"
import Button from "components/Button/Button"
import ModalCustom from "components/ModalCustom"
import { useBoolean } from "helpers/hooks"
import { EmailList } from "constants/index"
import { UserOverview } from "../dashboard.type"
import { exportUserData } from "../api.services"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { downloadEncrypted, umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"

interface QuotaPopoverProps {
  onClose: () => void
}
interface QuotationProps {
  data: UserOverview
  getDashboardOverview: () => void
}

const PrepareContent = (props: QuotaPopoverProps) => {
  return (
    <div className="flex flex-col">
      <p className="text-center">
        Your request is already being processed.
        <br />
        This can take a few hours to complete. When your data is ready, we will
        notify to you at your registered email. Thank you for your patience!
      </p>
      <div className="flex items-center justify-between mt-8">
        <Button widthBtn="100%" title="Understand" onClick={props.onClose} />
      </div>
    </div>
  )
}
const ExceededContent = (props: QuotaPopoverProps) => {
  const handleOpenMailSupport = () => {
    const a = document.createElement("a")
    a.setAttribute("href", `mailto:${EmailList.support}`)
    a.setAttribute("target", "_blank")
    a.click()
    a.remove()
  }
  return (
    <div className="flex flex-col">
      <p className="text-center">
        You exceeded quota for this quarter, please contact{" "}
        <a
          className="text-blue"
          target="_blank"
          href={`mailto:${EmailList.support}`}
        >
          {EmailList.support}
        </a>{" "}
        for support
      </p>
      <div className="flex items-center justify-between mt-8 gap-3">
        <Button
          widthBtn="100%"
          title="Cancel"
          colorBtn="white"
          onClick={props.onClose}
        />
        <Button
          widthBtn="100%"
          title="Contact support"
          type="submit"
          onClick={handleOpenMailSupport}
        />
      </div>
    </div>
  )
}

const Quotation = (props: QuotationProps) => {
  const { data, getDashboardOverview } = props
  const { quota_number, quota_limit, renew_at } = data
  const isOpen = useBoolean(false)
  const onClosePopover = () => isOpen.setValue(false)
  const isExceeded = useBoolean(true)
  const handleExportUserData = () => {
    umamiTracking(EVENT.EXPORT_QUOTA)
    if (quota_number >= quota_limit) {
      return isOpen.setValue(true)
    }
    // isExceeded.setValue(false)
    // isOpen.setValue(true)
    // exportUserData()
    //   .then(() => {
    //     getDashboardOverview()
    //   })
    //   .catch(() => {
    //     toast(
    //       <LabelNotificationPage
    //         messenger={MESSENGER_NOTIFICATION.GENERAL.UKNOWN_ERROR}
    //         type="error"
    //       />
    //     )
    //   })
    downloadEncrypted(`/api/user/files`)
      .then(() => {
        getDashboardOverview()
      })
      .catch(() => {
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.GENERAL.UKNOWN_ERROR}
            type="error"
          />
        )
      })
  }

  return (
    <ItemWrapper className="relative">
      <p className="text-sm font-semibold">All-data quota</p>
      <p className="text-base mb-[24px] font-semibold">
        {quota_number}/{quota_limit}
      </p>
      <p className="text-xs font-medium">Renew date:</p>
      <p className="text-sm mb-[12px] font-semibold">
        {quota_number === 0 || quota_number < quota_limit ? `-/-/-` : renew_at}
      </p>
      <p className="text-[13px] font-normal h-auto md:h-[60px] overflow-hidden md:dashboard-quotation-text">
        You can export all of your user data with this feature. Please note that
        this can only be performed once every 90 days. When you use the button
        below to make an export request, your data will be available for 1 week
        after the link is made.
      </p>
      <Button
        title="Export data"
        sizeBtn="small"
        colorBtn="yellow"
        className="hidden md:block"
        styleButton={{
          position: "absolute",
          bottom: 12,
          left: 12,
        }}
        onClick={handleExportUserData}
      />
      {isOpen.value ? (
        <ModalCustom
          label={
            isExceeded.value
              ? "Quota limit exceeded"
              : "We are preparing your data for download"
          }
          handleChangeButton={(_k) => onClosePopover}
          bodyChildren={
            isExceeded.value ? (
              <ExceededContent onClose={onClosePopover} />
            ) : (
              <PrepareContent onClose={onClosePopover} />
            )
          }
        />
      ) : undefined}
    </ItemWrapper>
  )
}
export default Quotation

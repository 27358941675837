import { Route, Redirect } from "react-router-dom"
import {
  isLogin,
  isAccountEncrypted,
  checkAccountDoneSyncEncrypted,
} from "config/environments"
import { PATH } from "constants/path"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { pathname, search } = rest.location
  if (!isLogin()) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: PATH.login,
              state: { redirectUrl: pathname, search },
            }}
          />
        )}
      />
    )
  }
  // force user enable encrytion
  const isEnableEncryptionPath = pathname.indexOf(PATH.enableEncryption) > -1
  const accountEncrypted = isAccountEncrypted()
  if (!accountEncrypted && !isEnableEncryptionPath) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: PATH.enableEncryption,
              state: { redirectUrl: pathname, search },
            }}
          />
        )}
      />
    )
  }
  // force user sync un-encrypted data
  const isEnableSyncPath = pathname.indexOf(PATH.projectSensitiveData) > -1
  if (accountEncrypted) {
    const isDoneSyncEncrypted =
      checkAccountDoneSyncEncrypted()
    if (!isDoneSyncEncrypted && !isEnableSyncPath) {
      return (
        <Route
          {...rest}
          render={() => (
            <Redirect to={{ pathname: PATH.projectSensitiveData }} />
          )}
        />
      )
    }
  }
  // after enable encryption user can not go to this route again
  if (isEnableEncryptionPath && accountEncrypted) {
    return (
      <Route
        {...rest}
        render={() => <Redirect to={{ pathname: PATH.projects }} />}
      />
    )
  }

  return <Route {...rest} render={() => Component} />
}

export default PrivateRoute

/* eslint-disable react-hooks/exhaustive-deps */
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { useWindowSize } from "helpers/hooks"
import { convertUrlBase64, parseDraftToHtml } from "helpers/utils"
import { dataEditorDefault } from "pages/project-component-detail/types"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  deleteProjectBuildCreateAdditionalInfoMiddleware,
  postProjectBuildCreateAdditionalInfoMiddleware,
} from "../api.services"
import FormInputEditorCard from "../molecules/FormInputEditorCard"
import ShowInfoAdditionalCard from "../molecules/ShowInfoAdditionalCard"
import { useAdditionalAndExtraInfoProjectBuild } from "../project-build.hook"
import { ProjectBuildAdditionalDetail } from "../project-build.type"
interface Props {
  additionals: ProjectBuildAdditionalDetail[]
  idProjectBuild: string
  isDeleteComment: boolean
  isEditorInput: boolean
  isLabel: boolean
  updateWhenCreateAdditional: () => void
  updateWhenDeleteAdditional: (idAdditional: string) => void
  isDeleted?: boolean
  conversationId?: string
}
const AdditionalInfoCard = (props: Props) => {
  const {
    additionals,
    idProjectBuild,
    isDeleteComment,
    isEditorInput,
    isLabel,
    updateWhenCreateAdditional,
    updateWhenDeleteAdditional,
    isDeleted,
    conversationId,
  } = props
  const {
    dataEditor,
    isLoading,
    isScroll,
    keyInputFile,
    ref,
    listFileAdd,
    setDataEditor,
    fileSelectedHandler,
    handleChangeEditor,
    handleDeleteFileNoDownload,
    setListFileAdd,
    handleChangeScroll,
    clearStorageWhenSubmit,
  } = useAdditionalAndExtraInfoProjectBuild("additional_info", idProjectBuild)

  const { isMobile } = useWindowSize()

  useEffect(() => {
    handleChangeScroll()
  }, [JSON.stringify(additionals)])

  const onSubmitButton = (dataEditor: string) => {
    if (!idProjectBuild) {
      return
    }
    isLoading.setValue(true)
    // const htmlPuri = parseDraftToHtml(dataEditor)
    let newDataEditor = dataEditor
    listFileAdd
      .filter((item) => item.type === "inline")
      .forEach((item) => {
        newDataEditor = newDataEditor.replace(item.url || "", item.id)
      })
    postProjectBuildCreateAdditionalInfoMiddleware(
      idProjectBuild,
      {
        description: newDataEditor,
        files: listFileAdd.map((file) => {
          return {
            file: file.file,
            file_name: file.name,
            key: file.id,
            type: file.type,
            file_type: file.file_type,
          }
        }),
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateWhenCreateAdditional()
          setListFileAdd([])
          setDataEditor("")
          isScroll.setValue(true)
          clearStorageWhenSubmit()
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }
  const onDeleteComment = (oldItem: ProjectBuildAdditionalDetail) => {
    if (!oldItem.id) {
      return
    }
    isLoading.setValue(true)
    deleteProjectBuildCreateAdditionalInfoMiddleware(
      oldItem.id,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateWhenDeleteAdditional(oldItem.id)
        }
      }
    )
  }
  const onDisableButtonSubmit = () => {
    if (dataEditor !== dataEditorDefault || listFileAdd.length) {
      return false
    }
    return true
  }
  return (
    <div className="build-info-form flex flex-col justify-between h-full overflow-auto">
      {isLabel ? (
        <FormLabelWarning
          className="mb-3 mr-6 "
          label="This information would be shared with invitees."
          minHeight={38}
        />
      ) : null}
      <div className="flex flex-1 flex-col overflow-auto" ref={ref}>
        {additionals.length
          ? additionals.map((additional, index) => (
              <div className="pr-0 md:pr-6 mb-3 last:mb-0" key={index}>
                <ShowInfoAdditionalCard
                  isView={isDeleteComment}
                  item={additional}
                  onDeleteComment={onDeleteComment}
                  conversationId={conversationId}
                  // styleRootCard={{
                  //   marginBottom: index === additionals.length - 1 ? 0 : 12,
                  // }}
                  isDeleted={isDeleted}
                />
              </div>
            ))
          : null}
        {!additionals.length && isEditorInput ? (
          <div className="flex items-center w-full h-full justify-center">
            <p className="font-semibold text-14 leading-24 color-gray-7a">
              There is no additional information
            </p>
          </div>
        ) : null}
      </div>

      {!isDeleted && !isEditorInput && (
        <FormInputEditorCard
          handleChangeEditor={handleChangeEditor}
          dataEditor={dataEditor}
          fileSelectedHandler={fileSelectedHandler}
          listFileAdd={listFileAdd}
          keyInputFile={keyInputFile.value}
          handleDeleteFileNoDownload={handleDeleteFileNoDownload}
          onSubmitButton={onSubmitButton}
          onDisableButtonSubmit={onDisableButtonSubmit()}
        />
      )}
    </div>
  )
}
export default AdditionalInfoCard

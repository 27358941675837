import { ReactComponent as IconFavorite } from "assets/images/icons/icon-favorite-project.svg"
import { ReactComponent as IconFavoriteActive } from "assets/images/icons/icon-favorite.svg"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { STATUS_BUILD } from "components/Status/types"
import RoleUsersCard from "pages/projects/molecules/RoleUsersCard"
import { BuildConversationDetail } from "../../conversations/conversations.type"
import { formatDateForConversationMessage } from "helpers/utils"
import { postConversationLikeBuildMiddleware } from "../../conversations/conversations.api"
import { STATUS_RESPONSE } from "types"
import { IUseDefaultValueProps, useBoolean, useWindowSize } from "helpers/hooks"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import DotCard from "../../conversations/atoms/DotCard"
import { TippyCustomzie } from "components/TippyCustomzie"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import ActionItem from "pages/projects/molecules/ActionItem"
import {
  archiveBuildMiddleware,
  deleteBuildAttachment,
  duplicateBuilInBuildOverviewdMiddleware,
} from "pages/project-build/api.services"
import { STATUS_PROJECT_ROLE } from "pages/projects/types"

interface Props {
  item: BuildConversationDetail
  isViewBuild?: boolean
  styleRoot?: React.CSSProperties
  activeIdBuild: string
  updateDataWhenChangeFavorite: (dataRes: BuildConversationDetail) => void
  updateDataWhenArchive: (dataRes: BuildConversationDetail) => void
  updateDataWhenDeleteBuild: (dataRes: BuildConversationDetail) => void
  updateDataWhenDuplicateBuild: (dataRes: BuildConversationDetail) => void
  onClickBuildComponent: (dataRes: BuildConversationDetail) => () => void
  isAction?: boolean
  isDeleted?: boolean
}
const BuildConversationCard = (props: Props) => {
  const {
    item,
    isViewBuild = true,
    styleRoot,
    activeIdBuild,
    updateDataWhenChangeFavorite,
    updateDataWhenArchive,
    onClickBuildComponent,
    updateDataWhenDuplicateBuild,
    updateDataWhenDeleteBuild,
    isDeleted,
    isAction = true,
  } = props

  const isLoading = useBoolean(false)
  const showTippy = useBoolean()

  const { isMobile } = useWindowSize()

  const archive =
    Boolean(item.is_archived) ||
    // item.status === STATUS_BUILD.CANCELLED ||
    isDeleted

  const onChangeFavorite = (event) => {
    if (!isAction) return
    event.preventDefault()
    event.stopPropagation()
    if (!item.id) {
      return
    }
    isLoading.setValue(true)
    postConversationLikeBuildMiddleware(
      item.id,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateDataWhenChangeFavorite({
            ...item,
            is_favorite: !item.is_favorite,
          })
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }
  const onArchive = (event) => {
    event.preventDefault()
    event.stopPropagation()
    isLoading.setValue(true)
    console.log(item)
    archiveBuildMiddleware(
      item.id,
      item.is_archived === 0 ? false : true,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateDataWhenArchive({
            ...item,
            is_archived: item.is_archived ? 0 : 1,
          })
        }
      }
    )
    showTippy.setValue(!showTippy.value)
    isLoading.setValue(false)
  }

  const renderUserStart = () => {
    if (!isViewBuild || !item.text) {
      return null
    }
    return (
      <p
        className="text-12 leading-18 font-medium mt-[5px]"
        style={{
          color: archive ? " #C7C7C7" : "#7a7a7a",
        }}
        dangerouslySetInnerHTML={{ __html: item.text }}
      ></p>
    )
  }
  const isArchiveByOwner =
    Boolean(item.is_archived) ||
    item.status === STATUS_BUILD.CANCELLED ||
    isDeleted

  const renderUpdateAt = () => {
    const newUpdate = item?.updated_at
    return newUpdate
      ? `Updated ${formatDateForConversationMessage(newUpdate)},`
      : "No update"
  }
  const handleClickTippy = (event) => {
    event.stopPropagation()
    showTippy.setValue(!showTippy.value)
  }
  const onDeleteBuild = (event) => {
    event.preventDefault()
    event.stopPropagation()
    deleteBuildAttachment(item.id)
      .then(() => {
        updateDataWhenDeleteBuild(item)
        toast(
          <LabelNotificationPage
            messenger={"Delete build successfully!"}
            type="success"
          />
        )
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message || "Delete build failed!"}
            type="error"
          />
        )
      })
    showTippy.setValue(false)
  }
  const duplicateBuild = (event) => {
    event.preventDefault()
    event.stopPropagation()
    duplicateBuilInBuildOverviewdMiddleware(item.id)
      .then((res) => {
        updateDataWhenDuplicateBuild(res)
        toast(
          <LabelNotificationPage
            messenger={"Duplicate build successfully!"}
            type="success"
          />
        )
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
    showTippy.setValue(false)
  }

  return (
    <div
      onClick={onClickBuildComponent(item)}
      className={`cursor-pointer flex flex-col p-3 mb-3 ${
        activeIdBuild === item.id
          ? "active-project-build"
          : "hover-project-card"
      } ${isDeleted ? "bg-delete-element" : ""} ${
        archive ? "bg-neutral3" : "bg-white"
      }`}
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 6,
        ...styleRoot,
      }}
    >
      <div
        className="flex items-center justify-between mb-1"
        style={{
          height: 24,
        }}
      >
        <div className="flex items-center overflow-auto">
          {item.is_favorite ? (
            <IconFavoriteActive onClick={onChangeFavorite} />
          ) : (
            <IconFavorite onClick={onChangeFavorite} />
          )}
          <div className="flex items-center">
            <p
              className="text-14 leading-24 color-black-111111 font-semibold ml-2"
              style={{
                whiteSpace: "nowrap",
                color: archive ? " #C7C7C7" : "#111111",
              }}
            >
              {item.code}
            </p>
            <DotCard
              isDisabled={archive}
              styleRoot={{ marginLeft: 4, marginRight: 4 }}
            />
            <LabelStatusBuild
              status={item.status ? item.status : STATUS_BUILD.DRAFT}
              rootStyle={{
                fontSize: 12,
                lineHeight: "18px",
              }}
              isArchive={archive}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          {item.is_read === false ? (
            <DotCard
              styleRoot={{
                height: 6,
                width: 6,
                backgroundColor: "#EA4545",
              }}
            />
          ) : null}
          {item.project_role !== STATUS_PROJECT_ROLE.VIEWER &&
            isAction &&
            !isDeleted && (
              <TippyCustomzie
                containerClass="component-card-menu reset-tippy"
                placement="bottom-end"
                interactive
                arrow={false}
                animation="scale"
                visible={showTippy.value}
                onClickOutside={() => showTippy.setValue(false)}
                content={
                  <div
                    style={{
                      background: "#222222",
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      width: 165,
                    }}
                  >
                    <ActionItem
                      title={!archive ? "Archive" : "Unarchive"}
                      onClick={onArchive}
                    />
                    {!archive && (
                      <ActionItem
                        title={"Duplicate"}
                        onClick={duplicateBuild}
                      />
                    )}

                    {item.project_role === STATUS_PROJECT_ROLE.OWNER && (
                      <ActionItem title={"Delete"} onClick={onDeleteBuild} />
                    )}
                  </div>
                }
                allowHTML
              >
                <div className="flex items-center" onClick={handleClickTippy}>
                  <ActionIconHistory
                    icon="three-dots"
                    tooltip="Action"
                    styleIcon={{
                      marginRight: 0,
                    }}
                  />
                </div>
              </TippyCustomzie>
            )}
        </div>
      </div>
      <div className="flex items-center">
        <p
          className="text-12 leading-18 color-gray-7a font-medium"
          style={{
            color: archive ? " #C7C7C7" : "#7a7a7a",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item.project_code}:{` ${item.project_name}`}
        </p>
      </div>
      {renderUserStart()}

      <div className="flex items-center flex-wrap mt-4 mb-5">
        {item.components.length
          ? item.components.map((component, index) => (
              <div
                key={index}
                className="px-1 flex items-center mr-1 mb-1"
                style={{
                  background: archive ? "#F1F1F1" : "#F7F7F7",
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                  height: 20,
                }}
              >
                <p
                  className="font-semibold"
                  style={{
                    fontSize: 10,
                    lineHeight: "14px",
                    color: archive ? " #C7C7C7" : "#7a7a7a",
                  }}
                >
                  {component}
                </p>
              </div>
            ))
          : null}
      </div>

      <div
        className="flex items-center justify-between mb-3"
        style={{
          height: 24,
          minHeight: 24,
        }}
      >
        <p
          className="text-12 leading-18 font-medium"
          style={{
            color: archive ? " #C7C7C7" : "#7a7a7a",
          }}
        >
          <p>{renderUpdateAt()}</p>
          <p dangerouslySetInnerHTML={{ __html: item.updated_by }}></p>
        </p>
        <RoleUsersCard data={item.users} max={3} />
      </div>
    </div>
  )
}
export default BuildConversationCard

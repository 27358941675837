import { encryptionController } from "controllers/EncryptionController"
import { ComponentType } from "pages/project-component-detail/types"

export enum ActivityLogType {
  CreateProject,
  ChangeProjectStatus,
  UpdateProjectName,
  UpdateProjectDescription,
  UpdateProjectCategory,
  CreateFolder,
  UpdateFolder,
  CreateComponentInFolder,
  ArchivedProject,
  ArchivedProjectComponent,
  ArchivedProjectBuild,
  AddBuildComponent,
  DeleteBuildComponent,
  MoveComponentInFolder,
  CreateBuild,
  DeleteFolder,
  DeleteComponent,
  DeleteBuild,
  UpdateBuildName,
  DuplicateComponentVersion,
  DuplicateComponent,
  Transcend,
  DuplicateBuild,
  CreateComponent,
  UpdateComponentName,
  CreateNewVersion,
  DeleteDraftVersionInComponent,
  DeleteDraftVersionOutComponent,
  UploadAttachmentFiles,
  DeleteAttachmentFiles,
  ActivateInvitee,
  AcceptedInvitee,
  ChangeBuildStatus,
  ChangeBuildStatusToProductionOrInProgress,
  AddInvitee,
  SendInvitation,
  DeleteInviteeComponent,
  AllowInviteeEditComponent,
  DisallowInviteeEditComponent,
  UpdateAdditionalOrExtraInfo,
  UpdateInvitee,
  AssignComponent,
  SyncFromMain,
  SaveVersion,
  ChangeVersionToDraft,
  UploadMech3DFile,
  DeleteMech3DFile,
  UploadBom,
  UploadSubBom,
  DeletePcbBom,
  DeleteMechBom,
  AddBomColumn,
  AddSupplier,
  DeleteSupplier,
  ApproveVersion,
  UnapproveVersion,
  RemoveSupplierColumn,
  RemoveColumn,
  AddColumn,
  Upload3dFile,
  UploadPcb,
  CommentToInProduction,
  CommentDeleteInviteeBuildComponent,
  CommentAddInviteeBuildComponent,
  CommentSyncDataFromMain,
  CommentDeleteProjectComponent,
  CommentApproveInviteeVersion,
  CommentUnapproveInviteeVersion,
}

class ActivityLogHelper {
  constructor() {}
  public getLogTemplate = (type: ActivityLogType) => {
    switch (type) {
      case ActivityLogType.CreateProject:
        return "<b>{user}</b> has started the project <b>{projectName}</b>"
      case ActivityLogType.ChangeProjectStatus:
        return "<b>{user}</b> has changed project status to <b>{projectStatus}</b>"
      case ActivityLogType.UpdateProjectName:
        return "<b>{user}</b> has changed project name to <b>{projectName}</b>"
      case ActivityLogType.CreateFolder:
        return "<b>{user}</b> has created a new folder <b>{folderName}</b>"
      case ActivityLogType.UpdateFolder:
        return "<b>{user}</b> has updated folder <b>{oldName}</b> to <b>{newName}</b>"
      case ActivityLogType.CreateComponent:
        return "<b>{user}</b> has created a new component <b>{componentCode}</b>"
      case ActivityLogType.CreateComponentInFolder:
        return "<b>{user}</b> has created a new component <b>{componentCode}</b> in <b>{folderName}</b>"
      case ActivityLogType.ArchivedProject:
        return "<b>{user}</b> has {archive} the project <b>{projectName}</b>"
      case ActivityLogType.ArchivedProjectComponent:
        return "<b>{user}</b> has {archive} the project component <b>{componentCode}</b>"
      //build
      case ActivityLogType.ArchivedProjectBuild:
        return "<b>{user}</b> has {archive} the project build <b>{buildCode}</b>"
      case ActivityLogType.AddBuildComponent:
        return "<b>{user}</b> has added component <b>{component}</b>"
      case ActivityLogType.DeleteBuildComponent:
        return "<b>{user}</b> has deleted component <b>{component}</b>"
      case ActivityLogType.MoveComponentInFolder:
        return "<b>{user}</b> has moved <b>{componentCode}</b> {direction} folder <b>{folderName}</b>"
      case ActivityLogType.CreateBuild:
        return "<b>{user}</b> has started a new build <b>{buildCode}</b>"
      case ActivityLogType.UpdateBuildName:
        return "<b>{user}</b> has updated build name to <b>{name}</b>"
      case ActivityLogType.ChangeBuildStatus:
        return "<b>{user}</b> has changed status <b>{previousStatus}</b> to <b>{currentStatus}</b>"
      case ActivityLogType.ChangeBuildStatusToProductionOrInProgress:
        return "<b>{user}</b> has changed build status of <b>{buildCode}</b> to <b>{status}</b>"
      case ActivityLogType.AddInvitee:
        return "<b>{user}</b> has added invitee <b>{invitee}</b>"
      case ActivityLogType.SendInvitation:
        return "<b>{user}</b> has sent invitation to <b>{invitee}</b>"
      case ActivityLogType.AllowInviteeEditComponent:
        return "<b>{user}</b> has allowed <b>{invitee}</b> to modify components"
      case ActivityLogType.DisallowInviteeEditComponent:
        return "<b>{user}</b> has revoked the permission for <b>{invitee}</b> to modify components"
      case ActivityLogType.UpdateAdditionalOrExtraInfo:
        return "<b>{user}</b> has made new entry to {model} information"
      case ActivityLogType.UpdateInvitee:
        return "<b>{user}</b> has updated <b>{invitee}</b> information"
      //
      case ActivityLogType.DeleteFolder:
        return "<b>{user}</b> has deleted folder <b>{folderName}</b>"
      case ActivityLogType.DeleteComponent:
        return "<b>{user}</b> has deleted component <b>{component}</b>"
      case ActivityLogType.DeleteBuild:
        return "<b>{user}</b> has deleted build <b>{buildCode}</b>"
      case ActivityLogType.DuplicateComponent:
        return "<b>{user}</b> has duplicated component <b>{componentCode}</b> to <b>{newComponentCode}</b>"
      case ActivityLogType.DuplicateBuild:
        return "<b>{user}</b> has duplicated build <b>{buildCode}</b> to <b>{newBuildCode}</b>"
      case ActivityLogType.Transcend:
        return "<b>{user}</b> has duplicated version <b>{versionCode}</b> to <b>{newVersionCode}</b>"
      case ActivityLogType.UpdateComponentName:
        return "<b>{user}</b> has updated component name of <b>{componentCode}</b>"
      case ActivityLogType.CreateNewVersion:
        return "<b>{user}</b> has created a new version <b>{versionCode}</b>"
      case ActivityLogType.DeleteDraftVersionInComponent:
        return "<b>{user}</b> has deleted a draft version <b>{versionCode}</b>"
      case ActivityLogType.DeleteDraftVersionOutComponent:
        return "<b>{user}</b> has deleted a draft version <b>{versionCode}</b> from <b>{componentCode}</b>"
      case ActivityLogType.UploadAttachmentFiles:
        return "<b>{user}</b> has uploaded files <b>{file}</b>"
      case ActivityLogType.DeleteAttachmentFiles:
        return "<b>{user}</b> has deleted file <b>{file}</b>"
      case ActivityLogType.ActivateInvitee:
        return "<b>{user}</b> has {action} <b>{invitee}</b>"
      case ActivityLogType.AcceptedInvitee:
        return "<b>{user}</b> has {action} <b>{invitee}</b> in <b>{buildCode}</b>"

      case ActivityLogType.DeleteInviteeComponent:
        return "<b>{user}</b> has removed <b>{versionCode}</b> away from <b>{invitee}</b>"
      case ActivityLogType.AssignComponent:
        return "<b>{user}</b> has assigned <b>{versionCode}</b> to <b>{invitee}</b>"
      case ActivityLogType.SyncFromMain:
        return "<b>{user}</b> has synced new data of <b>{componentCode}</b> from main branch <b>{versionCode}</b>"
      case ActivityLogType.SaveVersion:
        return "<b>{user}</b> has saved the version <b>{versionCode}</b>"
      case ActivityLogType.ChangeVersionToDraft:
        return "<b>{user}</b> has changed the version <b>{version}</b> to <b>Draft</b>"
      case ActivityLogType.UploadMech3DFile:
        return "<b>{user}</b> has uploaded 3d file <b>{fileName}</b>"
      case ActivityLogType.DeleteMech3DFile:
        return "<b>{user}</b> has deleted file <b>{fileName}</b>"
      case ActivityLogType.UploadBom:
        return "<b>{user}</b> has uploaded <b>{fileName}</b>"
      case ActivityLogType.UploadSubBom:
        return "<b>{user}</b> has uploaded {type} bom <b>{fileName}</b>"
      case ActivityLogType.DeletePcbBom:
        return "<b>{user}</b> has deleted pcb bom <b>{fileName}</b>"

      case ActivityLogType.DeleteMechBom:
        return "<b>{user}</b> has deleted mechnical bom <b>{fileName}</b>"
      case ActivityLogType.AddBomColumn:
        return "<b>{user}</b> has {content}"
      case ActivityLogType.DeleteSupplier:
        return "<b>{user}</b> has deleted <b>supplier</b> column"
      case ActivityLogType.ApproveVersion:
        return "<b>{user}</b> has approved the version <b>{versionCode}</b>"
      case ActivityLogType.UnapproveVersion:
        return "<b>{user}</b> has unapproved the version <b>{versionCode}</b>"
      case ActivityLogType.AddSupplier:
        return "<b>{user}</b> has added <b>supplier</b> column {in}"
      case ActivityLogType.RemoveSupplierColumn:
        return "<b>{user}</b> has deleted <b>supplier</b> column"
      case ActivityLogType.RemoveColumn:
        return "<b>{user}</b> has deleted column <b>{column}</b>"
      case ActivityLogType.AddColumn:
        return "<b>{user}</b> {content}"
      case ActivityLogType.Upload3dFile:
        return "<b>{user}</b> has uploaded 3d file <b>{fileName}</b>"
      case ActivityLogType.UploadPcb:
        return "<b>{user}</b> has {action} pcb {type} - <b>{fileName}</b>"

      // For the comment model
      case ActivityLogType.CommentToInProduction:
        return "{componentCode} has been removed by {user} when the build goes to production"
      case ActivityLogType.CommentDeleteInviteeBuildComponent:
        return "{componentCode} has been removed by {user}"
      case ActivityLogType.CommentAddInviteeBuildComponent:
        return "{componentCode} has been added by {user}"
      case ActivityLogType.CommentSyncDataFromMain:
        return "{user} has synced new data of {componentCode} from main branch {versionCode}"
      case ActivityLogType.CommentDeleteProjectComponent:
        return "{componentCode}/{versionCode} has been deleted by {user}"
      case ActivityLogType.CommentApproveInviteeVersion:
        return "{user} has approved the version {componentCode}/{versionCode}"
      case ActivityLogType.CommentApproveInviteeVersion:
        return "{user} has unapproved the version {componentCode}/{versionCode}"

      default:
        return ""
    }
  }
  public createStringFromTemplate(template, variables) {
    return template.replace(
      new RegExp("{([^{]+)}", "g"),
      function (_unused, varName) {
        return variables[varName] || `{${varName}}`
      }
    )
  }
  public toLogMessage = (type: ActivityLogType, params: any) => {
    let paramToMap = params
    if (!paramToMap.user) {
      paramToMap = {
        ...params,
        user: localStorage.getItem("userEmail"),
      }
    }
    return this.createStringFromTemplate(this.getLogTemplate(type), paramToMap)
  }
  public toEncryptedMessage = (
    type: ActivityLogType,
    params: any,
    encryptionKey?: string
  ) => {
    return encryptionController().encryptLog(
      this.toLogMessage(type, params),
      encryptionKey
    )
  }
  public toDiffColumn = (
    data1: { idColumn?: string; key: string; values: string[] }[],
    data2: { idColumn?: string; key: string; values: string[] }[]
  ) => {
    const created: string[] = []
    const updated: string[] = []
    const deleted: string[] = []
    data2.forEach((item2) => {
      if (!data1.find((item) => item.idColumn === item2.idColumn)) {
        created.push(item2.key)
      } else {
        const foundData1WithIdColumn = data1.find(
          (item) => item.idColumn === item2.idColumn
        )

        if (
          foundData1WithIdColumn &&
          (foundData1WithIdColumn.key !== item2.key ||
            item2.values.toString() !==
              foundData1WithIdColumn?.values.toString())
        ) {
          updated.push(item2.key.slice(0, -9))
        }
      }
    })
    data1.forEach((item1) => {
      if (data2.every((item) => item.idColumn !== item1.idColumn)) {
        const foundIdColumn = data2.find(
          (item) => item.idColumn === item1.idColumn
        )
        if (!foundIdColumn) deleted.push(item1.key.slice(0, -9))
      }
    })
    return {
      created,
      updated,
      deleted,
    }
  }
  public toDiffMessage = (data) => {
    const messages: string[] = []
    if (data.created.length > 0) {
      messages.push(
        `added new column${
          data.created.length > 1 ? "s" : ""
        } <b>${data.created.join(", ")}</b>`
      )
    }
    if (data.updated.length > 0) {
      messages.push(
        `updated column${
          data.updated.length > 1 ? "s" : ""
        } <b>${data.updated.join(", ")}</b>`
      )
    }
    if (data.deleted.length > 0) {
      messages.push(
        `deleted column${
          data.deleted.length > 1 ? "s" : ""
        } <b>${data.deleted.join(", ")}</b>`
      )
    }
    return messages.join(", ")
  }
  public createAddColumnLog = (bomType: ComponentType, diffColumn: any) => {
    const diff = this.toDiffMessage(diffColumn)
    let content = `has ${diff}`
    if (bomType === ComponentType.PCB) {
      content = `has ${diff} in pcb bom`
    }
    if (bomType === ComponentType.Mechanical) {
      content = `has ${diff} in mechanical bom`
    }
    return content
  }
}

export const activityLogHelper = new ActivityLogHelper()

import Axios, { AxiosResponse } from "axios"
import {
  CategoryDetail,
  CategoryRequest,
  ParamsProjectsProps,
  ProjectCount,
  ProjectDetail,
  ProjectStatusKey,
} from "../types"
import { EncryptionKeyInput, STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ProjectComponentDetail } from "pages/project-component/types"
import {
  BuildConversationDetail,
  ConversationDetail,
} from "pages/conversations/conversations.type"
import {
  encryptionController,
  EncryptionKeys,
  ProjectSensitiveData,
  UpdateProjectSensitiveDataRequest,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { isEmpty, isEqual } from "lodash"

export const getCategoriesMiddleware = async (
  url: string,
  params?: CategoryRequest
) => {
  const response: AxiosResponse<{
    data: CategoryDetail[]
  }> = await Axios.get(url, {
    params,
  })
  return response.data.data
}
export const getProjectCountMiddleware = async () => {
  const response: AxiosResponse<{
    data: ProjectCount
  }> = await Axios.get(`/api/project/get-project-count`)
  return response.data.data
}

export const postCategoryMiddleware = (
  request: {
    name: string
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: CategoryDetail
  ) => void
) => {
  Axios.post(`/api/category/create`, request)
    .then(
      (
        response: AxiosResponse<{
          data: CategoryDetail
        }>
      ) => {
        callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_CATEGORY_ERROR
      )
    })
}

export const getProjectsMiddleware = async (
  params: ParamsProjectsProps,
  url: string
) => {
  const response: AxiosResponse<{
    data: ProjectDetail[]
    encryptedKeys?: any
  }> = await Axios.get(url, {
    params,
  })
  const projectEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
  )
  if (response.data.encryptedKeys) {
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string
    const decryptedKeys = response.data.encryptedKeys.reduce((pre, cur) => {
      return {
        ...pre,
        [cur.id]: encryptionHelper.decrypt(userVaultKey, cur.encryptedKey),
      }
    }, {})
    localStorage.setItem(
      EncryptionKeys.projectEncryptionKeys,
      JSON.stringify({
        ...projectEncryptionKeys,
        ...decryptedKeys,
      })
    )
  }
  return response.data.data
}
export const getUnEncryptedProjectsMiddleware = async () => {
  const response: AxiosResponse<{
    data: ProjectDetail[]
    total: number
    done: number
  }> = await Axios.get("/api/project/get-list-un-encrypted")

  return {
    data: response.data.data,
    total: response.data.total,
    done: response.data.done,
  }
}
export const updateFailedFileMiddleware = async (fileKey: string) => {
  await Axios.put("/api/project/update-failed-files?loading=0", { fileKey })
  return true
}
export const postFileHashKeyMiddleware = async (
  fileKey: string,
  hashKey: string
) => {
  await Axios.post("/api/file/hash?loading=0", {
    file_key: fileKey,
    hash_key: hashKey,
  })
  return true
}
export const getFileHashKeyMiddleware = async (fileKey: string) => {
  const response = await Axios.get(`/api/file/hash?file_key=${encodeURIComponent(fileKey)}`)
  return response.data.hash_key
}

export const getProjectDetailMiddleware = async (
  idProject: string,
  idConversation?: string
) => {
  let url = `/api/project/${idProject}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: ProjectDetail
  }> = await Axios.get(url)
  if (response.data.data.is_encrypted) {
    const projectEncryptionKey = encryptionController().decryptProjectKey(
      response.data.data.encryptedKey
    )
    localStorage.setItem(
      EncryptionKeys.currentProjectEncryptionKey,
      isEmpty(projectEncryptionKey) ? "" : (projectEncryptionKey as string)
    )
  } else {
    localStorage.setItem(EncryptionKeys.currentProjectEncryptionKey, "")
  }
  return response.data.data
}
export const getProjectSensitiveDataMiddleware = async (idProject: string) => {
  let url = `/api/project/${idProject}/sensitive?loading=0`
  const response: AxiosResponse<{
    data: ProjectSensitiveData
  }> = await Axios.get(url)

  return response.data.data
}
export const getProjectFilesMiddleware = async (idProject: string) => {
  let url = `/api/project/${idProject}/files?loading=0`
  const response: AxiosResponse<{
    data: any
  }> = await Axios.get(url)

  return response.data.data
}
export const postSyncProjectBinaryFileMiddleware = async (
  idProject: string,
  payload: any
) => {
  let url = `/api/project/${idProject}/sync-binary-file?loading=0`
  return Axios.post(url, payload)
}

export const getProjectKeyMiddleware = async (idProject: string) => {
  let url = `/api/encryption/project/${idProject}/project-key`
  const response: AxiosResponse<{
    data: any
  }> = await Axios.get(url)

  return response.data.data
}
export const putProjectSyncContextDataMiddleware = async (
  idProject: string,
  data: UpdateProjectSensitiveDataRequest
) => {
  let url = `/api/project/${idProject}/update-sensitive?loading=0`
  return Axios.put(url, data).catch((error) => {
    console.log(error)
  })
}
export const createEncryptionKeyMiddleware = async (
  data: EncryptionKeyInput
) => {
  try {
    let url = `/api/auth/key-encryption`
    await Axios.post(url, data)
  } catch (error) {}
}
export const createEncryptionKeysMiddleware = async (
  data: EncryptionKeyInput[]
) => {
  try {
    let url = `/api/auth/key-encryptions`
    await Axios.post(url, { data })
  } catch (error) {}
}
export const validateAccessTokenMiddleware = async (token: string) => {
  try {
    let url = `/api/auth/verify-token?token=${token}`
    const response = await Axios.get(url)
    return response.data.isValid
  } catch (error) {}
}
export const acceptedSharingProjectMiddleware = async (projectId: string) => {
  try {
    let url = `/api/project/${projectId}/accepted`
    await Axios.post(url)
  } catch (error) {}
}
export const acceptedSharingBuildMiddleware = async (
  conversationId: string
) => {
  try {
    let url = `/api/project-build/accept-conversation/${conversationId}`
    await Axios.post(url)
  } catch (error) {}
}

export const postProjectMiddleware = async (
  request: {
    name: string
    description: string
    category_ids: string[]
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const projectEncryptionKey = encryptionHelper.createRandomKey()

  const userVaultKey = localStorage.getItem(
    EncryptionKeys.userVaultKey
  ) as string

  let dataToPost: any = request
  const enableEncrypted = localStorage.getItem("enabled_encrypted")
  if (enableEncrypted === "1") {
    const encryptedEncryptionKey = encryptionHelper.encrypt(
      userVaultKey,
      projectEncryptionKey
    ) as string
    dataToPost = {
      ...request,
      encryptedKey: encryptedEncryptionKey,
      log: await encryptionController().encrypt(
        activityLogHelper.toLogMessage(ActivityLogType.CreateProject, {
          projectName: request.name,
        }),
        {
          dataType: "string",
          type: "project",
          encryptionKey: projectEncryptionKey,
        }
      ),
    }
  }
  Axios.post(`/api/project/create`, dataToPost)
    .then((response: AxiosResponse) => {
      if (enableEncrypted === "1") {
        const projectEncryptionKeys = JSON.parse(
          localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
        )
        localStorage.setItem(
          EncryptionKeys.projectEncryptionKeys,
          JSON.stringify({
            ...projectEncryptionKeys,
            [response.data.data.id]: projectEncryptionKey,
          })
        )
      }
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_PROJECT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_PROJECT_ERROR
      )
    })
}
const toUpdateProjectLogMessage = async (project: any, payload: any) => {
  const messages: string[] = []
  if (payload.name.toLowerCase() !== project.name.toLowerCase()) {
    messages.push(`project name to <b>${payload.name}</b>`)
  }
  if (payload.description.toLowerCase() !== project.description.toLowerCase()) {
    messages.push(`project description to <b>${payload.description}</b>`)
  }
  const projectTags = project.selectCategories.map((item) => item.id)
  if (!isEqual(payload.category_ids, projectTags)) {
    const categories = await Axios.get(
      `/api/category/get-many?ids=${payload.category_ids.join(",")}`
    )
    const tags = categories.data.data.map((item) => item.name).join(", ")
    messages.push(`project tags to <b>${tags}</b>`)
  }
  return messages.join(", ")
}
export const putProjectMiddleware = async (
  idProject: string,
  request: {
    name: string
    description: string
    category_ids: string[]
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void,
  projectData?: any
) => {
  const temp = await toUpdateProjectLogMessage(projectData, request)
  const encryptionKey = JSON.parse(
    localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
  )[idProject]
  const encrypted = await encryptionController().encrypt(
    `<b>${localStorage.getItem("userEmail")}</b> has changed ${temp}`,
    {
      dataType: "string",
      type: "project",
      encryptionKey: encryptionKey,
    }
  )
  Axios.put(`/api/project/${idProject}/update`, {
    ...request,
    log: encrypted,
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.PUT_PROJECT_ERROR
      )
    })
}

export const deleteProjectMiddleware = (
  idProject: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project/${idProject}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_PROJECT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_PROJECT_ERROR
      )
    })
}
export const archiveProjectMiddleware = (
  idProject: string,
  isArchived: number,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void,
  projectName: string
) => {
  Axios.post(`/api/project/${idProject}/archive`, {
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.ArchivedProject, {
      archive: isArchived ? "unarchived" : "archived",
      projectName,
    }),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          isArchived === 0
            ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_SUCCESS
            : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      const newMessageError =
        isArchived === 0
          ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_ERROR
          : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_ERROR
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : newMessageError
      )
    })
}
export const favoriteProjectMiddleware = (
  idProject: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.post(`/api/project/${idProject}/like-or-unlike`)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.FAVORITE_PROJECT_ERROR
      )
    })
}

export const getProjectRoleMiddleware = async (projectId: string) => {
  const response: AxiosResponse<{
    data: { role: string }
  }> = await Axios.get(`/api/project/${projectId}/role`)
  return response.data.data
}

export const putUpdateStatusMiddleware = (
  idProject: string,
  request: {
    status: number
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.put(`/api/project/${idProject}/update-status`, {
    ...request,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.ChangeProjectStatus,
      {
        projectStatus: ProjectStatusKey[request.status],
      }
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_UPDATE_STATUS_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.PUT_UPDATE_STATUS_PROJECT_ERROR
      )
    })
}

export const getObjectSearchMiddleware = async (
  params: ParamsProjectsProps
) => {
  const response: AxiosResponse<{
    data: {
      projects: ProjectDetail[]
      components: ProjectComponentDetail[]
      builds: BuildConversationDetail[]
      conversations: ConversationDetail[]
    }
  }> = await Axios.get(`/api/project/search`, {
    params,
  })
  return response.data.data
}

export const deleteProjectAttachment = async (id: string) => {
  return Axios.delete(`/api/project/${id}/delete-attachment`).then(
    (res) => res.data
  )
}

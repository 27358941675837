import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import { REACT_APP_API_QUANTITY } from "config/environments"
import { ReactComponent as IconWarningStock } from "assets/images/icons/icon-warning-stock.svg"
import { ReactComponent as IconActionTable } from "assets/images/icons/icon-action-table-bom.svg"
import React from "react"
import { SelectCurrencyDetail } from "../types"
import SelectSupplier from "components/Select/SelectSupplier"
import { findQtyCol } from 'helpers/bom'

interface Props {
  originMouserData: string[]
  isAddColumn: boolean
  isDisable: boolean
  lengthAdditinal: number
  lengthInviteeBOM: number
  activeCurrency: SelectCurrencyDetail
  handleOpenModelDelete?: (type: string) => void
  bomJson: any;
}
const TableBOMSupplier = (props: Props) => {
  const {
    isAddColumn,
    isDisable,
    handleOpenModelDelete,
    originMouserData,
    lengthAdditinal,
    lengthInviteeBOM,
    activeCurrency,
    bomJson = []
  } = props
  const showDeleteSupplier = useBoolean(false)
  const renderTableHeaderSupplier = (header: string) => {
    switch (header) {
      case "unit_cost":
        return "Unit cost"
      case "stock":
        return "Stock"
      case "total":
        return "Sub total"
      default:
        return (
          <SelectSupplier
            isDisable={isDisable}
            title="Change all suppliers to"
            value="Supplier"
            options={[
              {
                label: "Mouser",
                value: "Mouser",
              },
            ]}
          />
        )
    }
  }
  const renderTableBodySupplier = (header: string, value: any, dataIndex = 0) => {
    switch (header) {
      case "supplier":
        return (
          <SelectSupplier
            isDisable={isDisable}
            title="Select supplier"
            value="Mouser"
            options={[
              {
                label: "Mouser",
                value: "Mouser",
              },
            ]}
          />
        )
      case "stock":
        return (
          <div className="flex items-center px-2">
            <p className="font-normal text-14 color-black-111111 leading-24">
              {value[header]}
            </p>
            {value[header] < REACT_APP_API_QUANTITY ? (
              <IconWarningStock className="ml-1" />
            ) : null}
          </div>
        )
      case "total":
        const newSplit1 = value['unit_cost'] ? value['unit_cost'].split("$") : [];
        const qtyValue = findQtyCol(bomJson[dataIndex]);
        let newValueUnitCost1 = 0
        if (newSplit1.length > 1) {
          newValueUnitCost1 = (
            Number(newSplit1[1]) * Number(activeCurrency.rate)
          );
        }
        const total = `$${(newValueUnitCost1 * qtyValue).toFixed(5)}`;
        //
        return (
          <div className="px-2">
            <p
              className="font-normal"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {`${total} ${activeCurrency.symbol}`}
            </p>
          </div>
        )
      case "unit_cost":
        const newSplit = value[header] ? value[header].split("$") : []
        let newValueUnitCost = "0"
        if (newSplit.length > 1) {
          newValueUnitCost = (
            Number(newSplit[1]) * Number(activeCurrency.rate)
          ).toFixed(5)
        }
        return (
          <div className="px-2">
            <p
              className="font-normal"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {`${newValueUnitCost} ${activeCurrency.symbol}`}
            </p>
          </div>
        )
    }
  }

  const renderBorderRadius = () => {
    if (showDeleteSupplier.value || lengthAdditinal || lengthInviteeBOM) {
      return 0
    }
    if (!isAddColumn) {
      return 6
    }
    return 0
  }
  const onClickDelete = () => {
    showDeleteSupplier.setValue(false)
    if (handleOpenModelDelete) {
      handleOpenModelDelete("supplier")
    }
  }
  const supplierHeaders = ["supplier", "stock", "unit_cost", "total"]
  return (
    <React.Fragment>
      <div
        className={`flex flex-col relative cursor-pointer ${
          !isDisable ? "hover-disabled-button-action-table" : ""
        }`}
        style={{
          borderRight: showDeleteSupplier.value
            ? "1px solid #FDBE44"
            : "1px solid #E4E4E4",
          borderTop: showDeleteSupplier.value
            ? "1px solid #FDBE44"
            : "1px solid #E4E4E4",
          borderBottom: showDeleteSupplier.value
            ? "1px solid #FDBE44"
            : "1px solid #E4E4E4",
          borderLeft: showDeleteSupplier.value
            ? "1px solid #FDBE44"
            : "1px solid transparent",
          marginTop: 10,
          backgroundColor: "#FFFFFF",
          borderTopRightRadius: renderBorderRadius(),
          borderBottomRightRadius: renderBorderRadius(),
        }}
      >
        <div className="flex items-end">
          {supplierHeaders.map((header: string, idx: number) => (
            <div
              className={`${
                header === "supplier" ? "px-1" : "px-3"
              } flex items-center font-semibold min-h-48-custom h-12`}
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                width: 170,
                minWidth: 170,
                textAlign: "start",
                borderBottom: "1px solid #E4E4E4",
                wordBreak: "break-all",
              }}
              key={idx}
            >
              {renderTableHeaderSupplier(header)}
            </div>
          ))}
        </div>
        {originMouserData.length
          ? originMouserData.map((el: any, index: number) => (
              <div
                className="flex items-center"
                key={index}
                style={{
                  backgroundColor: index % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
                  width: "min-content",
                }}
              >
                {supplierHeaders.map((header: string, idx: number) => (
                  <div
                    className={`px-1 flex items-center py-1`}
                    style={{
                      height: 48,
                      width: 170,
                    }}
                    key={idx}
                  >
                    {renderTableBodySupplier(header, el, index)}
                  </div>
                ))}
              </div>
            ))
          : null}
        <TippyCustomzie
          containerClass="component-card-menu absolute top-0 left-0 w-full h-full"
          placement="top"
          interactive
          arrow={false}
          animation="scale"
          visible={showDeleteSupplier.value}
          onClickOutside={() => showDeleteSupplier.setValue(false)}
          offset={[0, 10]}
          content={
            <div
              className="flex items-center"
              onClick={onClickDelete}
              style={{
                background: "#222222",
                borderRadius: 4,
                padding: "0px 12px",
                width: 140,
                height: 48,
              }}
            >
              <p
                className="font-normal"
                style={{
                  fontSize: 13,
                  lineHeight: "22px",
                  color: "#EA4545",
                }}
              >
                Delete
              </p>
            </div>
          }
          allowHTML
        >
          <div
            onClick={() => showDeleteSupplier.setValue(true)}
            className={`absolute flex items-center justify-center cursor-pointer ${
              showDeleteSupplier.value
                ? "active-button-action-table"
                : "disabled-button-action-table"
            }`}
            style={{
              top: -10,
              left: "calc(50% - 14px)",
              height: 20,
              width: 28,
              backgroundColor: "#FFFFFF",
              border: "1px solid #E4E4E4",
              borderRadius: 4,
            }}
          >
            <IconActionTable />
          </div>
        </TippyCustomzie>
      </div>
    </React.Fragment>
  )
}
export default TableBOMSupplier

import axios from "axios"
import { API_URL } from "config/environments"
import { history, useQuery } from "helpers/history"
import { PATH } from "constants/path"
import { configureStore } from "stores/configureStore"
import { startLoading, closeLoading } from "reducers/loading"
import { isEmpty } from "lodash"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"

export const setupInterceptors = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.baseURL = API_URL
      const query = useQuery()
      const token =
        localStorage.getItem("access_token") || query.get("access_token") || ""
      const tokenType = localStorage.getItem("token_type") || "Bearer"
      if (token) {
        config.headers = {
          Authorization: `${tokenType} ${token}`,
        }
      }
      if (
        // update specification api
        !config.url?.startsWith("/api/specification/history/") &&
        !config.url?.startsWith("/api/user/current-active") &&
        !config.url?.startsWith("/api/category/get-user-categories") &&
        !config.url?.endsWith("loading=0")
      ) {
        if (
          config.url?.startsWith("/api/miscellaneous/upload/") ||
          config.url?.startsWith("/api/firmware/upload/") ||
          config.url?.startsWith("/api/software/upload/") ||
          config.url?.startsWith("/api/mechanical/upload/") ||
          config.url?.startsWith("/api/invitee/miscellaneous/upload/") ||
          config.url?.startsWith("/api/invitee/firmware/upload/") ||
          config.url?.startsWith("/api/invitee/software/upload/") ||
          config.url?.startsWith("/api/invitee/mechanical/upload/")
        ) {
          if (isEmpty(config.data.files)) {
            return config
          }
          config = {
            ...config,
            data: {
              ...config.data,
              log: activityLogHelper.toEncryptedMessage(
                ActivityLogType.UploadAttachmentFiles,
                {}
              ),
            },
          }
        }
        if (
          config.url?.startsWith("/api/conversation/notes/") &&
          !config.url?.endsWith("upload-attachments")
        ) {
          return config
        }
        configureStore.dispatch(startLoading())
      }
      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (
        // update specification api
        !response.config.url?.startsWith("/api/specification/history/") &&
        !response.config.url?.startsWith("/api/user/current-active") &&
        !response.config.url?.startsWith("/api/category/get-user-categories") &&
        !response.config.url?.endsWith("loading=0")
      ) {
        if (
          response.config.url?.startsWith("/api/miscellaneous/upload/") ||
          response.config.url?.startsWith("/api/firmware/upload/") ||
          response.config.url?.startsWith("/api/software/upload/") ||
          response.config.url?.startsWith("/api/mechanical/upload/")
        ) {
          if (isEmpty(response.config.data.files)) {
            return response
          }
        }
        if (
          response.config.url?.startsWith("/api/conversation/notes/") &&
          !response.config.url?.endsWith("upload-attachments")
        ) {
          closeLoading()
          return response
        }
        closeLoading()
      }
      return response
    },
    (error) => {
      closeLoading()
      // "Unauthorized"
      if (error.response.status === 401) {
        localStorage.removeItem("access_token")
        return
      }
      // Forbidden denied redirect to 404 page
      if (error.response.status === 403) {
        setTimeout(() => {
          history.replace(PATH.notFound)
        }, 200)
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )
}

export const instanceExternalUrl = axios.create({
  baseURL: "",
})

import SimpleTable, { SimpleTableProps } from "components/Table/SimpleTable"
import { ReactComponent as IconCheck } from "assets/images/icons/icon-valid-input.svg"
import { ReactComponent as IconNotYet } from "assets/images/icons/icon-system-data.svg"
import { ReactComponent as IconFail } from "assets/images/icons/icon-error-notification.svg"

const columns: SimpleTableProps["columns"] = [
  {
    title: "Project code",
    dataIndex: "code",
  },
  {
    title: "Context",
    dataIndex: "is_synced_context",
    render: (value) => {
      if (value.is_synced_context === 1) return <IconCheck className="ml-1" />
      return <IconNotYet className="ml-1" />
    },
  },
  {
    title: "File",
    dataIndex: "is_synced_file",
    render: (value) => {
      if (value.is_synced_file === 1) return <IconCheck className="ml-1" />
      if (value.is_synced_file === -1) return <IconFail className="ml-1" />
      return <IconNotYet className="ml-1" />
    },
  },
]

interface ProjectSyncTableProps {
  data?: {
    id: string
    code: string
    is_synced_context: boolean
    is_synced_file: boolean
  }[]
}

const ProjectSyncTable = (props: ProjectSyncTableProps) => {
  return (
    <SimpleTable displayOD columns={columns} dataSource={props.data || []} />
  )
}
export default ProjectSyncTable

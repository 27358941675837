import axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  EncryptionKeys,
  encryptionController,
} from "controllers/EncryptionController"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { encryptionHelper } from "helpers/encryption"
import { validateEncryptedFile } from "helpers/utils"
import {
  BuildHistoryDetail,
  ComponentType,
  TableColumnBOMDetail,
} from "pages/project-component-detail/types"
import { STATUS_RESPONSE } from "types"

export const inviteeCommitMiddleware = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-component/history/${project_component_history_id}/commit`,
      {
        params: {
          conversation_id,
        },
      }
    )
  return response.data.data
}

export const inviteeCreateDraft = async (
  project_build_component_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-build-component/${project_build_component_id}/invitee/history/create-and-copy?conversation_id=${conversation_id}`,
      {
        log: {
          createNewDraftLog: activityLogHelper.toEncryptedMessage(
            ActivityLogType.CreateNewVersion,
            {}
          ),
          changeToDraftLog: activityLogHelper.toEncryptedMessage(
            ActivityLogType.ChangeVersionToDraft,
            {}
          ),
        },
      }
    )
  return response.data.data
}

export const approveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  componentCode: string,
  versionCode: string
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/approve`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.ApproveVersion,
        { versionCode }
      ),
      commentLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CommentApproveInviteeVersion,
        { componentCode, versionCode },
        conversationEncryptionKeys[conversation_id]
      ),
    }
  )
  return response.data.data
}
export const unapproveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  componentCode: string,
  versionCode: string
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/unapprove`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.UnapproveVersion,
        { versionCode }
      ),
      commentLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CommentUnapproveInviteeVersion,
        { componentCode, versionCode },
        conversationEncryptionKeys[conversation_id]
      ),
    }
  )
  return response.data.data
}
export const revertInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  data: {
    message: string
  }
) => {
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/revert`,
    data.message ? data : { message: "" }
  )
  return response.data.data
}

export const deleteInviteeDraft = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response = await axios.put(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/delete`,
    {
      log: {
        logInComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionInComponent,
          {}
        ),
        logOutComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionOutComponent,
          {}
        ),
      },
    }
  )
  return response.data.data
}

export const inviteeUploadFileBOMMiddleware = async (
  idHistory: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  const result = await encryptionController().encrypt(file, {
    dataType: "file",
    type: "project",
  })

  request.delete("file")
  const blob = new Blob([result], { type: file.type })
  request.append("file", blob, file.name)
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.Upload3dFile,
    {
      type: "bom",
      action: "uploaded",
      fileName: file.name,
    }
  )
  request.append("log", log)
  request.append("file_hash", rawHash)
  axios
    .post(`/api/invitee/bom/upload/${idHistory}/${conversationId}`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (response: AxiosResponse) => {
      await validateEncryptedFile(
        rawHash,
        { ...response.data.data.file, conversation_id: conversationId },
        response.data.data.project_id
      )
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}

export const inviteeDeleteFileBOMMiddleware = (
  idHistory: string,
  conversationId: string,
  fileName: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .put(`/api/invitee/bom/${idHistory}/${conversationId}/delete-file`, {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DeleteAttachmentFiles,
        { file: fileName }
      ),
    })
    .then((_responseDeleteFileBOM: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      )
    })
}
export const addInviteePcbFileMiddleware = (
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type: "gerber",
    action: "uploaded",
    fileName: (request.get("file") as any).name,
  })
  request.append("log", log)
  // axios
  //   .post(
  //     `/api/invitee/pcb/upload/${projectComponentHistoryId}/${conversationId}`,
  //     request
  //   )
  //   .then((_response: AxiosResponse) => {
  //     callback(
  //       STATUS_RESPONSE.SUCCESS,
  //       MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
  //     )
  //   })
  //   .catch((error) => {
  //     callback(
  //       STATUS_RESPONSE.ERROR,
  //       error.response?.status === 413
  //         ? `The upload of file larger than 50MB is not allowed`
  //         : error.response?.data?.message ??
  //             MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
  //     )
  //   })
  axios
    .post(`/api/pcb/to-svg`, request)
    .then(async (response: AxiosResponse) => {
      // Encrypt svg files
      const svgs = await Promise.all(
        response.data.data.map(async (svg) => {
          const svgBlob = new Blob([svg.content], { type: "image/svg+xml" })
          const encryptedHtmlFile = await encryptionController().encrypt(
            svgBlob,
            { dataType: "file", type: "project" }
          )
          request.append(
            "svg",
            new Blob([encryptedHtmlFile], { type: svgBlob.type }),
            svg.name
          )
          const { content, ...rest } = svg
          return rest
        })
      )
      request.append("layers", JSON.stringify(svgs))
      // Encrypt gerber file
      const file = request.get("file") as File
      const rawFileBlob = new Blob([file], { type: file.type })
      const fileText = await rawFileBlob.text()
      const rawHash = encryptionHelper.createFileHash(fileText)
      // encrypt
      const result = await encryptionController().encrypt(file, {
        dataType: "file",
        type: "project",
      })
      request.delete("file")
      const blob = new Blob([result], { type: file.type })

      request.append("file", blob, file.name)
      request.append("file_hash", rawHash)

      axios
        .post(
          `/api/invitee/pcb/upload-gerber/${projectComponentHistoryId}/${conversationId}`,
          request
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            rawHash,
            {
              ...response.data.data.file,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.status === 413
              ? `The upload of file larger than 50MB is not allowed`
              : error.response?.data?.message ??
                  MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
          )
        })
    })
    .catch((error) => {
      console.log(error)
    })
}
export const uploadInviteePcbBOMFileMiddleware = async (
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  const result = await encryptionController().encrypt(file, {
    dataType: "file",
    type: "project",
  })

  request.delete("file")
  const blob = new Blob([result], { type: file.type })
  request.append("file", blob, file.name)
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.UploadSubBom,
    {
      type: "pcb",
      fileName: file.name,
    }
  )
  request.append("log", log)
  request.append("file_hash", rawHash)
  axios
    .post(
      `/api/invitee/pcb/upload-bom/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then(async (response: AxiosResponse) => {
      await validateEncryptedFile(
        rawHash,
        {
          ...response.data.data.file,
          conversation_id: conversationId,
        },
        response.data.data.project_id
      )
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}
export const uploadInviteeStackupFileMiddleware = async (
  projectComponentHistoryId: string,
  conversationId: string,

  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  encryptionController()
    .encrypt(request.get("file"), { dataType: "file", type: "project" })
    .then((result) => {
      request.delete("file")
      request.append("file", new Blob([result], { type: file.type }), file.name)
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadPcb,
        {
          type: "stackup",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", rawHash)
      axios
        .post(
          `/api/invitee/pcb/upload-stackup/${projectComponentHistoryId}/${conversationId}`,
          request
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            rawHash,
            {
              file_key: response.data.data.file,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_ERROR
          )
        })
    })
}
export const uploadInviteeAssemblyFileMiddleware = async (
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  const result = await encryptionController().encrypt(file, {
    dataType: "file",
    type: "project",
  })
  request.delete("file")
  const blob = new Blob([result], { type: file.type })
  request.append("file", blob, file.name)
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type: "assembly",
    action: "uploaded",
    fileName: file.name,
  })
  request.append("log", log)
  request.append("file_hash", rawHash)
  axios
    .post(
      `/api/invitee/pcb/upload-assembly/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then(async (response: AxiosResponse) => {
      await validateEncryptedFile(
        rawHash,
        {
          file_key: response.data.data.file,
          conversation_id: conversationId,
        },
        response.data.data.project_id
      )
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_ERROR
      )
    })
}
export const deleteInviteeFilePCBTemplateMiddleware = (
  idComponentHistory: string,
  conversationId: string,
  url: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void,
  fileName?: string,
  type?: string
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type,
    action: "deleted",
    fileName,
  })
  axios
    .put(`/api/invitee/pcb/${idComponentHistory}/${conversationId}/${url}`, {
      log,
    })
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, "")
      }
    })
    .catch((error) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR, error.response?.data?.message)
      }
    })
}
export const uploadInviteeAttachmentsMiddleware = async (
  historyId: string,
  conversationId: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  let fileString = ""
  const files = data.getAll("files") as File[]
  let fileHashKeys: any = []
  data.delete("files")
  await Promise.all(
    files.map(async (file) => {
      const result = await encryptionController().encrypt(file, {
        dataType: "file",
        type: "project",
      })
      const blob = new Blob([result], { type: file.type })
      data.append("files", blob, file.name)
      fileString = fileString + file.name + ","
      const rawFileBlob = new Blob([file], { type: file.type })
      const fileText = await rawFileBlob.text()
      const rawHash = encryptionHelper.createFileHash(fileText)
      fileHashKeys = fileHashKeys.concat([
        {
          file_name: file.name,
          file_hash: rawHash,
        },
      ])
    })
  )
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.UploadAttachmentFiles,
    { file: fileString }
  )
  data.append("log", log)
  data.append("file_hashs", JSON.stringify(fileHashKeys))
  axios
    .post(
      `/api/invitee/pcb/upload-attachments/${historyId}/${conversationId}`,
      data
    )
    .then(async (response: AxiosResponse) => {
      await Promise.all(
        response.data.data.files.map(async (file) => {
          const found = fileHashKeys.find(
            (item) => item.file_name === file.file_name
          )
          await validateEncryptedFile(
            found?.file_hash || "",
            {
              file_key: file.file_key,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          return true
        })
      )
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_ERROR
      )
    })
}

//mech
export const postInviteeTabContentMiddleware = async (
  historyId: string,
  conversationId: string,
  type: string,
  data: FormData
) => {
  const description = data.get("description") as string
  if (description) {
    const encryptedMessage = await encryptionController().encrypt(description, {
      dataType: "string",
      type: "project",
    })
    data.append("description_block", encryptedMessage)
    const encryptedRawMessage = await encryptionController().encrypt(
      description,
      { dataType: "string", type: "project" }
    )
    data.delete("description")

    data.append("description", encryptedRawMessage)
  }
  data.append(
    "log",
    activityLogHelper.toEncryptedMessage(
      ActivityLogType.UploadAttachmentFiles,
      {}
    )
  )
  const files = data.getAll("files") as File[]
  let fileHashKeys: any = []
  data.delete("files")
  await Promise.all(
    files.map(async (file) => {
      const result = await encryptionController().encrypt(file, {
        dataType: "file",
        type: "project",
      })
      const blob = new Blob([result], { type: file.type })
      data.append("files", blob, file.name)
      const rawFileBlob = new Blob([file], { type: file.type })
      const fileText = await rawFileBlob.text()
      const rawHash = encryptionHelper.createFileHash(fileText)
      fileHashKeys = fileHashKeys.concat([
        {
          file_name: file.name,
          file_hash: rawHash,
        },
      ])
    })
  )
  data.append("file_hashs", JSON.stringify(fileHashKeys))
  return axios
    .post<{ data: string }>(
      `/api/invitee/${type}/upload/${historyId}/${conversationId}`,
      data
    )
    .then(async (response: AxiosResponse) => {
      await Promise.all(
        response.data.data.files.map(async (file) => {
          const found = fileHashKeys.find(
            (item) => item.file_name === file.file_name
          )
          await validateEncryptedFile(
            found?.file_hash || "",
            {
              file_key: file.file_key,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          return true
        })
      )
    })
}

export const uploadInvitee3DFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const file = newFile.get("file") as any
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  const result = await encryptionController().encrypt(file, {
    dataType: "file",
    type: "project",
  })

  newFile.delete("file")
  const blob = new Blob([result], { type: file.type })
  newFile.append("file", blob, file.name)
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.Upload3dFile,
    {
      fileName: file.name,
    }
  )
  newFile.append("log", log)
  newFile.append("file_hash", rawHash)
  const response = await axios.post<{ data: any }>(
    `/api/invitee/${type}/${historyId}/${conversationId}/upload-3d`,
    newFile
  )
  await validateEncryptedFile(
    rawHash,
    { ...response.data.data.file, conversation_id: conversationId },
    response.data.data.project_id
  )
  return response.data.data
}

export const deleteInvitee3DFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  fileName: string
) => {
  const logMessage = activityLogHelper.toEncryptedMessage(
    ActivityLogType.DeleteMech3DFile,
    {
      fileName: fileName,
    }
  )
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-3d-file`,
    {
      data: {
        log: logMessage,
      },
    }
  )
}
export const uploadInviteeSubBomFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const file = newFile.get("file") as File
  const rawFileBlob = new Blob([file], { type: file.type })
  const fileText = await rawFileBlob.text()
  const rawHash = encryptionHelper.createFileHash(fileText)
  const result = await encryptionController().encrypt(file, {
    dataType: "file",
    type: "project",
  })
  newFile.delete("file")
  const blob = new Blob([result], { type: file.type })
  newFile.append("file", blob, file.name)
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.UploadSubBom,
    {
      type,
      fileName: file.name,
    }
  )
  newFile.append("log", log)
  newFile.append("file_hash", rawHash)
  const response = await axios.post<{ data: any }>(
    `/api/invitee/${type}/${historyId}/${conversationId}/upload-bom`,
    newFile
  )
  await validateEncryptedFile(
    rawHash,
    { ...response.data.data.file, conversation_id: conversationId },
    response.data.data.project_id
  )
  return response.data.data
}

export const deleteInviteeSubBomFile = async (
  historyId: string,
  conversationId: string,
  type: string // TabType
) => {
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-bom`,
    {
      data: {
        log: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteMechBom,
          {}
        ),
      },
    }
  )
}

export const inviteeBomAddAdditionalColMiddleware = async (
  idHistory: string,
  conversationId: string,
  dataRequest: TableColumnBOMDetail[],
  type: ComponentType = ComponentType.BOM,
  oldData: TableColumnBOMDetail[]
) => {
  const diffColumn = activityLogHelper.toDiffColumn(oldData, dataRequest)
  const log = activityLogHelper.createAddColumnLog(type, diffColumn)
  const newData = await Promise.all(
    dataRequest.map(async (item) => {
      const encryptedValues = await encryptionController().encrypt(
        JSON.stringify(item.values),
        { dataType: "string", type: "project" }
      )
      return {
        ...item,
        values: encryptedValues,
      }
    })
  )
  const res = await axios.post(
    `/api/invitee/${type}/${idHistory}/${conversationId}/add-column`,
    {
      data: newData,
      log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddBomColumn, {
        content: log,
      }),
    }
  )
  return res.data.data
}

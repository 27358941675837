import { ProjectDetail } from "pages/projects/types"
import { STATUS_BUILD, ProjectComponentStatus } from "components/Status/types"
import { UseBooleanProps } from "types"
import { SelectedDefaultProp } from "components/Select/types"
import { BuildHistoryDetail } from "pages/project-component-detail/types/index"

export interface HeaderProps {
  projectDetail: ProjectDetail
}

export interface ProjectComponentDetail {
  id: string
  project_id: string
  name: string
  code: string
  created_at: string
  created_by: string
  updated_at: string
  version: string
  checkbox: boolean
  project_build_component_id?: string
  versions?: BuildHistoryDetail[]
  type: {
    value: number
    key: string
  }
  is_valid_to_build: boolean
  is_archived: number
  project_component_history: {
    code: string
    id: string
    name: string
    status: STATUS_BUILD
  }
  invitee: {
    email: string
    name: string
  }
  project_component_history_id: string
  is_read_comment: boolean | undefined
  last_version_status: string
  status: ProjectComponentStatus
  can_sync: boolean
}

export interface Folder {
  components: ProjectComponentDetail[]
  created_by: string
  id: string
  name: string
  project_component_ids: string
  project_id: string
}
export const defaultFolder: Folder = {
  components: [],
  created_by: "",
  id: "",
  name: "",
  project_component_ids: "",
  project_id: "",
}

export const defaultProjectComponentDetail: ProjectComponentDetail = {
  code: "",
  created_at: "",
  created_by: "",
  id: "",
  name: "",
  project_id: "",
  updated_at: "",
  version: "",
  checkbox: false,
  is_valid_to_build: false,
  type: {
    value: NaN,
    key: "",
  },
  is_archived: 0,
  project_component_history: {
    code: "",
    id: "",
    name: "",
    status: STATUS_BUILD.DRAFT,
  },
  invitee: {
    email: "",
    name: "",
  },
  project_component_history_id: "",
  is_read_comment: undefined,
  last_version_status: "",
  status: ProjectComponentStatus.Active,
  can_sync: false,
}

export interface ParamsProjectComponentProps {
  page?: number
  pageSize?: number
  type?: number
  status?: number
}

export interface CreateProjectComponentRequest {
  project_id: string
  name: string
  type: number
  group_id?: string
}

export interface UpdateProjectComponentRequest {
  project_id: string
  name: string
}

export interface BuildPanelProps {
  listComponentsLength: number
  readOnly?: boolean
  listBuilds: ProjectBuildDetail[]
  handleUpdateListProjectComponent: (item: ProjectBuildDetail) => void
  handleClickCommit: () => void
  statusBuild: StatusBuild
  isShare?: boolean
  projectId: string
  isUpdateProjectBuild: {
    value: IsUpdateProjectBuildProp
    setValue: (value: IsUpdateProjectBuildProp) => void
  }
  isShowAddComponentInBuild: {
    value: boolean
    setValue: (value: boolean) => void
  }
}

export interface CreateBuildRequest {
  project_id: string
  project_component_ids: string[]
}

export const dataBuildPaneRaw = {
  builds: [],
  components: [],
  created_at: "",
  id: "",
  name: "",
  project_id: "",
  status: STATUS_BUILD.EMPTY,
  updated_at: "",
  activeCollapse: false,
  code: "",
  is_archived: 0,
}

export interface ProjectBuildDetail {
  id: string
  project_id: string
  component_types: string
  code: string
  name: string
  project_name: string
  status: STATUS_BUILD
  created_at: string
  updated_at: string
  components: ProjectComponentDetail[]
  builds: ProjectBuildDetail[]
  activeCollapse: boolean
  is_archived: number
  created_by: string
  project_code: string
}
export const emptyProjectBuildDetail: ProjectBuildDetail = {
  id: "",
  project_id: "",
  code: "",
  name: "",
  component_types: "",
  status: STATUS_BUILD.DRAFT,
  created_at: "",
  updated_at: "",
  components: [],
  builds: [],
  activeCollapse: false,
  is_archived: 0,
  created_by: "",
  project_code: "",
  project_name: "",
}

export interface StatusBuild {
  name: string
  status: STATUS_BUILD
  id: string
}

export const defaultStatusBuild: StatusBuild = {
  name: "",
  status: STATUS_BUILD.EMPTY,
  id: "",
}

export interface CreateShareLink {
  url: string
}

export interface ParamsGetUserNotInProject {
  page: number
  pageSize: number
  filter?: {
    fullname: string
  }
}

export interface ItemUserInvitedProps {
  data: GetUsersInProjectProp
  originRoleOptions: SelectedRole[]
  projectId: string
  getUsersInProject: (bodyData: { email: string; role: number }) => void
}

export interface InviteUserProps {
  setOpenModal: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  projectId: string
}

export interface SelectedRole {
  label: string
  value: string
}

export const defaultSelectedRole: SelectedRole = {
  label: "Editor",
  value: "2",
}

export interface RoleOptionsData {
  key: SelectedRole["label"]
  value: number
}

export type SelectedUserProp = {
  email: string
  fullname: string
  id: string
  avatar: string
}

export type SetRoleInProjectProps = {
  email: string
  role: number
}

export type GetUsersInProjectProp = {
  user_id: string
  role: number
  fullname: string
  permission: string
  avatar: string
  id: string
  email: string
  is_new_user: boolean
  accepted: boolean
}

export interface UsersSearchResultProps {
  users: SelectedUserProp[]
  setOpen: (value: boolean) => void
  handleMoreData: () => void
  isLoading: boolean
  setSelectedUser: (value: SelectedUserProp) => void
}

export interface ActivitiesLogProps {
  projectId: string
}

export interface GetActivitiesLogData {
  id: string
  project_component_history_id: string
  content: string
  created_at: string
  created_by: string
  user: {
    id: string
    fullname: string
    avatar: string
  }
}

export interface ParamsGetActivitiesLog {
  page: number
  pageSize: number
}

export interface ActivityLogState {
  isReload: boolean
}

// Build status
export interface BuildStatusProp {
  key: string
  value: number
}

export type ChangeBuildStatusData = {
  status: number
  reason: string
}

export interface HeaderListProjectComponentProps {
  projectRole: string
  readOnly?: boolean
  statusBuild: StatusBuild
  isShowInvite: UseBooleanProps
  isShowComment: UseBooleanProps
  isLoading: UseBooleanProps
  handleChangeButton: () => void
  selectCpnType: {
    options: SelectedDefaultProp[]
    selected: SelectedDefaultProp
    onChange: (event: SelectedDefaultProp) => void
  }
  selectBuildStatus: {
    options: SelectedDefaultProp[]
    selected: SelectedDefaultProp | {}
    onChange: (event: SelectedDefaultProp) => void
  }
}

export interface AddComponentInBuildProps {
  openModal: {
    value: boolean
    setValue: (value: boolean) => void
  }
  isUpdateProjectBuild: {
    value: IsUpdateProjectBuildProp
    setValue: (value: IsUpdateProjectBuildProp) => void
  }
  isLoading: {
    value: boolean
    setValue: (value: boolean) => void
  }
  projectBuildId: string
  projectDetail: ProjectDetail
}

type IsUpdateProjectBuildProp = {
  isUpdate: boolean
  componentId: string
}

export interface PermissionProjectPage {
  viewOnlyShare: boolean
  archiveProject: boolean
}

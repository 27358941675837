import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import { HeaderContent } from "components/HeaderInfo/HeaderInfo"
import { useTippyLayout } from "components/TippyCustomzie"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { projectBuildLink } from "helpers/redirect"
import {
  formatDate,
  formatDateForConversationMessage,
  customLocalStorageHandler,
} from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import { cloneDeep, isUndefined } from "lodash"
import {
  CONVERSATION_ROLE,
  TAB_BY_ROLE,
} from "pages/conversations/conversations.type"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import { ProjectComponentDetail } from "pages/project-component/types"
interface Props {
  projectComponentDetail: ProjectComponentDetail
  updatedAtProjectComponent: string
  projectBuild: ProjectComponentBuildDetail
  idProjectBuildComponentParam: string
  convoID: string
  idConversation: string
  idProject: string
  conversationRole?: CONVERSATION_ROLE
}
const IDInfoConversationCard = (props: Props) => {
  const {
    projectBuild,
    projectComponentDetail,
    updatedAtProjectComponent,
    idProjectBuildComponentParam,
    convoID,
    idConversation,
    idProject,
    conversationRole,
  } = props
  const user = useAppSelector((state) => state.userInfo)
  const { isMobile } = useWindowSize()
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()
  const showConversationtInfo = useBoolean()
  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)

  const onClickLabelConversation = () => {
    const newCookies = !isUndefined(storageData) ? cloneDeep(storageData) : {}
    newCookies.projectBuildId = projectBuild.id
    newCookies.conversationId = idConversation
    handleSetLocalStorage(newCookies)
    pushTo(PATH.conversations)
  }
  const onClickLabelBuild = () => {
    // if (idProject) {
    pushTo(
      PATH.buildOverview,
      {
        status: projectBuild.status,
        idProject: projectBuild.project_id,
        idProjectBuild: projectBuild.id,
      },
      undefined,
      { tab: user.id === projectBuild.created_by ? "my-builds" : "others" }
    )
    return
  }

  const renderConversionCardInfo = () => (
    <div className="flex flex-col md:flex-row items-center justify-center">
      {convoID ? (
        <LabelHeader
          {...contentLabelStyleProps}
          title="Convo ID:"
          label={convoID}
          isClick
          path={`/conversations`}
          search={{
            conversationId: idConversation,
            tab: conversationRole
              ? TAB_BY_ROLE[conversationRole]
              : conversationRole,
          }}
        />
      ) : null}
      {projectBuild.id ? (
        <LabelHeader
          {...contentLabelStyleProps}
          title="Build ID:"
          label={projectBuild.code}
          path={projectBuildLink(
            projectBuild.project_id,
            projectBuild.id,
            projectBuild.status
          )}
          search={{
            tab: user.id === projectBuild.created_by ? "my-builds" : "others",
          }}
        />
      ) : null}
      <LabelHeader
        {...contentLabelStyleProps}
        title="Component ID:"
        label={projectComponentDetail.code}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Created:"
        label={formatDate(projectComponentDetail?.created_at, "DD MMM yyyy")}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Last update:"
        label={
          updatedAtProjectComponent
            ? `${formatDateForConversationMessage(updatedAtProjectComponent)}`
            : "No update"
        }
      />
    </div>
  )

  return (
    <div className="flex items-center">
      {isMobile ? (
        <TippyLayout
          visible={showConversationtInfo.value}
          headerContent={
            <HeaderContent
              onClick={() => showConversationtInfo.setValue(false)}
            />
          }
          mainContent={renderConversionCardInfo()}
        >
          <LabelHeader
            title="Project Info"
            isBorderRight
            // onClickTitle={openLayout}
            onClickTitle={() => {
              showConversationtInfo.setValue(true)
            }}
          />
        </TippyLayout>
      ) : (
        renderConversionCardInfo()
      )}
      <ActivityLogDefault
        model={MODEL_ACTIVITY_LOG.CONVERSATION_BUILD_COMPONENTS}
        idRelation={`${idProjectBuildComponentParam}/${idConversation}`}
      />
    </div>
  )
}
export default IDInfoConversationCard

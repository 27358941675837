import { EncryptionKeys } from "controllers/EncryptionController"

export const addKeyToLocalStorageObject = (
  type:
    | EncryptionKeys.conversationEncryptionKeys
    | EncryptionKeys.projectEncryptionKeys,
  additional: Object
) => {
  const keyObject = JSON.parse(localStorage.getItem(type) || "{}")
  const result = {
    ...keyObject,
    ...additional,
  }
  localStorage.setItem(type, JSON.stringify(result))
}

/* eslint-disable react-hooks/exhaustive-deps */
import ActionIconHistory from "../molecules/ActionIconHistory"
import { ReactComponent as IconSelectCurrency } from "assets/images/icons/icon-select-currency-arrow-down.svg"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean, useString } from "helpers/hooks"
import { ReactComponent as IconActiveSelectCurrency } from "assets/images/icons/icon-active-select-currency.svg"
import {
  emptySelectCurrencyDetail,
  SelectCurrencyDetail,
  TableColumnBOMDetail,
  ComponentType,
} from "../types"
import { useContext, useEffect, useRef, useState } from "react"
import { cloneDeep, isArray, remove, isEmpty, slice } from "lodash"
import ActionItem from "pages/projects/molecules/ActionItem"
import {
  removeSupplierColunmMiddleware,
  postBOMAddColumnMiddleware,
  postBOMAddSupplierMiddleware,
  postBOMSyncDataMiddleware,
  getBOMAddSupplierMiddleware,
} from "../services"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import {
  checkPermissionPage,
  converDataTableColumnBOM,
  converNameHeaderTableAdditional,
  downloadFile,
  formatDateForConversationMessage,
  umamiTracking,
} from "helpers/utils"
import TableBOMItem from "../molecules/TableBOMItem"
import TableBOMSupplier from "../molecules/TableBOMSupplier"
import TableBOMAddColumn from "../molecules/TableBOMAddColumn"
import { PermissionProjectComponentPage } from "../contexts/PermissionProjectComponentPage.context"
import { API_URL } from "config/environments"
import ModalDelete from "components/ModalCustom/ModalDelete"
import TableAdditionalJSON from "../molecules/TableAdditionalJSON"
import TableInviteeBOMCard from "../molecules/TableInviteeBOMCard"
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import Button from "components/Button/Button"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { TemplateLink } from "constants/index"
import { EVENT } from "constants/events"
import { exportBom, getSuplierPartAndQty } from "helpers/bom"

interface Props {
  BOMJson: any
  mouserData: any
  additionalJson: any
  inviteeBoms: any
  currencies: SelectCurrencyDetail[]
  idHistory: string
  nameBOM: string
  bomFileName: string
  updatedAtBOM?: string
  onUpdateWhenAddSupplier: (callback?: () => void) => void
  isActiveBtnSync: boolean
  componentType?: ComponentType
  isAddColumn?: {
    value: boolean
    setValue: (value: boolean) => void
  }
}
const BOMDetailCard = (props: Props) => {
  const {
    BOMJson,
    mouserData,
    idHistory,
    nameBOM,
    additionalJson,
    inviteeBoms,
    currencies,
    onUpdateWhenAddSupplier,
    isActiveBtnSync,
    componentType = ComponentType.BOM,
    updatedAtBOM,
    isAddColumn,
    bomFileName,
  } = props
  const {
    archiveProject,
    viewOnlyShare,
    archiveComponent,
    archiveBuild,
    isNoUpdateFilePCB,
  } = useContext(PermissionProjectComponentPage)
  const refTable = useRef<HTMLDivElement>(null)
  const [selectCurrency, setSelectCurrency] = useState<SelectCurrencyDetail>(
    emptySelectCurrencyDetail
  )
  const [activeCurrency, setActiveCurrency] = useState(
    emptySelectCurrencyDetail
  )
  const showTippySelectCurrency = useBoolean(false)
  const showTippyAdd = useBoolean(false)
  const isLoading = useBoolean(false)
  const [headers, setHeaders] = useState<string[]>([])
  const [dataAdditionalJson, setDataAdditionalJson] = useState<
    TableColumnBOMDetail[]
  >([])
  const [originAdditionalJson, setOriginAdditionalJson] = useState<
    TableColumnBOMDetail[]
  >([])

  const [extraJson, setExtraJson] = useState<TableColumnBOMDetail[]>([])

  const isDeleteColumn = useBoolean(false)
  const isUpdateColumn = useBoolean(false)
  const keyDeleteColumn = useString("")
  const [tableAddColumn, setDataAddColumn] = useState<string[]>([])

  useEffect(() => {
    if (isArray(BOMJson) && BOMJson.length) {
      const newHeaders: string[] = Object.keys(BOMJson[0])
      setHeaders(newHeaders)
      setDataAddColumn(Array.from(new Array(BOMJson.length)).map(() => ""))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BOMJson])

  useEffect(() => {
    if (!currencies.length) {
      return
    }
    const currencySGD = currencies.find((el) => el.symbol === "SGD")
    setSelectCurrency(currencySGD ? currencySGD : currencies[0])
    setActiveCurrency(currencySGD ? currencySGD : currencies[0])
  }, [currencies])

  useEffect(() => {
    if (isAddColumn) {
      if (!isEmpty(extraJson)) {
        isAddColumn.setValue(true)
      } else {
        isAddColumn.setValue(false)
      }
    }
  }, [JSON.stringify(extraJson), isAddColumn])

  useEffect(() => {
    if (isArray(additionalJson) && additionalJson.length) {
      const newDataAdditional: TableColumnBOMDetail[] = additionalJson.map(
        (elAdditionalJson) => {
          const { newKey, sliceKey } = converNameHeaderTableAdditional(
            elAdditionalJson.key
          )
          return {
            ...elAdditionalJson,
            idColumn: sliceKey,
            key: newKey,
          }
        }
      )
      setDataAdditionalJson(newDataAdditional)
      setOriginAdditionalJson(newDataAdditional)
      setExtraJson([])
    } else {
      setDataAdditionalJson([])
      setOriginAdditionalJson([])
      setExtraJson([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(additionalJson)])

  const handleClickTippySelectCurrency = (event) => {
    event.stopPropagation()
    showTippySelectCurrency.setValue(!showTippySelectCurrency.value)
  }
  const handleClickTippyAdd = (event) => {
    event.stopPropagation()
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
        component: archiveComponent,
        notificationComponent: true,
        noUpdateFilePCB: isNoUpdateFilePCB,
        notificationNoUpdateFilePCB: true,
        build: archiveBuild,
        notificationBuild: true,
      }) ||
      !isActiveBtnSync
    ) {
      return
    }
    showTippyAdd.setValue(!showTippyAdd.value)
  }

  const handleChangeSelectCurrency = (newValue: SelectCurrencyDetail) => () => {
    setSelectCurrency(newValue)
  }
  const onCancelSelect = () => {
    showTippySelectCurrency.setValue(!showTippySelectCurrency.value)
    setSelectCurrency(activeCurrency)
  }
  const onChangeSelect = () => {
    setActiveCurrency(selectCurrency)
    toast(
      <LabelNotificationPage
        messenger="Currency exchange successfully!"
        type="success"
      />
    )

    showTippySelectCurrency.setValue(false)
  }
  const onClickAction = (key: "supplier" | "column") => () => {
    if (!isAddColumn) {
      return false
    }
    showTippyAdd.setValue(false)
    switch (key) {
      case "supplier":
        callAPIAddSupplier()
        break
      case "column":
        isAddColumn.setValue(true)
        setExtraJson(
          extraJson.concat({
            idColumn: "",
            key: "",
            values: tableAddColumn,
          })
        )
        onChangeScroll()

        break
    }
  }
  const callAPIAddSupplier = async () => {
    umamiTracking(EVENT.BOM.ADD_SUPPLIER)
    if (!idHistory) {
      return
    }
    const parts = getSuplierPartAndQty(BOMJson);
    if (parts === false) {
      toast(
        <LabelNotificationPage
          messenger="Your BOM file is missing Manufacturer part number column, please check it."
          type="success"
        />
      );
      return;
    }
    isLoading.setValue(true)
    // GET SUPLIER DATA
    await getBOMAddSupplierMiddleware(parts).then((res) => {
      postBOMAddSupplierMiddleware(idHistory, componentType, JSON.stringify(res.data.data))
        .then(() => {
          isLoading.setValue(false)
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.ADD_SUPPLIER_SUCCESS}
              type="success"
            />
          )
          onUpdateWhenAddSupplier(onChangeScroll)
        })
        .catch((error) => {
          isLoading.setValue(false)
          if (
            error.response?.data?.message ===
            "This version has been updated. Please reload the page"
          ) {
            return toast(
              <LabelNotificationPage
                messenger={error.response?.data?.message}
                type="error"
              />
            )
          }
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: (
                    <span>
                      Could not load supplier data because BOM file does not
                      follow the standard format. Please follow the format
                      template.
                      <a
                        className="text-blue cursor-pointer ml-1"
                        href={TemplateLink.Bom}
                        target="_blank"
                      >
                        Download BOM template.
                      </a>
                    </span>
                  ),
                  content: "",
                  styleTitle: {
                    textAlign: "center",
                  },
                  label: `Warning`,
                  onSubmit: closeModal,
                  titleButton: `OK`,
                },
              },
            })
          )
        })
    }).catch((error) => {
      isLoading.setValue(false)
      return toast(
        <LabelNotificationPage
          messenger={error.response?.data?.message}
          type="error"
        />
      )
    });
    // console.log('supplierData', supplierData);
    // if (supplierData) {
    //   return;
    // }


  }
  const handleCancelAddColumn = (successAPI?: boolean) => {
    if (!isAddColumn) {
      return false
    }
    isAddColumn.setValue(false)
    isUpdateColumn.setValue(false)

    if (!successAPI) {
      setExtraJson([])
      setDataAdditionalJson(originAdditionalJson)
    }
  }
  const onSaveAddColumn = () => {
    if (!idHistory) {
      return
    }
    const newDataTableBOM = dataAdditionalJson.concat(extraJson)
    // check if user leave header empty
    let isEmptyHeader = false
    newDataTableBOM.forEach((i) => {
      if (i.key === "") {
        isEmptyHeader = true
      }
    })
    //
    if (isEmptyHeader) {
      toast(
        <LabelNotificationPage
          messenger="Required to enter header table!"
          type="warning"
        />
      )
      return
    }
    //
    isLoading.setValue(true)
    postBOMAddColumnMiddleware(
      idHistory,
      converDataTableColumnBOM(newDataTableBOM),
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (isUpdateColumn.value) {
          umamiTracking(EVENT.BOM.UPDATE_COLUMN)
        } else {
          umamiTracking(EVENT.BOM.ADD_COLUMN)
        }
        //
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleCancelAddColumn(true)
          onUpdateWhenAddSupplier(() => {
            onChangeScroll()
          })
        }
      },
      componentType,
      additionalJson
    )
  }
  const handleUpdateAdditionalJson = (newData: TableColumnBOMDetail[]) => {
    /// just update because no create any new column
    isAddColumn?.setValue(true)
    if (newData.length === originAdditionalJson.length) {
      setDataAdditionalJson(newData)
      return
    }
    if (newData.length > originAdditionalJson.length) {
      const extraColumns = slice(
        newData,
        originAdditionalJson.length,
        newData.length
      )
      const newExtraJson = cloneDeep(extraJson)
      extraColumns.forEach((column, index) => {
        if (newExtraJson[index]) {
          newExtraJson[index] = {
            idColumn: column.idColumn || newExtraJson[index].idColumn,
            key: column.key || newExtraJson[index].key,
            values: newExtraJson[index].values.map(
              (v, i) => column.values[i] || v
            ),
          }
        } else {
          newExtraJson.push(column)
        }
      })
      setExtraJson(newExtraJson)
      const remainAdditionalColumns = slice(
        newData,
        0,
        originAdditionalJson.length
      )
      setDataAdditionalJson(remainAdditionalColumns)
    }
  }

  const onExportFile = async () => {
    if (!idHistory) {
      return
    }
    isLoading.setValue(true)
    await exportBom(
      bomFileName || nameBOM,
      BOMJson,
      additionalJson,
      mouserData,
      inviteeBoms,
      componentType
    )
    // const url = `${API_URL}/api/${componentType}/${idHistory}/export`
    // downloadFile(url, nameBOM ?? new Date().toISOString(), true)
    isLoading.setValue(false)
  }
  const onSyncData = async () => {
    umamiTracking(EVENT.BOM.SYNC_SUPPLIER_STOCKS)
    if (!idHistory) {
      return
    }
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
        component: archiveComponent,
        notificationComponent: true,
        build: archiveBuild,
        notificationBuild: true,
      })
    ) {
      toast(
        <LabelNotificationPage
          messenger="Please edit version before sync supplier data!"
          type="warning"
        />
      )
      return
    }
    if (!isActiveBtnSync) {
      return toast(
        <LabelNotificationPage
          messenger="This function only work on Draft and latest version of main branch!"
          type="warning"
        />
      )
    }
    const parts = getSuplierPartAndQty(BOMJson);
    if (parts === false) {
      toast(
        <LabelNotificationPage
          messenger="Your BOM file is missing Manufacturer part number column, please check it."
          type="success"
        />
      );
      return;
    }
    isLoading.setValue(true)
    // GET SUPLIER DATA
    await getBOMAddSupplierMiddleware(parts).then((res) => {
      isLoading.setValue(true)
      postBOMSyncDataMiddleware(
        idHistory,
        (type: STATUS_RESPONSE, messenger: string) => {
          isLoading.setValue(false)
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          if (type === STATUS_RESPONSE.SUCCESS) {
            onUpdateWhenAddSupplier()
          }
        },
        componentType,
        JSON.stringify(res.data.data)
      )
    })

  }
  const handleOpenModelDelete = (type: string) => {
    isDeleteColumn.setValue(true)
    keyDeleteColumn.setValue(type)
  }
  const onCloseModalDelete = () => {
    isDeleteColumn.setValue(false)
    keyDeleteColumn.setValue("")
  }
  const onDeleteColumn = () => {
    if (!idHistory || !keyDeleteColumn.value) {
      return
    }
    if (keyDeleteColumn.value === "supplier") {
      deleteSupplier()
      return
    }
    deleteAdditional()
  }
  const deleteAdditional = () => {
    isLoading.setValue(true)
    umamiTracking(EVENT.BOM.UPDATE_COLUMN)
    const newDataTableAdditional = cloneDeep(originAdditionalJson)
    remove(
      newDataTableAdditional,
      (el) => `${el.key}${el.idColumn}` === keyDeleteColumn.value
    )
    postBOMAddColumnMiddleware(
      idHistory,
      converDataTableColumnBOM(newDataTableAdditional),
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(
          <LabelNotificationPage
            messenger={
              type === STATUS_RESPONSE.SUCCESS
                ? "Delete column successfully!"
                : messenger
            }
            type={type}
          />
        )
        if (type === STATUS_RESPONSE.SUCCESS) {
          isDeleteColumn.setValue(false)
          keyDeleteColumn.setValue("")
          setDataAdditionalJson(newDataTableAdditional)
          setOriginAdditionalJson(newDataTableAdditional)
        }
      },
      componentType,
      additionalJson
    )
  }
  const deleteSupplier = () => {
    isLoading.setValue(true)
    removeSupplierColunmMiddleware(
      idHistory,
      {
        key: keyDeleteColumn.value,
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isDeleteColumn.setValue(false)
          keyDeleteColumn.setValue("")
          onUpdateWhenAddSupplier()
        }
      },
      componentType
    )
  }

  const onChangeScroll = () => {
    setTimeout(() => {
      if (refTable.current) {
        refTable.current.scrollLeft = refTable.current.scrollWidth
        refTable.current.scrollIntoView({
          behavior: "smooth",
        })
      }
    }, 0)
  }
  const renderSelectCurrency = () => {
    if (!mouserData.length) {
      return null
    }
    return (
      <TippyCustomzie
        containerClass="component-card-menu"
        placement="bottom-end"
        interactive
        arrow={false}
        animation="scale"
        visible={showTippySelectCurrency.value}
        onClickOutside={onCancelSelect}
        content={
          <div
            className="grid"
            style={{
              width: 320,
              maxHeight: 392,
              background: "#FFFFFF",
              boxShadow: "0px 2px 10px rgba(29, 29, 29, 0.12)",
              borderRadius: 6,
              gridTemplateRows: "52px calc(100% - 128px) 76px",
            }}
          >
            <div className="flex justify-between items-center pl-4 pr-3.5">
              <p className="font-semibold text-16 leading-26 color-black-222222">
                Change currency
              </p>
              <ActionIconHistory
                onClick={onCancelSelect}
                icon="close-modal"
                tooltip="Close"
                styleIcon={{
                  marginRight: 0,
                }}
              />
            </div>
            <div
              className="flex flex-col mt-4"
              style={{
                overflow: "auto",
              }}
            >
              {currencies.length
                ? currencies.map((currency, index) => (
                    <div
                      key={index}
                      className={`flex items-center justify-between px-4 cursor-pointer ${
                        selectCurrency.symbol === currency.symbol
                          ? ""
                          : "hover-select-currency"
                      }`}
                      onClick={handleChangeSelectCurrency(currency)}
                      style={{
                        minHeight: 38,
                        backgroundColor:
                          selectCurrency.symbol === currency.symbol
                            ? "#FDBE44"
                            : "#FFFFFF",
                      }}
                    >
                      <p
                        className="font-normal leading-22 text-13"
                        style={{
                          color:
                            selectCurrency.symbol === currency.symbol
                              ? "#111111"
                              : "#7A7A7A",
                        }}
                      >
                        {`${currency.name} (${currency.symbol})`}
                      </p>
                      {selectCurrency.symbol === currency.symbol ? (
                        <IconActiveSelectCurrency />
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            <div className="flex justify-between items-center px-4">
              <Button
                title="Cancel"
                colorBtn="white"
                widthBtn={138}
                onClick={onCancelSelect}
              />
              <Button title="Change" widthBtn={138} onClick={onChangeSelect} />
            </div>
          </div>
        }
        allowHTML
      >
        <div
          className="flex items-center justify-center h-7 mr-3 cursor-pointer"
          style={{
            paddingLeft: 4,
          }}
          onClick={handleClickTippySelectCurrency}
        >
          <p
            className="font-semibold mr-1"
            style={{
              fontSize: 13,
              lineHeight: "22px",
            }}
          >
            {activeCurrency?.symbol}
          </p>
          <IconSelectCurrency />
        </div>
      </TippyCustomzie>
    )
  }
  const renderButtonSupplier = () => {
    if (mouserData.length) {
      return null
    }
    return (
      <ActionItem title="Add a supplier" onClick={onClickAction("supplier")} />
    )
  }
  const renderModalDelete = () => {
    if (!isDeleteColumn.value) {
      return null
    }

    const { newKey } = converNameHeaderTableAdditional(keyDeleteColumn.value)

    return (
      <ModalDelete
        onCloseModal={onCloseModalDelete}
        title={`Are you sure to delete ${
          keyDeleteColumn.value === "supplier" ? keyDeleteColumn.value : newKey
        }`}
        label={`Confirmation`}
        onSubmit={onDeleteColumn}
        titleButton="Delete"
      />
    )
  }

  return (
    <div className="flex flex-col mr-6 mt-6">
      <div className="flex justify-between items-end h-7">
        <p
          className="font-semibold"
          style={{
            fontSize: 16,
            lineHeight: "26px",
            color: "#111111",
          }}
        >
          BOM Detail
        </p>
        {isAddColumn ? (
          <div className="flex items-center h-full">
            {activeCurrency.symbol && activeCurrency.symbol !== "SGD" ? (
              <FormLabelWarning label="Note: The exchange rate is as a reference only and may not reflect the actual cost." />
            ) : null}
            <p className="text-[12px] font-normal text-brown pl-[12px]">
              Last update:{" "}
              {updatedAtBOM
                ? `${formatDateForConversationMessage(updatedAtBOM)}`
                : "No update"}
            </p>
            <div className="hidden md:flex md:items-center">
              <div className="ml-[12px] mr-[8px] h-[13px] w-[1px] bg-lightGray"></div>
              {renderSelectCurrency()}

              <ActionIconHistory
                icon="download"
                tooltip="Export"
                styleIcon={{
                  marginRight: 12,
                }}
                onClick={onExportFile}
              />
              {mouserData.length ? (
                <ActionIconHistory
                  icon="system-data"
                  onClick={onSyncData}
                  tooltip="Sync supplier data"
                  styleIcon={{
                    marginRight: 12,
                  }}
                  disabled={
                    checkPermissionPage({
                      project: archiveProject,
                      viewShare: viewOnlyShare,
                      component: archiveComponent,
                      build: archiveBuild,
                    }) || !isActiveBtnSync
                  }
                />
              ) : null}
              {(isAddColumn && isAddColumn.value) || isUpdateColumn.value ? (
                <div className="flex">
                  <Button
                    title="Cancel"
                    colorBtn="white"
                    sizeBtn="small"
                    onClick={() => handleCancelAddColumn()}
                    styleButton={{
                      color: "#EA4545",
                      marginRight: 12,
                    }}
                  />
                  <Button
                    title="Save"
                    sizeBtn="small"
                    onClick={onSaveAddColumn}
                  />
                </div>
              ) : (
                <TippyCustomzie
                  containerClass="component-card-menu"
                  placement="bottom-end"
                  interactive
                  arrow={false}
                  animation="scale"
                  visible={showTippyAdd.value}
                  onClickOutside={() => showTippyAdd.setValue(false)}
                  content={
                    <div
                      style={{
                        background: "#222222",
                        borderRadius: 4,
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 140,
                      }}
                    >
                      {renderButtonSupplier()}

                      <ActionItem
                        title="Add a column"
                        onClick={onClickAction("column")}
                      />
                    </div>
                  }
                  allowHTML
                >
                  <div onClick={handleClickTippyAdd}>
                    <ButtonHasIcon
                      title="Add"
                      widthButton={80}
                      heightButton={28}
                      textSize={12}
                      textLineHeight={18}
                      textWeight={500}
                      disabled={checkPermissionPage({
                        project: archiveProject,
                        viewShare: viewOnlyShare,
                        component: archiveComponent,
                        noUpdateFilePCB: isNoUpdateFilePCB,
                        build: archiveBuild,
                      })}
                    />
                  </div>
                </TippyCustomzie>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <div
          className="flex items-center"
          ref={refTable}
          style={{
            overflow: "auto",
            transform: "rotateX(180deg)",
            marginTop: 5,
          }}
        >
          <div
            className="flex items-center"
            style={{
              transform: "rotateX(180deg)",
            }}
          >
            <TableBOMItem
              headers={headers}
              dataBody={BOMJson}
              isAddColumn={isAddColumn?.value || false}
              lengthSupplier={mouserData.length}
              lengthAdditinal={dataAdditionalJson.length}
              lengthInviteeBOM={inviteeBoms.length}
            />
            {mouserData.length ? (
              <TableBOMSupplier
                originMouserData={mouserData}
                isAddColumn={isAddColumn?.value || false}
                lengthAdditinal={dataAdditionalJson.length}
                bomJson={BOMJson}
                lengthInviteeBOM={inviteeBoms.length}
                isDisable={checkPermissionPage({
                  project: archiveProject,
                  viewShare: viewOnlyShare,
                  component: archiveComponent,
                  noUpdateFilePCB: isNoUpdateFilePCB,
                  build: archiveBuild,
                })}
                handleOpenModelDelete={handleOpenModelDelete}
                activeCurrency={activeCurrency}
              />
            ) : null}
            {additionalJson.length ? (
              <TableAdditionalJSON
                dataAdditionalJson={dataAdditionalJson}
                defaultBomValues={tableAddColumn}
                setDataAdditionalJson={handleUpdateAdditionalJson}
                isDisable={checkPermissionPage({
                  project: archiveProject,
                  viewShare: viewOnlyShare,
                  component: archiveComponent,
                  noUpdateFilePCB: isNoUpdateFilePCB,
                  build: archiveBuild,
                })}
                isAddColumn={isAddColumn?.value || false}
                lengthInviteeBOM={inviteeBoms.length}
                handleOpenModelDelete={handleOpenModelDelete}
                isUpdateColumn={isUpdateColumn}
              />
            ) : null}
            {inviteeBoms.length ? (
              <TableInviteeBOMCard
                originInviteeBoms={inviteeBoms}
                isDisable
                lengthBOMJSON={BOMJson.length}
                onChangeScroll={onChangeScroll}
              />
            ) : null}

            {!isEmpty(extraJson)
              ? extraJson.map((_item, index) => (
                  <TableBOMAddColumn
                    key={index}
                    jsonIndex={index}
                    extraJson={extraJson}
                    setExtraJson={setExtraJson}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
      {renderModalDelete()}
    </div>
  )
}
export default BOMDetailCard

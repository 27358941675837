import Button from "components/Button/Button"
import ModalCustom from "components/ModalCustom"
const YesNoModal = (props: any) => {
  return (
    <ModalCustom
      bodyChildren={
      <div
        className="inset-0 flex fixed items-center justify-center"
        style={{
          zIndex: 1000000,
        }}
      >
        <div className="px-6 py-6 bg-white rounded-md flex flex-col items-center justify-center mx-6 w-webkit-fill md:w-[420px]">
          <p
            className="font-semibold"
            style={{
              fontSize: 16,
              lineHeight: "32px",
              color: "#111111",
            }}
          >
          Have you backed up your recovery code?
          </p>
          <div className="flex flex-col md:flex-row items-center justify-between w-full mt-5 gap-3">
            <Button
              widthBtn={"100%"}
              title="No"
              colorBtn="white"
              onClick={props.onCancel}
            />
            <Button
              widthBtn={"100%"}
              title="Yes"
              onClick={props.onConfirm}
            />
          </div>
        </div>
      </div>
    }
  />
  )
}
export default YesNoModal

import React, { useCallback, useEffect, useState } from "react"
import PageLayout from "pages/layout/PageLayout"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
  ProjectBuildDetail,
} from "pages/project-component/types"
import Header from "./organisms/ProjectComponentDetailHeader"
import BOMDetailCard from "./organisms/BOMDetailCard"
import {
  BuildHistoryDetail,
  defaultBuildHistoryDetail,
  emptyInfoFileDetail,
  PartsSpecificationDetail,
  TAB_URL_PROJECT_COMPONENT,
  ViewLinkedBuildHeaders,
  ExtendedViewType,
  BOMDetail,
  SelectCurrencyDetail,
  ComponentType,
  LayerRender,
} from "./types"
import {
  getBuildHistoriesMiddleware,
  getIsReadBuildComponentMiddleware,
  getPCBLayerAssignmentsMiddleware,
  getPCBMiddleware,
  getProjectBuildListLinkedBuildMiddleware,
  getPCBSubBOMMiddleware,
  getBOMCurrenciesMiddleware,
} from "./services"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import InfoBuildDetail from "./organisms/InfoBuildDetail"
import HistoryTreeCard from "../../components/HistoryTree/HistoryTreeCard"
import UpdateFileBuildCard from "./organisms/UpdateFileBuildCard"
import CustomCollapse from "./molecules/CustomCollapse"
import GerberViewerCard from "./organisms/GerberViewerCard"
import SpecificationViewerCard from "./organisms/SpecificationViewerCard"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { STATUS_TYPE_FILE_BUILD } from "./build-history.constant"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { PermissionProjectComponentPage } from "./contexts/PermissionProjectComponentPage.context"
import {
  checkPermissionPage,
  converSpecificationsRequice,
  findHistoryTreeDefault,
  getFileContentFromEnrypted,
  getPrivateAssetURI,
} from "helpers/utils"
import TableBuildCard from "pages/project-component/organisms/TableBuildCard"
import { cloneDeep, includes, isEmpty, remove } from "lodash"
import {
  emptyProjectBuildDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import { useGerberPCBComponent } from "./project-component-detail.hook"
import { REACT_APP_API_CURRENCIES } from "config/environments"
import { SpecificationHeader } from "components/specification/SpecificationHeader"
import { SectionCustom } from "components/Section/SectionCustom"
import { closeLoading } from "reducers/loading"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"

const PCBTemplate = () => {
  const params = useParams<{
    idProjectComponent: string
    titlePage: string
    idProjectBuildComponent: string
  }>()
  const locationPCBTemplate = useLocation<any>()
  const idProjectComponent = params?.idProjectComponent || ""
  const titlePage = params?.titlePage || ""
  const idProjectBuildComponent = params?.idProjectBuildComponent || ""
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const {
    idComponentHistory,
    infoFileAssembly,
    infoFileBOM,
    infoFileGerber,
    infoFileStackup,
    isToggleGerber,
    isToggleSpecification,
    layerAssignment,
    layersPCB,
    specifications,
    setLayerAssignment,
    setLayersPCB,
    setInfoFileAssembly,
    setInfoFileBOM,
    setInfoFileGerber,
    setInfoFileStackup,
    setSpecifications,
    canViewGerber,
    listFileUrl,
    setListFileUrl,
  } = useGerberPCBComponent()

  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const isViewLinkedBuild = useBoolean(false)
  const isActiveLinedBuild = useBoolean(false)
  const [listViewLinkedBuilds, setListViewLinkedBuilds] = useState<
    ProjectBuildDetail[]
  >([])
  const updatedAtComponent = useString()
  const viewOnlyShare = useBoolean(true)
  const archiveProject = useBoolean(true)
  const archiveComponent = useBoolean(true)
  const archiveBuild = useBoolean(true)
  const [specificationRequired, setSpecificationRequired] = useState<string[]>(
    []
  )
  const isUpdatingBom = useBoolean(false)
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const isReadComponent = useBoolean(true)
  const [partSpecificationRequice, setPartSpecificationRequice] = useState<
    PartsSpecificationDetail[]
  >([])
  const [detailViewType, setDetailViewType] = useState<ExtendedViewType>(
    ExtendedViewType.HideAll
  )
  const [layerFiles, setLayerFiles] = useState<LayerRender[]>([])
  const loadedLayers = useBoolean(false)
  const [currencies, setCurrencies] = useState<SelectCurrencyDetail[]>([])
  const [bomDetail, setBomDetail] = useState<BOMDetail>()

  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail.build_status === BuildStatusNumber.DELETED_ATTACHMENT
  const getLayerData = async (layers: LayerRender[]) => {
    toast(
      <LabelNotificationPage
        messenger="System busy. Please wait.."
        type="warning"
      />
    )
    const layerContents = await Promise.all(
      layers.map(async (layer) => {
        if (isEmpty(layer.file)) {
          return layer
        }
        const fileUrl = getPrivateAssetURI(layer.file, {})
        const content = await getFileContentFromEnrypted(
          fileUrl,
          layer.file_name,
          true
        )
        return {
          ...layer,
          file: content as string,
        }
      })
    )
    loadedLayers.setValue(true)
    closeLoading()
    setLayersPCB(layerContents)
  }
  useEffect(() => {
    if (isToggleGerber.value && layerFiles && !loadedLayers.value) {
      getLayerData(layerFiles)
    }
  }, [isToggleGerber.value, layerFiles?.toString()])
  useEffect(() => {
    loadedLayers.setValue(false)
  }, [idComponentHistory.value])
  useEffect(() => {
    if (
      !idProjectComponent ||
      !includes(
        [
          TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
        ],
        titlePage
      )
    ) {
      pushTo(PATH.projects)
      return
    }
    setHistories([])
    getBOMCurrencies()
    getProjectComponentDetail()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponent,
    titlePage,
    idProjectBuildComponent,
    locationPCBTemplate.state,
  ])

  const getBOMCurrencies = async () => {
    const dataRes = await getBOMCurrenciesMiddleware(
      REACT_APP_API_CURRENCIES ? JSON.parse(REACT_APP_API_CURRENCIES) : []
    )
    setCurrencies(dataRes)
  }

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponent
      )
      setComponentDetail(dataComponentRes)
      await getDataWhenChangeTitlePage(dataComponentRes)
      if (dataComponentRes.type.key !== TYPE_PROJECT_COMPONENT.PCB) {
        pushTo(PATH.projectComponent, {
          idProject: dataComponentRes.project_id,
        })
        return
      }
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataComponentRes.project_id
      )
      setProjectDetail(dataProjectDetailRes)
      const dataProjectRole = await getProjectRoleMiddleware(
        dataComponentRes.project_id
      )
      await getPCBLayerAssignments()
      await getHistories(dataComponentRes.id)

      viewOnlyShare.setValue(
        dataProjectRole.role === STATUS_PROJECT_ROLE.VIEWER
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      archiveComponent.setValue(Boolean(dataComponentRes.is_archived))
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getDataWhenChangeTitlePage = async (
    oldComponentRes: ProjectComponentDetail
  ) => {
    if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
      await getProjectBuildComponent(idProjectBuildComponent)
      await getIsReadProjectBuildComponent(idProjectBuildComponent)
    } else {
      setProjectBuild(emptyProjectBuildDetail)
      isReadComponent.setValue(oldComponentRes.is_read_comment)
      const dataLinkedBuild = await getProjectBuildListLinkedBuildMiddleware(
        oldComponentRes.id
      )
      isActiveLinedBuild.setValue(dataLinkedBuild.length)
      setListViewLinkedBuilds(dataLinkedBuild)
      archiveBuild.setValue(false)
      updatedAtComponent.setValue(oldComponentRes.updated_at)
    }

    isLoading.setValue(false)
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent
    )
    isReadComponent.setValue(dataRes.is_read_comment)
  }
  const getPCB = useCallback(
    async (oldIdComponentHistory: string, loading = false) => {
      try {
        const dataPCB = await getPCBMiddleware(oldIdComponentHistory)
        if (isEmpty(dataPCB.zip_file)) {
          loadedLayers.setValue(false)
          setLayersPCB([])
        }
        setLayerFiles(dataPCB.tempLayers)
        setInfoFileGerber({
          file: dataPCB.zip_file,
          name: dataPCB.file_name,
        })
        setInfoFileBOM({
          file: dataPCB.bom_file,
          name: dataPCB.bom_file_name,
        })
        setInfoFileStackup({
          file: dataPCB.stackup_file,
          name: dataPCB.stackup_file_name,
        })
        setInfoFileAssembly({
          file: dataPCB.assembly_file,
          name: dataPCB.assembly_file_name,
        })
        setListFileUrl(dataPCB.files ?? [])
        // setLayersPCB(dataPCB.layers ?? [])
        setSpecifications(dataPCB.specifications ?? [])
        canViewGerber.setValue(dataPCB.can_view_gerber)
        if (dataPCB.specifications.length) {
          const dataPartsRequice = dataPCB.specifications[0].parts.filter(
            (part) => Boolean(part.required)
          )
          setPartSpecificationRequice(dataPartsRequice)
          setSpecificationRequired(
            converSpecificationsRequice(dataPartsRequice)
          )
        }
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        setInfoFileGerber(emptyInfoFileDetail)
        setInfoFileBOM(emptyInfoFileDetail)
        setInfoFileStackup(emptyInfoFileDetail)
        setInfoFileAssembly(emptyInfoFileDetail)
        setLayersPCB([])
        setSpecifications([])
        setListFileUrl([])
        canViewGerber.setValue(true)
        if (loading) {
          isLoading.setValue(false)
        }
      }
    },
    [
      setInfoFileGerber,
      setInfoFileBOM,
      setInfoFileStackup,
      setInfoFileAssembly,
      setListFileUrl,
      setLayersPCB,
      setSpecifications,
      canViewGerber,
      isLoading,
    ]
  )

  const getHistories = async (
    oldIdComponent: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getBuildHistoriesMiddleware(
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? idProjectBuildComponent
          : oldIdComponent,
        titlePage
      )
      setHistories(dataRes)
      if (!isEmpty(dataRes)) {
        await getDataHistoryDetail(dataRes, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      pushTo(PATH.projects)
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const getDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit: boolean
  ) => {
    const { idHistory, newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationPCBTemplate
    )
    setHistoryDetail(newHistoryDetail)
    idComponentHistory.setValue(idHistory)
    await getSubBomDetail(idHistory)
    await getPCB(idHistory)
  }

  const getSubBomDetail = async (idHistory: string) => {
    isUpdatingBom.setValue(false)
    await getPCBSubBOMMiddleware(idHistory)
      .then((res) => setBomDetail(res))
      .catch(() => {
        setBomDetail(undefined)
      })
  }

  const getPCBLayerAssignments = async () => {
    try {
      const dataRes = await getPCBLayerAssignmentsMiddleware()
      setLayerAssignment(
        dataRes.map((el) => {
          return {
            ...el,
            label: el.key,
          }
        })
      )
    } catch (error) {
      setLayerAssignment([])
    }
  }

  const getProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      oldIdProjectBuildComponent
    )
    setProjectBuild(dataRes)
    archiveBuild.setValue(Boolean(dataRes.is_archived))
    updatedAtComponent.setValue(dataRes.updated_at)
  }

  const updatedAtComponentDetail = (updatedAt: string) => {
    updatedAtComponent.setValue(updatedAt)
  }
  const handleUpdateDataPCB = (
    oldIdComponentHistory: string,
    type?: string
  ) => {
    isLoading.setValue(true)
    getPCB(oldIdComponentHistory, true)
    if (type === STATUS_TYPE_FILE_BUILD.GERBER) {
      isToggleGerber.setValue(false)
      isToggleSpecification.setValue(false)
    }
    updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataWhenChangeHistory = () => {
    isLoading.setValue(true)
    getHistories(componentDetail.id, true, false)
    updatedAtComponentDetail(new Date().toISOString())
  }

  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      isUpdatingBom.setValue(false)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getPCB(newHistoryDetail.id, true)
      getSubBomDetail(newHistoryDetail.id)
      isToggleGerber.setValue(false)
      isToggleSpecification.setValue(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idComponentHistory,
      isLoading,
      getPCB,
      isToggleGerber,
      isToggleSpecification,
      historyDetail,
    ]
  )

  const onViewLinedBuilds = () => {
    if (!listViewLinkedBuilds.length || !isActiveLinedBuild.value) {
      return
    }
    isViewLinkedBuild.setValue(!isViewLinkedBuild.value)
  }
  const onUpdateDataEdit = (item: ProjectComponentDetail) => {
    setComponentDetail(item)
  }
  const handleChangeHistoryTreeComment = (originIdHistory: string) => {
    const newHistories = cloneDeep(histories)
    const newHistoryDetail = newHistories.find(
      (el) => el.id === originIdHistory
    )
    if (newHistoryDetail) {
      handleChangeHistoryDetail(newHistoryDetail)()
    }
  }
  const isDisableToggleGerber = infoFileGerber.file ? false : true
  /// index of history
  const newMasterHistories =
    titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
      ? histories.filter((el) => !el.project_build_component_id)
      : histories
  const indexHistory = newMasterHistories.findIndex(
    (el) => el.id === historyDetail.id
  )
  const handleDeleteAttachment = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
    updatedAtComponentDetail(new Date().toISOString())
  }
  const renderBody = () => {
    if (isViewLinkedBuild.value) {
      return (
        <div className="flex flex-col mt-6 mr-6">
          <TableBuildCard
            listBuilds={listViewLinkedBuilds}
            customHeaders={ViewLinkedBuildHeaders}
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <UpdateFileBuildCard
          idComponentHistory={idComponentHistory.value}
          handleUpdateDataPCB={handleUpdateDataPCB}
          infoFileAssembly={infoFileAssembly}
          infoFileBOM={infoFileBOM}
          infoFileGerber={infoFileGerber}
          infoFileStackup={infoFileStackup}
          listFileUrl={listFileUrl}
          detailViewType={detailViewType}
          setDetailViewType={setDetailViewType}
          handleUpdateBomFile={getSubBomDetail}
          isDeleted={isDeleted}
          handleDeleteAttachment={handleDeleteAttachment}
        />
        <div
          className={`flex flex-col mt-6 mr-6 ${
            detailViewType !== ExtendedViewType.Gerber ? "hidden" : ""
          }`}
        >
          <CustomCollapse
            changeHeaderBorder={false}
            buttonClass="border-0"
            isToggle={isToggleGerber.value}
            handleChangeToggle={(newToggle: boolean) =>
              isToggleGerber.setValue(newToggle)
            }
            header={
              <SpecificationHeader
                title={"Gerber viewer"}
                isExpanded={isToggleGerber.value}
              />
            }
            childrenBody={
              <GerberViewerCard
                layersPCB={layersPCB}
                layerAssignment={layerAssignment}
                isToggleGerber={isToggleGerber.value}
                canViewGerber={canViewGerber.value}
                viewOnly={
                  checkPermissionPage({
                    project: archiveProject.value,
                    viewShare: viewOnlyShare.value,
                    component: archiveComponent.value,
                    build: archiveBuild.value,
                  }) ||
                  includes(
                    [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
                    historyDetail.status
                  )
                }
                isEncrypted={projectDetail.is_encrypted}
              />
            }
            isDisableToggle={isDisableToggleGerber}
          />
          <div className="mt-6">
            <CustomCollapse
              buttonClass="border-0"
              changeHeaderBorder={false}
              isToggle={isToggleSpecification.value}
              handleChangeToggle={(newToggle: boolean) =>
                isToggleSpecification.setValue(newToggle)
              }
              header={
                <SpecificationHeader
                  title={"Specification"}
                  isExpanded={isToggleGerber.value}
                />
              }
              childrenBody={
                <SpecificationViewerCard
                  partSpecificationRequice={partSpecificationRequice}
                  setPartSpecificationRequice={setPartSpecificationRequice}
                  setSpecificationRequired={setSpecificationRequired}
                  updatedAtComponentDetail={updatedAtComponentDetail}
                  specifications={specifications}
                  idComponentHistory={idComponentHistory.value}
                  handleUpdateSpecifications={handleUpdateDataPCB}
                  viewOnly={
                    checkPermissionPage({
                      project: archiveProject.value,
                      viewShare: viewOnlyShare.value,
                      component: archiveComponent.value,
                      build: archiveBuild.value,
                    }) ||
                    includes(
                      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
                      historyDetail.status
                    )
                  }
                  isToggleSpecification={isToggleSpecification.value}
                />
              }
              isDisableToggle={isDisableToggleGerber || !specifications.length}
            />
          </div>
        </div>

        <div
          className={`flex flex-col mt-6 mr-6 ${
            !bomDetail || detailViewType !== ExtendedViewType.Bom
              ? "hidden"
              : ""
          }`}
        >
          <BOMDetailCard
            BOMJson={bomDetail?.bom_json || []}
            currencies={currencies}
            idHistory={historyDetail.id}
            updatedAtBOM={bomDetail?.updated_at}
            onUpdateWhenAddSupplier={() => getSubBomDetail(historyDetail.id)}
            nameBOM={infoFileBOM.name}
            bomFileName={infoFileBOM.file_name || ""}
            mouserData={bomDetail?.mouser_data || []}
            additionalJson={bomDetail?.additional_json || []}
            inviteeBoms={[]}
            isActiveBtnSync={
              (indexHistory === 0 &&
                !historyDetail.project_build_component_id) || // first history => active
              historyDetail.status === STATUS_BUILD.DRAFT || // draft => always active
              // if index of history is second and the first is draft => active
              (indexHistory === 1 &&
                histories[0]?.status === STATUS_BUILD.DRAFT)
            }
            componentType={ComponentType.PCB}
            isAddColumn={isUpdatingBom}
          />
        </div>
      </React.Fragment>
    )
  }
  return (
    <PageLayout
      childrenHeader={
        <Header
          projectDetail={projectDetail}
          projectComponentDetail={componentDetail}
          updatedAtComponent={updatedAtComponent.value}
          projectBuild={projectBuild}
          titlePage={titlePage}
          idProjectBuildComponent={idProjectBuildComponent}
        />
      }
      heightHeader={48}
    >
      <PermissionProjectComponentPage.Provider
        value={{
          archiveProject: archiveProject.value,
          viewOnlyShare: viewOnlyShare.value,
          archiveComponent: archiveComponent.value,
          archiveBuild: archiveBuild.value,
          isListActionHistory:
            !viewOnlyShare.value && infoFileGerber.file ? true : false,
          isNoUpdateFilePCB: includes(
            [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
            historyDetail.status
          ),
          titlePage,
          idProjectBuildComponent,
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <InfoBuildDetail
            projectComponentDetail={componentDetail}
            projectBuild={projectBuild}
            historyDetail={historyDetail}
            handleUpdateDataWhenChangeHistory={
              handleUpdateDataWhenChangeHistory
            }
            histories={histories}
            onUpdateDataEdit={onUpdateDataEdit}
            isViewLinkedBuild={isViewLinkedBuild.value}
            onViewLinedBuilds={onViewLinedBuilds}
            specificationRequired={specificationRequired}
            isReadComponent={isReadComponent.value}
            isNotDownload={
              infoFileGerber.file && infoFileGerber.name ? false : true
            }
            setIsReadComponent={isReadComponent.setValue}
            isActiveLinedBuild={isActiveLinedBuild.value}
            handleChangeHistoryTreeComment={handleChangeHistoryTreeComment}
            isUpdatingBom={isUpdatingBom.value}
            isDeleted={isDeleted}
            commitButtonStage={{
              isActive:
                infoFileGerber.file &&
                infoFileGerber.name &&
                historyDetail.status === STATUS_BUILD.DRAFT
                  ? true
                  : false,
              tooltipHelper: "Please upload gerber",
            }}
            setArchiveComponent={archiveComponent.setValue}
          />

          <SectionCustom>
            <HistoryTreeCard
              histories={histories}
              historyDetail={historyDetail}
              handleChangeHistoryDetail={handleChangeHistoryDetail}
              typeComponent={componentDetail.type.key}
              titlePage={titlePage}
            />
            {renderBody()}
          </SectionCustom>
        </div>
      </PermissionProjectComponentPage.Provider>
    </PageLayout>
  )
}
export default PCBTemplate

import Button from "components/Button/Button"
import { useWindowSize } from "helpers/hooks"
import ModalCustom from "."

export interface AcceptModalProps {
  content?: string
  label?: string
  title?: string | JSX.Element
  titleButton?: string
  onCloseModal: () => void
  onSubmit?: (event?: any) => void
  colorYellowButton?: boolean
  styleContent?: React.CSSProperties
  styleTitle?: React.CSSProperties
  contentWrapperStyles?: React.CSSProperties
}

const ModalAccept = (props: AcceptModalProps) => {
  const {
    content,
    label,
    title,
    titleButton,
    colorYellowButton,
    onCloseModal,
    onSubmit,
    styleContent,
    styleTitle,
    contentWrapperStyles = {
      paddingTop: 33,
      paddingBottom: 49,
    },
  } = props

  const { isMobile } = useWindowSize()

  return (
    <ModalCustom
      label={label ?? "Confirmation"}
      styleRoot={{
        width: isMobile ? "-webkit-fill-available" : 400,
      }}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <div
            className="flex flex-col items-center"
            style={contentWrapperStyles}
          >
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                ...styleTitle,
              }}
            >
              {title}
            </p>
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                ...styleContent,
              }}
              dangerouslySetInnerHTML={{ __html: content || "" }}
            ></p>
          </div>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
            <Button
              widthBtn={"100%"}
              title="Later"
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title={titleButton ? titleButton : ""}
              colorBtn={colorYellowButton ? "yellow" : "red"}
              onClick={onSubmit}
            />
          </div>
        </div>
      }
    />
  )
}
export default ModalAccept
